import { ChangeDetectorRef, Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ArticlesLexiClient, OperationCommercialeDto, OperationCommercialeType, PrixDto, PrixLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import { filter, lastValueFrom, Subscription } from 'rxjs';

@Component({
  selector: 'app-operations-commerciales-pour-article-detail',
  templateUrl: './operations-commerciales-pour-article-detail.component.html',
  styleUrls: ['./operations-commerciales-pour-article-detail.component.scss'],
})
export class OperationsCommercialesPourArticleDetailComponent implements OnDestroy {
  private readonly subscriptions = new Subscription();
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

  _articleId: number;
  get articleId(): number {
    return this._articleId;
  }
  @Input() set articleId(value: number) {
    if (value) {
      this._articleId = value;
      this.loadPageData();
    }
  }

  readonly operationCommercialeTypeDataSource = [
    { id: OperationCommercialeType.tarif, intitule: "Tarif"},
    { id: OperationCommercialeType.fidelite, intitule: "Fidélité"},
  ];
  prixDataSource: PrixDto[];
  dataSource: OperationCommercialeDto[];
  currentSiteId: number;
  currentSocieteId: number;

  constructor(
    private readonly prixLexiClient: PrixLexiClient,
    private readonly articlesLexiClient: ArticlesLexiClient,
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef,
  ) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadPageData() {
    this.subscriptions.add(
      this.authService.currentSiteId$.pipe(
        filter((siteId: number) => siteId != null)
      ).subscribe(async (siteId: number) => {
        this.currentSiteId = siteId;
        this.setDataSource();
        await this.setListPrix();
      })
    );

    this.subscriptions.add(
      this.authService.currentSocieteId$.pipe(
        filter((societeId: number) => societeId != null)
      ).subscribe(async (societeId: number) => this.currentSocieteId = societeId)
    );
  }

  async setDataSource() {
    this.dataSource = await lastValueFrom(this.articlesLexiClient.getOperationsCommercialesByArticleId(this.articleId));
  }

  private async setListPrix() {
    const listePrix = await lastValueFrom(this.prixLexiClient.getListePrix());
    this.prixDataSource = listePrix.filter(x => !x.bloque);
  }

}
