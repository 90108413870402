<dx-scroll-view>
  <div class="form-container" style="height: 100%;">
    <!-- Entête -->
    <dx-box direction="row" width="100%" [height]="50" align="center" crossAlign="center">
      <!-- Bouton de retour -->
      <dxi-item class="back-button" [ratio]="1">
        <dx-button backButton [fallbackRoute]="'/articles'" stylingMode="text" icon="back"></dx-button>
      </dxi-item>

      <!-- Libellé de l'article -->
      <dxi-item [ratio]="3">
        <h2 *ngIf="isCreation" style="margin: 0;">Création d'un nouvel article</h2>
        <h3 *ngIf="!isCreation" style="margin: 0;">{{ article?.codeBo }} - {{ article?.libelleLong }}</h3>
      </dxi-item>

      <!-- Boutons d'action -->
      <dxi-item [ratio]="1" [visible]="isCreation || isModificationEnCours">
        <div>
          <dx-button class="action-button" text="Enregistrer" type="success" icon="save" (onClick)="onUpdateArticle()"
            [visible]="!isCreation && isModificationEnCours"></dx-button>
          <dx-button class="action-button" text="Annuler" type="danger" icon="undo" (onClick)="onCancel()"
            [visible]="!isCreation && isModificationEnCours"></dx-button>
          <dx-button class="action-button" text="Créer" type="success" icon="save" (onClick)="onCreateNewArticle()"
            [visible]="isCreation"></dx-button>
        </div>
      </dxi-item>
      <dxi-item [ratio]="1" *ngIf="!isCreation">
        <div class="menu-button">
          <app-objecttype-edition-possible-list [objectType]="objectType.article" [objectId]="article?.id"
            [isDropDownButton]="true"></app-objecttype-edition-possible-list>
          <dx-drop-down-button [items]="actions" keyExpr="id" displayExpr="intitule" text="Action" icon="smalliconslayout"
            [dropDownOptions]="{ width: 120 }" [disabled]="isModificationEnCours || !isModificationAutorisee"
            (onItemClick)="onActionClick($event)" style="margin-left: 10px"></dx-drop-down-button>
        </div>
      </dxi-item>
    </dx-box>

    <div *ngIf="article && chargementInitialFait" style="display: grid; grid-template-columns: repeat(3, minmax(0, 1fr)); height: calc(100% - 50px);">
      <!-- #region SidePanel gauche -->
      <div style="grid-column: span 1; height: 100%;">
        <!-- #region Résumé : Photo principale, classifications, codeBo et libellé long, tarifs -->
        <div class="carteArticle">
          <!-- #region Classifications -->
          <div style="grid-column: span 2; display: flex; align-items: center;">
            <ng-container *ngIf="classifications?.length > 0">
              <span *ngFor="let c of classifications"
                class="dx-button-mode-text dx-state-focused chip"
                [title]="isClassificationsDuParent ? 'Cette classification provient du parent' : ''"
              >
                {{ c.classificationValueIntitule }}
              </span>
              <span  *ngIf="!isCreation && isClassificationsDuParent && !isModificationEnCours" style="margin-right: 12px;">
                <i id="classificationLegendeIcon" style="font-size: 18px; cursor: pointer;" class="dx-icon-info" (mouseenter)="showPopover = true" (mouseleave)="showPopover = false"></i>
              </span>
            </ng-container>
          </div>
          <!-- #endregion -->

          <!-- #region Photo principale -->
          <div style="grid-column: span 1;">
            <div *ngIf="photoPrincipaleId != null">
              <div class="loadingImage" *ngIf="isImageLoading"></div>
              <div *ngIf="!isImageLoading">
                <img [src]="imageSrc" class="photoPrincipale" />
              </div>
            </div>

            <div *ngIf="photoPrincipaleId == null && !isCreation">
              <div id="dropzone-external" class="dx-button-mode-text dx-state-focused">
                <div class="align-icon">
                  <i class="dx-icon-photo icon"></i>
                </div>
              </div>

              <dx-file-uploader dialogTrigger="#dropzone-external" dropZone="#dropzone-external" [multiple]="false"
                [allowedFileExtensions]="['.jpg', '.jpeg', '.png']" accept="image/*" uploadMode="useForm"
                [(value)]="addedPhotos" [visible]="false" (onValueChanged)="addNewImage()"
              ></dx-file-uploader>
            </div>
          </div>
          <!-- #endregion -->

          <!-- #region CodeBo et LibelleLong -->
          <div style="grid-column: span 1;">
            <dx-form [formData]="article" labelLocation="top" [readOnly]="!isModificationEnCours && !isCreation">
              <dxi-item itemType="group">
                <dxi-item itemType="group" [colCount]="12">
                  <dxi-item dataField="codeBo" [colSpan]="article?.articleParentId != null ? 9 : 12"
                    editorType="dxTextBox" [label]="{ text: 'Code' }" [editorOptions]="{ maxLength: 36 }"
                  ></dxi-item>
                  <dxi-item *ngIf="!isCreation && article?.articleParentId != null" [colSpan]="3">
                    <div *dxTemplate style="padding-top: 24px;">
                      <dx-button icon="parentfolder" hint="Voir l'article parent" (onClick)="navigateToArticleParent()" [width]="30" [height]="30"></dx-button>
                    </div>
                  </dxi-item>
                </dxi-item>
                <dxi-item dataField="libelleLong" editorType="dxTextBox" [label]="{ text: 'Intitulé' }" [editorOptions]="{ maxLength: 100 }">
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
              </dxi-item>
            </dx-form>
          </div>
          <!-- #endregion -->

          <!-- #region Tarifs -->
          <div *ngIf="!isCreation" style="grid-column: span 2;">
            <app-tarif-article-simplifie [article]="article"></app-tarif-article-simplifie>
          </div>
          <!-- #endregion -->
        </div>
        <!-- #endregion -->

        <!-- #region Tabs : Bons d'achat | Notes | Documents | Photos -->
        <dx-form *ngIf="!isCreation" [formData]="article" labelLocation="top" [readOnly]="!isModificationEnCours">
          <dxi-item itemType="tabbed">
            <dxo-tab-panel-options [showNavButtons]="true" [onSelectionChanged]="onTabSelectionChanged"></dxo-tab-panel-options>

            <!-- Onglet Bons d'achat -->
            <dxi-tab title="Bons d'achat" *ngIf="article.isBonAchat">
              <app-bon-achat-datagrid [articleId]="article.id"></app-bon-achat-datagrid>
            </dxi-tab>

            <!-- Onglet Notes -->
            <dxi-tab title="Notes">
              <app-notes-list [objectType]="objectType.article" [objectId]="article.id"></app-notes-list>
            </dxi-tab>

            <!-- Onglet Documents -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Documents]">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Documents]]">
                <app-document-list [objectId]="article.id" [objectType]="objectType.article" (onDocumentSupprime)="onDocumentSupprime($event)"></app-document-list>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Photos -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Photos]">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Photos]]">
                <div *ngIf="listPhotos.length === 0">
                  Il n'y a pas de photos dans les documents.
                </div>
                <dx-gallery *ngIf="listPhotos.length > 0"
                  id="gallery"
                  [dataSource]="listPhotos"
                  [height]="300"
                  [loop]="listPhotos.length > 1"
                  [showNavButtons]="true"
                  [showIndicator]="true"
                >
                  <div *dxTemplate="let galleryItem of 'item'" class="gallery-item">
                    <img onclick="window.open(this.src)" class="photos-gallery" [alt]="galleryItem.fileName" [src]="urlPhoto + galleryItem.id" />
                    <div style="margin-top: 10px">{{ galleryItem.fileName }}</div>
                  </div>
                </dx-gallery>
              </dxi-item>
            </dxi-tab>
          </dxi-item>
        </dx-form>
        <!-- #endregion -->
      </div>
      <!-- #endregion -->

      <!-- #region Tabs : Données de l'article  -->
      <div style="grid-column: span 2; height: 100%;">
        <dx-form #form [formData]="article" labelLocation="top" [readOnly]="!isModificationEnCours && !isCreation" [height]="'100%'">
          <dxi-item itemType="tabbed">
            <dxo-tab-panel-options #tabPanelGeneralOptions [showNavButtons]="true" [onSelectionChanged]="onTabSelectionChanged" [height]="'100%'"></dxo-tab-panel-options>

            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.General]">
              <dxi-item itemType="group" [colCount]="4">
                <dxi-item dataField="type" [label]="{ text: 'Type de l\'article' }" editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: articleTypeDataSource,
                    searchEnabled: true,
                    displayExpr: 'libelle',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['libelle'],
                    showDataBeforeSearch: true,
                  }"
                >
                  <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-item>
                <dxi-item dataField="rapportTarifaire" [label]="{ text: 'Rapport tarifaire' }" editorType="dxNumberBox"
                  [editorOptions]="{ format: { type: 'fixedPoint', precision: 4 } }"
                ></dxi-item>
                <dxi-item dataField="rapportPhysique" [label]="{ text: 'Rapport physique' }" editorType="dxNumberBox"
                  [editorOptions]="{ format: { type: 'fixedPoint', precision: 4 } }"
                ></dxi-item>
                <dxi-item dataField="dureeGarantieMois" [label]="{ text: 'Garantie'}" editorType="dxNumberBox"
                  [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"
                ></dxi-item>
                <dxi-item *ngIf="!isCreation && (!isModificationEnCours || (isModificationEnCours && !canModifierPrixRevient))"
                  dataField="prixRevient" editorType="dxNumberBox"
                  [editorOptions]="{format: {type: 'fixedPoint', precision: 3}, readOnly:'true'}">
                  <dxo-label template="prixRevientHistoryLabelTemplate"></dxo-label>
                </dxi-item>
                <dxi-item *ngIf="!isCreation && isModificationEnCours && canModifierPrixRevient"
                  dataField="prixRevient" editorType="dxNumberBox"
                  [editorOptions]="{ format: { type: 'fixedPoint', precision: 3 }, readOnly: 'true' }"
                >
                  <dxo-label template="prixRevientLabelTemplate"></dxo-label>
                </dxi-item>
                <dxi-item dataField="quantiteMinimumVente" editorType="dxNumberBox"
                  [label]="{ text: 'Qté min. de vente' }" [editorOptions]="{
                    format: { type: 'fixedPoint', precision: 3 },
                  }"
                ></dxi-item>
                <dxi-item dataField="uniteBaseId" [label]="{ text: 'Unité de base' }" editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: uniteDataSource,
                    searchEnabled: true,
                    displayExpr: 'intitule',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['intitule'],
                    showDataBeforeSearch: true,
                    value: uniteBaseSelectedId,
                  }"
                ></dxi-item>
                <dxi-item dataField="nombreNumeroSerie" [label]="{ text: 'Nombre n° série' }" editorType="dxNumberBox"
                  [editorOptions]="{
                    format: { type: 'fixedPoint', precision: 0 },
                  }"
                ></dxi-item>
                <dxi-item dataField="avecVariation" [label]="{text:'Activer les variantes'}" editorType="dxSwitch"></dxi-item>
                <dxi-item itemType="empty" *ngIf="!isCreation" [colSpan]="3"></dxi-item>

                <dxi-item itemType="group" [colCount]="4" [colSpan]="4">
                  <dxi-item dataField="isPpn" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Est PPN'}"></dxi-item>
                  <dxi-item dataField="isPgc" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Est PGC'}"></dxi-item>
                  <dxi-item dataField="bloque" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Bloqué'}"></dxi-item>
                  <dxi-item dataField="suiviSav" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Suivi SAV'}"></dxi-item>
                  <dxi-item dataField="matiereDangereuse" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Matière dangereuse'}"></dxi-item>
                  <dxi-item dataField="marchandiseFragile" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Fragile'}"></dxi-item>
                  <dxi-item dataField="_synchronized" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Synchronisé'}"></dxi-item>
                  <dxi-item dataField="genereChiffreAffaire" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Génère CA'}"></dxi-item>
                  <dxi-item *ngIf="canGererBonAchat" dataField="isBonAchat" editorType="dxCheckBox" [label]="{ visible: false }" [editorOptions]="{text: 'Bon d\'achat'}"></dxi-item>
                  <dxi-item *ngIf="canGererBonAchat" itemType="empty" [colSpan]="3"></dxi-item>
                </dxi-item>

                <!-- Options Revatua -->
                <dxi-item itemType="group" [colCount]="4" [colSpan]="4" caption="Revatua">
                  <dxi-item dataField="codeTarifRevatua" [label]="{ text: 'Code tarif REVATUA' }" editorType="dxSelectBox"
                    [editorOptions]="{
                      dataSource: tarifRevatuaDataSource,
                      searchEnabled: true,
                      displayExpr: codeTarifRevatuaDisplayExpr,
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['intitule', 'code'],
                      showDataBeforeSearch: true,
                      placeholder: codeTarifRevatuaPlaceholder,
                    }"></dxi-item>
                  <dxi-item dataField="stockageRevatua" [label]="{ text: 'Stockage REVATUA' }" editorType="dxSelectBox"
                    [editorOptions]="{
                      dataSource: stockageRevatuaList,
                      searchEnabled: true,
                      displayExpr: 'intitule',
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['intitule'],
                      showDataBeforeSearch: true,
                      placeholder: stockageRevatuaPlaceholder,
                    }"
                  ></dxi-item>
                  <dxi-item
                    dataField="articleAssuranceParDefautId"
                    [label]="{text: 'Article assurance'}"
                    editorType="dxSelectBox"
                    [editorOptions]="{
                      dataSource: articleAssuranceDataSource,
                      searchEnabled: true,
                      displayExpr: 'libelleLong',
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['libelleLong', 'codeBo', 'libelleCourt'],
                      showDataBeforeSearch: true,
                    }"
                  ></dxi-item>
                </dxi-item>

                <!-- Conditionnements -->
                <dxi-item *ngIf="!isCreation" itemType="group" [colCount]="4" [colSpan]="4" caption="Conditionnements">
                  <dxi-item dataField="conditionnementTarifaireVenteId" [label]="{ text: 'Tarifaire' }"
                    editorType="dxSelectBox" [editorOptions]="{
                    dataSource: conditionnementDataSource,
                    searchEnabled: true,
                    displayExpr: 'libelle',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['libelle'],
                    showDataBeforeSearch: true,
                  }"></dxi-item>
                  <dxi-item dataField="conditionnementVenteId" [label]="{ text: 'Vente' }"
                    editorType="dxSelectBox" [editorOptions]="{
                    dataSource: conditionnementDataSource,
                    searchEnabled: true,
                    displayExpr: 'libelle',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['libelle'],
                    showDataBeforeSearch: true,
                  }"></dxi-item>
                  <dxi-item dataField="conditionnementAchatId" [label]="{ text: 'Achat' }"
                    editorType="dxSelectBox" [editorOptions]="{
                    dataSource: conditionnementDataSource,
                    searchEnabled: true,
                    displayExpr: 'libelle',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['libelle'],
                    showDataBeforeSearch: true,
                  }"></dxi-item>
                  <dxi-item itemType="empty" [colSpan]="1"></dxi-item>
                </dxi-item>
              </dxi-item>
            </dxi-tab>

            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Variantes]" *ngIf="!isCreation && articleAvecVariation">
              <dxi-item itemType="group" colCount="2">
                <dxi-item dataField="variation1CaracteristiqueId"
                  [label]="{ text: 'Caractéristique 1' }"
                  editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: caracteristiqueDataSource,
                    searchEnabled: true,
                    displayExpr: 'intitule',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['codeBo', 'intitule'],
                    showDataBeforeSearch: true,
                  }"
                ></dxi-item>
                <dxi-item dataField="variation2CaracteristiqueId"
                  [label]="{ text: 'Caractéristique 2' }"
                  editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: caracteristiqueDataSource,
                    searchEnabled: true,
                    displayExpr: 'intitule',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['codeBo', 'intitule'],
                    showDataBeforeSearch: true,
                  }"
                ></dxi-item>
              </dxi-item>
              <dxi-item>
                <div *dxTemplate>
                  <app-variantes-article-list [article]="article" [offsetTopInPx]="280"></app-variantes-article-list>
                </div>
              </dxi-item>
            </dxi-tab>

            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Vente]" *ngIf="!isCreation">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Vente]]" itemType="tabbed">
                <dxo-tab-panel-options [showNavButtons]="true" stylingMode="secondary" tabsPosition="left"></dxo-tab-panel-options>
                <dxi-tab title="Tarifs" [style]="{ padding: '0 12px' }">
                  <app-tarif-article-list [articleId]="article?.id"></app-tarif-article-list>
                </dxi-tab>
                <dxi-tab title="Op. commerciales" [style]="{ padding: '0 12px' }">
                  <app-operations-commerciales-pour-article-detail [articleId]="article?.id"></app-operations-commerciales-pour-article-detail>
                </dxi-tab>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Comptabilité -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.ComptaDouane]">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.ComptaDouane]]"
                   itemType="group" caption="Comptabilité" [colCount]="2">
                <dxi-item dataField="taxeGroupeId" [label]="{ text: 'Groupe de taxes' }" editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: taxeGroupeDataSource,
                    searchEnabled: true,
                    displayExpr: compteGeneralDisplayExpr,
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['intitule'],
                    showDataBeforeSearch: true,
                  }">
                </dxi-item>

                <dxi-item dataField="compteCharge" [label]="{ text: 'Compte de charge' }" editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: comptesGeneraux,
                    searchEnabled: true,
                    displayExpr: compteGeneralDisplayExpr,
                    valueExpr: 'code',
                    showClearButton: true,
                    searchExpr: ['code', 'intitule'],
                    showDataBeforeSearch: true,
                    placeholder:
                      infosHeriteesDeLaClassification?.compteCharge ??
                      (isInterfaceComptableActive
                        ? 'Nécessaire pour l\'interface comptable'
                        : 'Sélection...'),
                  }"></dxi-item>

                <dxi-item dataField="compteProduit" [label]="{ text: 'Compte de produit' }" editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: comptesGeneraux,
                    searchEnabled: true,
                    displayExpr: compteGeneralDisplayExpr,
                    valueExpr: 'code',
                    showClearButton: true,
                    searchExpr: ['code', 'intitule'],
                    showDataBeforeSearch: true,
                    placeholder:
                      infosHeriteesDeLaClassification?.compteProduit ??
                      (isInterfaceComptableActive
                        ? 'Nécessaire pour l\'interface comptable'
                        : 'Sélection...'),
                  }"></dxi-item>

                <dxi-item dataField="compteVariationStock" [label]="{ text: 'Compte variation de stock' }"
                  editorType="dxSelectBox" [editorOptions]="{
                    dataSource: comptesGeneraux,
                    searchEnabled: true,
                    displayExpr: compteGeneralDisplayExpr,
                    valueExpr: 'code',
                    showClearButton: true,
                    searchExpr: ['code', 'intitule'],
                    showDataBeforeSearch: true,
                    placeholder:
                      infosHeriteesDeLaClassification?.compteVariationStock ??
                      (isInterfaceComptableActive
                        ? 'Nécessaire pour l\'interface comptable'
                        : 'Sélection...'),
                  }"></dxi-item>

                <dxi-item dataField="compteStock" [label]="{ text: 'Compte de stock' }" editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: comptesGeneraux,
                    searchEnabled: true,
                    displayExpr: compteGeneralDisplayExpr,
                    valueExpr: 'code',
                    showClearButton: true,
                    searchExpr: ['code', 'intitule'],
                    showDataBeforeSearch: true,
                    placeholder:
                      infosHeriteesDeLaClassification?.compteStock ??
                      (isInterfaceComptableActive
                        ? 'Nécessaire pour l\'interface comptable'
                        : 'Sélection...'),
                  }"></dxi-item>

                <dxi-item dataField="compteRemise" [label]="{ text: 'Compte Remise' }" editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: comptesGeneraux,
                    searchEnabled: true,
                    displayExpr: compteGeneralDisplayExpr,
                    valueExpr: 'code',
                    showClearButton: true,
                    searchExpr: ['code', 'intitule'],
                    showDataBeforeSearch: true,
                    placeholder:
                        infosHeriteesDeLaClassification?.compteRemise ??
                        (isInterfaceComptableActive
                          ? 'Nécessaire pour l\'interface comptable'
                          : 'Sélection...'),
                  }"></dxi-item>
              </dxi-item>
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.ComptaDouane]]"
                   itemType="group" caption="Douane" [colCount]="2">
                <dxi-item dataField="nomenclatureDouaneId" [label]="{ text: 'Nomenclature douanière' }"
                  editorType="dxSelectBox" [editorOptions]="{
                    dataSource: nomenclatureDouaneDataSource,
                    searchEnabled: true,
                    displayExpr: 'intitule',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['intitule', 'codeSH'],
                    showDataBeforeSearch: true,
                  }"></dxi-item>
                <dxi-item dataField="suffixeValeurDouaneId" [label]="{ text: 'Suffixe valeur douane' }"
                  editorType="dxSelectBox" [editorOptions]="{
                    dataSource: suffixeValeurDataSource,
                    searchEnabled: true,
                    displayExpr: 'intitule',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['intitule', 'code'],
                    showDataBeforeSearch: true,
                  }"></dxi-item>
                <dxi-item dataField="codeExonerationDouaneId" [label]="{ text: 'Code d\'exonération douanière' }"
                  editorType="dxSelectBox" [editorOptions]="{
                    dataSource: codeExonerationDataSource,
                    searchEnabled: true,
                    displayExpr: 'intitule',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['intitule', 'code'],
                    showDataBeforeSearch: true,
                  }"></dxi-item>
                <dxi-item dataField="paysOrigineId" [label]="{ text: 'Pays d\'origine' }" editorType="dxSelectBox"
                  [editorOptions]="{
                    dataSource: paysDataSource,
                    searchEnabled: true,
                    displayExpr: 'intitule',
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['intitule'],
                    showDataBeforeSearch: true,
                  }"></dxi-item>
                <dxi-item dataField="tauxSucre" [label]="{ text: 'Taux de sucre' }" editorType="dxNumberBox"
                  [editorOptions]="{
                    format: { type: 'fixedPoint', precision: 3 },
                  }"></dxi-item>
                <dxi-item dataField="litrage" [label]="{ text: 'Litrage' }" editorType="dxNumberBox" [editorOptions]="{
                    format: { type: 'fixedPoint', precision: 3 },
                  }"></dxi-item>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Stock -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Stock]" *ngIf="!isCreation && article.type === ArticleType.backOffice">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Stock]]">
                <div *dxTemplate>
                  <app-lot-stockage-datagrid [articleId]="article?.id" [currentSiteId]="currentSiteId"
                    [dataGridMarginTop]="'calc(100vh - 235px)'" [isInFicheArticle]="true"
                    [configFiltre]="configFiltreLot" [showCodeArticle]="false"
                  ></app-lot-stockage-datagrid>
                </div>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Achats -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Achats]">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Achats]]" dataField="fournisseurPrincipalId" [label]="{ text: 'Fournisseur principal' }"
                editorType="dxSelectBox" [editorOptions]="{
                  dataSource: partenaireDataSource,
                  searchEnabled: true,
                  displayExpr: 'intitule',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['intitule'],
                  showDataBeforeSearch: true,
                }"
              ></dxi-item>

              <dxi-item *ngIf="!isCreation && isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Achats]]">
                <div *dxTemplate style="padding: 12px;">
                  <dx-data-grid
                    [dataSource]="referencesFournisseurs"
                    [allowColumnReordering]="true"
                    [columnAutoWidth]="true"
                    [allowColumnResizing]="true"
                    [showBorders]="true"
                    [height]="'100%'"
                  >
                    <!-- Toolbar -->
                    <dxo-toolbar>
                      <dxi-item location="before">
                        <div style="font-size: 20px;">Références fournisseurs</div>
                      </dxi-item>
                      <dxi-item>
                        <div *dxTemplate>
                          <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setReferencesFournisseurs()" stylingMode="text"></dx-button>
                        </div>
                      </dxi-item>
                    </dxo-toolbar>
                    <dxi-column dataField="codeArticleFournisseur" width="200" caption="Référence"></dxi-column>
                    <dxi-column dataField="fournisseurIntitule" caption="Libellé"></dxi-column>
                  </dx-data-grid>
                </div>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Logistique -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Logistique]" *ngIf="!isCreation">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Logistique]]" itemType="tabbed">
                <dxo-tab-panel-options [showNavButtons]="true" stylingMode="secondary" tabsPosition="left"></dxo-tab-panel-options>
                <dxi-tab title="Conditionnements" [style]="{ padding: '0 12px' }">
                  <app-conditionnement-list [articleId]="article?.id" [uniteBaseId]="article.uniteBaseId" [offsetTopInPx]="225"></app-conditionnement-list>
                </dxi-tab>
                <dxi-tab title="Codes barres" [style]="{ padding: '0 12px' }">
                  <app-code-barre-article-detail [articleId]="article.id" [uniteBaseId]="article.uniteBaseId" [offsetTopInPx]="225"></app-code-barre-article-detail>
                </dxi-tab>
                <dxi-tab title="Lieux de stockage" [style]="{ padding: '0 12px' }">
                  <app-lieu-stockage-article [articleId]="article.id" [siteId]="currentSiteId" [offsetTopInPx]="225"></app-lieu-stockage-article>
                </dxi-tab>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Numéro de série -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.NumeroSerie]" *ngIf="!isCreation && article.nombreNumeroSerie > 0">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.NumeroSerie]]">
                <app-registre-numero-serie-list *ngIf="!isCreation" [articleId]="article.id" [canSelectionnerArticle]="false"
                [height]="'68vh'"></app-registre-numero-serie-list>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Historiques -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Historique]" *ngIf="!isCreation">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Historique]]" itemType="tabbed">
                <dxo-tab-panel-options [showNavButtons]="true" stylingMode="secondary" tabsPosition="left"></dxo-tab-panel-options>
                <dxi-tab *ngIf="article.type === ArticleType.backOffice" title="Bons" [style]="{ padding: '0 12px' }">
                  <app-article-detail-bon-list
                    [articleId]="article.id"
                    [currentPartenaireId]="currentPartenaireId"
                    [offsetTopInPx]="225"
                  ></app-article-detail-bon-list>
                </dxi-tab>
                <dxi-tab *ngIf="article.type === ArticleType.backOffice" title="Mouvements" [style]="{ padding: '0 12px' }">
                  <app-mouvement-stock-datagrid
                    [showTitle]="false"
                    [configFiltre]="configFiltre"
                    [articleId]="article?.id"
                    [currentPartenaireId]="currentPartenaireId"
                    [offsetTopInPx]="225"
                  ></app-mouvement-stock-datagrid>
                </dxi-tab>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Caractéristique -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Caracteristiques]" *ngIf="!isCreation">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Caracteristiques]]">
                <div *dxTemplate style="padding: 12px; height: 100%;">
                  <app-gestion-caracteristiques [objectId]="article.id" [objectType]="objectType.article"></app-gestion-caracteristiques>
                </div>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Classifications -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Classifications]" [colCount]="2">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Classifications]]"
                dataField="articleParentId"
                [label]="{ text: 'Article parent' }"
                editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: articleDataSource,
                  searchEnabled: true,
                  displayExpr: 'libelleLong',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['libelleLong', 'codeBo'],
                  showDataBeforeSearch: true
                }"
              ></dxi-item>

              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Classifications]]"
                   dataField="familleId" [label]="{ text: 'Famille d\'articles' }" editorType="dxSelectBox"
                [editorOptions]="{
                  dataSource: familleDataSource,
                  searchEnabled: true,
                  displayExpr: 'libelleLong',
                  valueExpr: 'id',
                  showClearButton: true,
                  searchExpr: ['libelleLong'],
                  showDataBeforeSearch: true,
                }"
              ></dxi-item>

              <dxi-item *ngIf="!isCreation && isClassificationsDuParent && !isModificationEnCours
                        && isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Classifications]]"
              [colSpan]="2">
                <div *dxTemplate style="height: 24px; background-color: #FFF3CD;">
                  <span *ngIf="classifications.length == 1" style="color: grey;">La classification ci-dessous provient de l'article parent</span>
                  <span *ngIf="classifications.length > 1" style="color: grey;">Les classifications ci-dessous proviennent de l'article parent</span>
                </div>
              </dxi-item>

              <!-- DataGrid Classifications -->
              <dxi-item *ngIf="!isCreation && isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Classifications]]" [colSpan]="2">
                <div *dxTemplate>
                  <app-classification-datagrid
                    [heightToDeduce]="280"
                    [classificationsDto]="!isModificationEnCours && isClassificationsDuParent ? classifications : []"
                    [objectType]="objectType.article"
                    [itemId]="!isModificationEnCours && isClassificationsDuParent ? article?.articleParentId : article?.id"
                    [allowEdit]="isModificationEnCours || !isClassificationsDuParent"
                    (classificationsMisesAJour)="onClassificationsMisesAJour($event)"
                  ></app-classification-datagrid>
                </div>
              </dxi-item>
            </dxi-tab>

            <!-- Onglet Audit -->
            <dxi-tab [title]="dictionnaireTabTitles[tabTitlesEnum.Audit]" *ngIf="!isCreation">
              <dxi-item *ngIf="isTabLoaded[dictionnaireTabTitles[tabTitlesEnum.Audit]]">
                <dx-tab-panel style="padding : 0; margin:0">
                  <dxi-item title="Article" style="padding: 0;">
                    <app-audit-piste objectType="article" [objectId]="article.id" [offsetTopInPx]="275"></app-audit-piste>
                  </dxi-item>
                  <dxi-item title="Tarifs" style="padding: 0;">
                    <app-tarif-article-historique [articleId]="article?.id"></app-tarif-article-historique>
                  </dxi-item>
                </dx-tab-panel>
              </dxi-item>
            </dxi-tab>
          </dxi-item>

          <div *dxTemplate="let data of 'prixRevientLabelTemplate'">
            <div id="template-content">
              <span>Prix de revient</span>
              <a (click)="opentPrixRevientPopup()" href="javascript:void(0);"
                style="text-decoration: none; margin-left: 5px">
                <i class="dx-icon-edit"></i>
              </a>
            </div>
          </div>
          <div *dxTemplate="let data of 'prixRevientHistoryLabelTemplate'">
            <div id="template-content">
              <span>Prix de revient</span>
              <a (click)='loadHistorique()' href="javascript:void(0);" style="text-decoration: none; margin-left: 5px;" title="Voir l'historique des prix de revient">
                <i class="dx-icon-clock" ></i>
              </a>
            </div>
          </div>
        </dx-form>
      </div>
      <!-- #endregion -->
    </div>

    <div *ngIf="!chargementInitialFait" style="display: flex; justify-content: center; align-items: center;">
      <dx-load-indicator [visible]="!chargementInitialFait" height="150" width="150"></dx-load-indicator>
    </div>
  </div>
</dx-scroll-view>

<!-- Popup : modification du prix de revient -->
<dx-popup [width]="320" [height]="150" title="Modifier le prix de revient" [(visible)]="showEditPrixRevientPopup"
  [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <div style="text-align: center">
      <dx-number-box [format]="{ type: 'fixedPoint', precision: 3 }" [(value)]="article.prixRevient"
        [width]="150"></dx-number-box>
    </div>
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      " *ngIf="!isCreation && isModificationEnCours">
      <dx-button text="Enregistrer" type="success" icon="save" (onClick)="onUpdatePrixRevient()"
        style="margin-right: 10px"></dx-button>
      <dx-button class="action-button" text="Annuler" type="danger" icon="undo" (onClick)="
          this.showEditPrixRevientPopup = false;
          this.article.prixRevient = this.prixRevientAvantModification
        ">
      </dx-button>
    </div>
  </div>
</dx-popup>

<!-- Popup : historique PMP -->
<dx-popup [showTitle]="true" height="auto" minHeight="525" title="Historique des prix de revient"
  [(visible)]="showPopupHistorique">
  <div style="display: flex; flex-direction:column; justify-content: space-between"
    *dxTemplate="let data = model; of: 'content'">
    <dx-data-grid [dataSource]="historiquePmp">
      <dxi-column dataField="dateChangement" caption="Date de changement" [allowEditing]="false" dataType="date"
        format="dd/MM/yyyy HH:mm"></dxi-column>
      <dxi-column dataField="pmpOriginal" caption="Ancien PMP" [allowEditing]="false">
        <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
      </dxi-column>
      <dxi-column dataField="nouveauPmp" caption="Nouveau PMP" [allowEditing]="false">
        <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
      </dxi-column>
      <dxi-column dataField="description" caption="Description" [allowEditing]="false"></dxi-column>
      <dxi-column dataField="createdByUser" caption="Changé par"></dxi-column>
    </dx-data-grid>
    <div style="display: flex; justify-content: end; align-items: center; margin-top: 20px">
      <dx-button class="action-button" text="Annuler" type="danger" icon="undo"
        (onClick)="this.showPopupHistorique=false;" />
    </div>
  </div>
</dx-popup>

<!-- Popup : Confirmation création d'article groupe taxe -->
<dx-popup [width]="450" [height]="'auto'" [(visible)]="showConfirmationTaxePopup" [showTitle]="true"
  [dragEnabled]="false" [showCloseButton]="true" title="Confirmation d'ajout">
  <div *dxTemplate="let data of 'content'">
    Cet article n'a pas de groupe de taxe.<br />
    Voulez-vous vraiment le créer ?
    <div style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
      ">
      <dx-button text="Enregistrer" type="success" icon="save" (onClick)="createArticle()"
        style="margin-right: 10px"></dx-button>
      <dx-button class="action-button" text="Annuler" type="danger" icon="undo"
        (onClick)="this.showConfirmationTaxePopup = false">
      </dx-button>
    </div>
  </div>
</dx-popup>

<dx-popover target="#classificationLegendeIcon" [width]="300" [(visible)]="showPopover">
  <div *dxTemplate="let data = model; of: 'content'">
    <span *ngIf="classifications.length == 1" style="color: grey;">La classification provient de l'article parent</span>
    <span *ngIf="classifications.length > 1" style="color: grey;">Les classifications proviennent de l'article parent</span>
  </div>
</dx-popover>