<dx-data-grid #dataGrid *ngIf="dataSource" id="article-list-datagrid-main-content" [dataSource]="dataSource"
  [allowColumnReordering]="false" [columnAutoWidth]="false" [allowColumnResizing]="true" [showBorders]="true"
  [height]="'100%'"
  (onRowInserting)="onRowInserting($event.data)" (onRowUpdating)="onRowUpdating($event)"
  (onRowRemoving)="onRowRemoving($event.key)" (onKeyDown)="onKeyDown($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" stylingMode="text" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="false"></dxo-filter-row>
  <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[25, 50, 100]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-group-panel [visible]="false"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-editing [allowUpdating]="canModifierCaracteristiques" [allowDeleting]="canModifierCaracteristiques"
    [allowAdding]="canModifierCaracteristiques" mode="row"
  ></dxo-editing>

  <!-- Colonnes -->
  <dxi-column dataField="caracteristique.caracteristiqueTypeIntitule" caption="Type" [allowEditing]="false" groupIndex="0"></dxi-column>
  <dxi-column dataField="caracteristique.id" caption="Caractéristique" cellTemplate="cellTemplate" editCellTemplate="editCellTemplate" alignment="left">
    <div *dxTemplate="let data of 'cellTemplate'">
      {{data.data.caracteristique.intitule}}
    </div>

    <div *dxTemplate="let cellInfo of 'editCellTemplate'">
      <dx-select-box
        [dataSource]="caracteristiquesList"
        [value]="cellInfo.value"
        valueExpr="id"
        displayExpr="intitule"
        [searchEnabled]="true"
        [searchExpr]="['intitule', 'codeBo']"
        (onValueChanged)="cellInfo.setValue($event.value)"
      ></dx-select-box>
    </div>
  </dxi-column>
  <dxi-column dataField="valeur" caption="Valeur"></dxi-column>
</dx-data-grid>