<dx-data-grid [dataSource]="historiqueTarif" 
  [allowColumnReordering]="true" 
  [allowColumnResizing]="true"
  [height]="'100%'"
  (onExporting)="onExporting($event, 'historiqueTarif')"
>
  <dxo-toolbar>
    <dxi-item name="exportButton" location="after"></dxi-item>
  </dxo-toolbar>
  
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-paging [enabled]="true" [pageSize]="10"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 50, 100]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  
  <dxi-column dataField="prixIntitule" caption="Prix" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="dateChangement" caption="Date de changement" dataType="date" format="dd/MM/yyyy HH:mm"
    [allowEditing]="false" [allowHeaderFiltering]="false" [sortIndex]="0" [sortOrder]="'desc'"></dxi-column>
  <dxi-column dataField="valeurOriginale" caption="Ancienne valeur" [allowEditing]="false" [allowHeaderFiltering]="false">
    <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
  </dxi-column>
  <dxi-column dataField="nouvelleValeur" caption="Nouvelle valeur" [allowEditing]="false" [allowHeaderFiltering]="false">
    <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
  </dxi-column>
  <dxi-column dataField="description" caption="Description" [allowEditing]="false" [allowHeaderFiltering]="false"></dxi-column>
  <dxi-column dataField="createdByUser" caption="Changé par"></dxi-column>
</dx-data-grid>
