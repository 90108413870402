<dx-data-grid
  [dataSource]="lieuStockageArticleDataSource"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [showBorders]="true"
  [columnAutoWidth]="true"
  (onRowRemoving)="onRowRemoving($event)"
  (onRowInserting)="onRowInserting($event)"
  (onRowUpdating)="onRowUpdating($event)"
  (onInitNewRow)="onInitNewRow()"
  (onExporting)="onExporting($event)"
  (onEditCanceled)="onEditCanceled()"
>

  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before">
      <div>{{ titre }}</div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" stylingMode="text" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="addRowButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
    <dxi-item name="exportButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-state-storing [enabled]="true" [storageKey]="dataGridStorageKey" appStorage></dxo-state-storing>
  <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true" [showNavigationButtons]="true"></dxo-pager>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-editing mode="popup" [allowAdding]="true" [allowUpdating]="canGererLieuStockage && accessFromFicheArticle" [allowDeleting]="canGererLieuStockage">
    <dxo-popup
      title="Associer un lieu de stockage"
      [showTitle]="true"
      [showCloseButton]="true"
      [height]="525"
      width="25%"
    ></dxo-popup>
    <dxo-form [colCount]="4">
      <dxi-item [colSpan]="4" [visible]="accessFromFicheArticle">
        <div *dxTemplate>
          Société
          <dx-switch (onValueChanged)="switchValueChanged()" [(value)]="switchValue"></dx-switch>
          Site en cours
        </div>
      </dxi-item>
      <dxi-item dataField="lieuStockageId"
        [visible]="accessFromFicheArticle"
        [colSpan]="4"
        [label]="{text: 'Lieu de stockage'}"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: lieuStockageDataSource,
          searchEnabled: true,
          displayExpr: 'intitule',
          valueExpr: 'id',
          searchExpr: ['intitule'],
          showDataBeforeSearch: true,
        }"></dxi-item>
      <dxi-item dataField="priorite" [colSpan]="1" [visible]="accessFromFicheArticle"></dxi-item>
      <dxi-item dataField="articleId" [colSpan]="4" [visible]="!accessFromFicheArticle"
        [label]="{ text: 'Article' }"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: articleDataSource,
          searchEnabled: true,
          displayExpr: 'libelleLong',
          valueExpr: 'id',
          searchExpr: ['libelleLong', 'codeBo'],
          showDataBeforeSearch: true,
          onValueChanged: onArticleChanged
        }"
        ></dxi-item>
    </dxo-form>
  </dxo-editing>

  <!-- Columns -->
  <!-- Vue depuis fiche article -->
  <dxi-column dataField="lieuStockageId" [visible]="false" caption="Lieu de stockage" [allowGrouping]="false" [allowEditing]="isAdding">
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column dataField="codeLieuStockage" caption="Code" [allowGrouping]="false" [allowEditing]="false" [visible]="accessFromFicheArticle"></dxi-column>
  <dxi-column dataField="intituleLieuStockage" caption="Intitule" [allowGrouping]="false" [allowEditing]="false" [visible]="accessFromFicheArticle"></dxi-column>
  <dxi-column dataField="intituleLieuStockageNature" caption="Nature" [allowGrouping]="false" [allowEditing]="false" [visible]="accessFromFicheArticle"></dxi-column>
  <dxi-column dataField="lieuParentCodeBo" caption="Lieu parent" [allowGrouping]="false" [allowEditing]="false" [visible]="accessFromFicheArticle"></dxi-column>
  <dxi-column dataField="lieuParentIntitule" caption="Intitule parent" [allowGrouping]="false" [allowEditing]="false" [visible]="false"></dxi-column>
  <dxi-column dataField="isPickingLieuStockage" caption="Picking" dataType="boolean" [width]="100" [allowGrouping]="false" [visible]="accessFromFicheArticle"></dxi-column>
  <dxi-column dataField="priorite" caption="Priorité" dataType="number" [allowGrouping]="false" [visible]="accessFromFicheArticle"></dxi-column>
  <dxi-column dataField="dateDePointage" dataType="date" caption="Pointage" [allowGrouping]="false" [visible]="accessFromFicheArticle"></dxi-column>

  <!-- Vue depuis lieu de stockage -->
  <dxi-column dataField="articleId" caption="Article" [allowGrouping]="false" [allowEditing]="!accessFromFicheArticle" [visible]="false"></dxi-column>
  <dxi-column dataField="articleCodeBo" caption="Code" [allowGrouping]="false" [allowEditing]="false" [visible]="!accessFromFicheArticle" cellTemplate="cellTemplate">
    <div *dxTemplate="let data of 'cellTemplate'">
      <a routerLink="/article/{{data.data.articleId}}">
        {{data.value}}
      </a>
    </div>
  </dxi-column>
  <dxi-column dataField="articleLibelleLong" caption="Libelle" [allowGrouping]="false" [allowEditing]="false" [visible]="!accessFromFicheArticle"></dxi-column>
</dx-data-grid>