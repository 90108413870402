<!-- Toolbar onglet Comptages -->
<dx-toolbar style="height: 48px;">
  <!-- Comptage en cours -->
  <dxi-item *ngIf="comptage" location="center" locateInMenu="never">
    <div *dxTemplate>
      <!-- Mode multi-comptage -->
      <ng-container *ngIf="bon?.strategieComptage?.activerMultiComptage">
        <span style="padding-right: 1rem;"><i>({{marchandiseLignes?.length}} référence{{marchandiseLignes?.length > 1 ? 's' : ''}})</i></span>
        <dx-drop-down-button
          text="{{comptage.intitule}} | N°{{comptage.numero}} | {{comptageStatutIntitule}} | {{comptage.utilisateurAffecteIntitule}}"
          [items]="comptages"
          keyExpr="id"
          class="dropdown-actions"
          (onItemClick)="onChangementComptage($event.itemData)"
          style="margin-right: 6px; min-width: 160px;"
          itemTemplate="listComptageItemTemplate"
        >
          <div *dxTemplate="let data of 'listComptageItemTemplate'">
            <div>{{data?.intitule}} | N°{{data.numero}} | {{ data?.statutIntitule }} | {{data?.utilisateurAffecteIntitule}}</div>
          </div>
        </dx-drop-down-button>

        <!-- Boutons d'actions tant que le bon n'est pas clôturé -->
        <ng-container *ngIf="bon?.fluxStatut != fluxStatut.closed">
          <!-- Bouton modifier un comptage -->
          <dx-button icon="edit" hint="Modifier un comptage" style="margin-right: 6px;"
            [disabled]="!canCreerComptage || isLoading || bon?.fluxStatut == fluxStatut.paused || isModificationEnCours"
            (onClick)="showComptagePopup(comptage?.id)"
          ></dx-button>

          <!-- Bouton créer un comptage -->
          <dx-button icon="add" hint="Créer un comptage"
            [disabled]="!canCreerComptage || isLoading || bon?.fluxStatut == fluxStatut.paused || isModificationEnCours"
            (onClick)="showComptagePopup()"
          ></dx-button>
        </ng-container>
      </ng-container>

      <!-- Mode comptage unique -->
      <div *ngIf="!bon?.strategieComptage?.activerMultiComptage" style="font-size: 1rem;">
        {{comptage.intitule}} - {{comptageStatutIntitule}} ({{marchandiseLignes?.length}} référence{{marchandiseLignes?.length > 1 ? 's' : ''}})
      </div>
    </div>
  </dxi-item>
  <dxi-item location="center">
    <div *dxTemplate>
      <div *ngIf="bon?.fluxStatut != fluxStatut.closed" style="display: flex">
        <dx-button
          *ngIf="isModeSimple && hasComptagesNonAbandonnes === false && !canReprendreComptage"
          (onClick)="demarrerComptage()"
          [disabled]="!canCreerComptage || !canDemarrerComptage || isLoading || bon?.fluxStatut == fluxStatut.paused || isModificationEnCours"
          text="Démarrer le comptage"
        ></dx-button>
        <div style="font-size: 27px; display: block; margin-left: 3px;" *ngIf="bon?.fluxStatut == fluxStatut.paused">
          <app-flux-statut-icon [statut]="bon?.fluxStatut" [title]="'Le statut \'' + bonFluxStatutLibelle + '\' du bon ne permet pas de lancer le comptage.'" [fontSize]="'27px'"></app-flux-statut-icon>
        </div>
        <dx-button *ngIf="isModeSimple && isComptageEnCours && canValiderComptage" (onClick)="validerComptage()" [disabled]="isLoading" text="Valider le comptage" style="margin-left: 6px;" type="success"></dx-button>
        <dx-button *ngIf="canReprendreComptage" (onClick)="reprendreComptage()" [disabled]="isLoading" text="Reprendre le comptage" style="margin-left: 6px;"></dx-button>
        <dx-button *ngIf="isModeSimple && isComptageAnnulable" (onClick)="annulerComptage()" [disabled]="isLoading" text="Annuler le comptage" style="margin-left: 6px;"></dx-button>
      </div>
    </div>
  </dxi-item>
  <dxi-item *ngIf="isModeAvance" location="center" >
    <div *dxTemplate>
      <b>L'option <i>Autoriser Comptage Terminal</i> de la stratégie de comptage est activée</b><br />
      <span>Utiliser les terminaux mobiles pour effectuer les comptages ou bien désactiver l'option dans la stratégie de comptage.</span>
    </div>
  </dxi-item>
  <dxi-item *ngIf="isModeAvance === false && isModeSimple ===  false" location="center" >
    <div *dxTemplate>
      <b>Aucune stratégie de comptage n'a été définie pour ce bon</b>
    </div>
  </dxi-item>
</dx-toolbar>

<!-- #region DataGrid ComptageMarchandiseLigne -->
<dx-data-grid
  *ngIf="comptage"
  keyExpr="comptageLigneUniqueId"
  [dataSource]="dataSource"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [showBorders]="true"
  height="calc(100% - 48px)"
  (onRowInserting)="onRowInserting($event)"
  (onRowUpdating)="onRowUpdating($event)"
  (onRowRemoving)="onRowRemoving($event)"
  (onInitNewRow)="onInitNewRow($event)"
  (onContentReady)="onContentReady($event)"
  (onEditCanceled)="onEditCanceled($event)"
  (onEditorPreparing)="onEditorPreparing($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onRowInserted)="onRowInserted($event)"
  (onExporting)="onExporting($event, exportFileName)"
  >
  <!-- #region Options -->
  <dxo-sorting mode="single"></dxo-sorting>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <!-- newRowPosition="pageTop" affichera toujours la saisie de la nouvelle ligne en haut de la page courante
    afin d'éviter qu'elle apparaîsse au milieu d'un groupe, ce qui est source de confusion -->
  <dxo-editing mode="cell" [allowAdding]="isComptageEnCours" [allowUpdating]="isComptageEnCours" [allowDeleting]="isComptageEnCours"></dxo-editing>

  <!-- State Désactivé pour le moment, nécessite de gérer le filtre Article à compter -->
  <!-- <dxo-state-storing [enabled]="true" type="localStorage" storageKey="grille_bon_detail_comptage"></dxo-state-storing> -->
  <!-- #endregion -->

  <!-- #region Toolbar -->
  <dxo-toolbar>
    <!-- TODO : A réactiver une fois la classe "highlight" rajoutée (cf. onRowPrepared) -->
    <!-- <dxi-item location="before">
      <div *dxTemplate>
        <i id="bonDetailComptageLegendeIcon" style="font-size: 18px; cursor: pointer;" class="dx-icon-info" (mouseenter)="showLegende = true" (mouseleave)="showLegende = false"></i>
      </div>
    </dxi-item> -->
     <!-- Bouton Grouper / Dégrouper -->
    <dxi-item *ngIf="comptage" location="before" style="margin-left: -8px;">
      <div *dxTemplate>
        <dx-button [text]="isGrouped ? 'Dégrouper' : 'Grouper' " (onClick)="toggleGrouping(isGrouped)" [disabled]="isLoading"></dx-button>
      </div>
    </dxi-item>

    <!-- SelectBox Article à compter -->
    <dxi-item *ngIf="comptage" location="before">
      <div *dxTemplate>
        <dx-select-box
          [dataSource]="articlesACompterDataSource"
          [displayExpr]="articleDisplayExpr"
          searchEnabled="true"
          [searchExpr]="['intitule', 'codeBo']"
          [width]="250"
          valueExpr="id"
          [value]="currentArticle?.id"
          placeholder="Article à compter"
          (onValueChanged)="onCurrentArticleChanged($event)"
        ></dx-select-box>
      </div>
    </dxi-item>

    <!-- Bouton réinitialiser filtres -->
    <dxi-item *ngIf="comptage" location="before">
      <div *dxTemplate>
        <dx-button icon="clearformat" hint="Réinitialise les filtres" (onClick)="onClearCurrentArticle()" stylingMode="text"></dx-button>
      </div>
    </dxi-item>

    <!-- Switch Scan Numéro série -->
    <dxi-item *ngIf="isComptageEnCours" location="before">
      <div *dxTemplate style="display: flex; align-items: center;">
          <span>N° série</span>
          <dx-switch [(value)]="isScanningNumeroSerie" style="margin-left: 4px;"></dx-switch>
      </div>
    </dxi-item>
    <!-- Scan input -->
    <dxi-item *ngIf="isComptageEnCours" location="before">
      <div *dxTemplate style="margin-left: 1rem;">
        <dx-text-box #scanTextBox [(ngModel)]="scanValue" (onEnterKey)="onScanArticle()" width="180" placeholder="Scanner ici"></dx-text-box>
      </div>
    </dxi-item>

    <!-- Bouton d'ajout d'article -->
    <dxi-item *ngIf="canAjouterArticle" location="after" locateInMenu="auto">
      <div *dxTemplate style="display: flex; align-items: center; padding: 2px;">
        <dx-button icon="add" text="Ajouter" (onClick)="popupAjoutArticlesVisible = true" [disabled]="!isComptageEnCours || isLoading" style="margin-left: 6px;"></dx-button>
      </div>
    </dxi-item>

    <!-- Choix du conditionnement -->
    <dxi-item location="after" locateInMenu="auto">
      <div *dxTemplate>
        <dx-select-box [dataSource]="conditionnementTypeDataSource" displayExpr="intitule" valueExpr="id"
          [value]="selectedConditionnementType" (onValueChanged)="onConditionnementTypeChanged($event)"
          [width]="90"
        ></dx-select-box>
      </div>
    </dxi-item>

    <!-- Choix du colis -->
    <dxi-item *ngIf="isComptageEnCours && canEditNumeroColis" location="after" locateInMenu="auto">
      <div *dxTemplate style="display: flex; align-items: center; padding: 2px;">
          <span>Colis</span>
          <dx-select-box *ngIf="paquets.length > 0" [dataSource]="paquets" [displayExpr]="paquetDisplayExpr"
            [width]="90" [(ngModel)]="currentPaquet"  style="margin-left: 4px;"
          ></dx-select-box>
      </div>
    </dxi-item>

    <!-- Bouton Rafraichir les comptages -->
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir les comptages" (onClick)="loadComptages(comptage?.id)" stylingMode="text"></dx-button>
      </div>
    </dxi-item>

    <!-- Bouton Export Excel -->
    <dxi-item name="exportButton" ></dxi-item>

    <!-- Bouton Column Chooser -->
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>
  <!-- #endregion -->

  <!-- #region Colonnes -->
  <!-- Colonne actions -->
  <dxi-column type="buttons" [width]="110">
    <dxi-button name="edit" cssClass="visible-on-hover"></dxi-button>
    <dxi-button name="delete" cssClass="visible-on-hover"></dxi-button>
    <dxi-button *ngIf="isComptageEnCours && !isGrouped" name="add" cssClass="visible-on-hover" hint="Ajouter une ligne sur cet article" [onClick]="onAjouterArticleSurLigne"></dxi-button>
    <dxi-button name="save"></dxi-button>
    <dxi-button name="cancel"></dxi-button>
  </dxi-column>

  <!-- Article -->
  <dxi-column dataField="articleCodeBo" caption="Code" width="120" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="referenceFournisseur" caption="Réf. Fournisseur" width="200" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="articleIntitule" caption="Article" [groupIndex]="0" sortOrder="asc" [allowEditing]="false" groupCellTemplate="groupTemplate"></dxi-column>
  <div *dxTemplate="let group of 'groupTemplate'">
    <dx-button *ngIf="isComptageEnCours" icon="add" type="default" stylingMode="text" [text]="group.displayValue" (onClick)="onAddToGroup(group)"></dx-button>
    <dx-button *ngIf="!isComptageEnCours" type="default" stylingMode="text" [text]="group.displayValue"></dx-button>
  </div>

  <!-- Quantité -->
  <dxi-column *ngIf="showQuantitePrevue" dataField="quantiteInitialeConvertie" caption="Quantité Prévue" dataType="number" width="141"
    [allowHeaderFiltering]="false" [allowEditing]="false" [customizeText]="customizeQtePrevue" cssClass="bold">
  </dxi-column>

  <dxi-column dataField="quantiteCompteeConvertie" caption="Quantité Comptée" dataType="number" width="155" [allowHeaderFiltering]="false"
    [editorOptions]="{ onFocusIn: onFocusQteComptee, valueChangeEvent: 'keyup' }"
    cssClass="isEditable"
  >
    <dxi-validation-rule type="numeric" ignoreEmptyValue="false"></dxi-validation-rule>
    <dxi-validation-rule *ngIf="showNoSerieInputs" type="custom" message="La quantité doit être 1 quand il y a un numéro de série" [validationCallback]="validerQuantiteComptee"></dxi-validation-rule>
    <dxi-validation-rule type="compare" [comparisonTarget]="quantiteComparisonTarget" comparisonType=">="
      [ignoreEmptyValue]="true" message="Une quantité comptée ne peut être négative"
    ></dxi-validation-rule>
  </dxi-column>

  <dxi-column dataField="uniteId" caption="Unité" width="130" [allowHeaderFiltering]="false" [allowEditing]="false" [calculateCellValue]="calculateUniteValue">
    <dxo-lookup [dataSource]="uniteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <!-- N° Série -->
  <dxi-column *ngIf="showNumeroSerie" dataField="numeroSerie" caption="No série" width="200" cssClass="isEditable"></dxi-column>

  <!-- Icon alerte référence inattendue -->
  <dxi-column dataField="isInattendu" caption="" width="90" dataType="boolean" cellTemplate="inattenduTemplate" [allowEditing]="false">
    <div *dxTemplate="let cell of 'inattenduTemplate'">
      <i *ngIf="cell.value" class="dx-icon-warning"></i>
    </div>
  </dxi-column>

  <!-- N° Colis -->
  <dxi-column *ngIf="showNumeroColis" dataField="numeroColis" caption="No Colis" dataType="number" width="100" [allowEditing]="canEditNumeroColis"
    [editorOptions]="{ onInitialized: setCurrentInputNumeroSerie }"
    [cssClass]="canEditNumeroColis? 'isEditable': ''"
  >
  </dxi-column>

  <dxi-column dataField="commentaire" caption="Commentaire" cssClass="isEditable"></dxi-column>
  <dxi-column dataField="createdOn" caption="Créé le" dataType="date" format="dd/MM/yyyy HH:mm:ss" [allowEditing]="false"  [sortIndex]="0" sortOrder="desc"></dxi-column>
  <dxi-column dataField="modifiedOn" caption="Modifié le" dataType="date" format="dd/MM/yyyy HH:mm:ss" [visible]="false" [allowEditing]="false"></dxi-column>
  <dxi-column dataField="zIndex" caption="Index" [visible]="false" [allowEditing]="false"></dxi-column>

  <dxo-summary>
    <dxi-group-item
        column="quantiteInitialeConvertie"
        summaryType="max"
        valueFormat="#0.###"
        displayFormat="{0}"
        [showInGroupFooter]="false"
        [alignByColumn]="true">
    </dxi-group-item>
    <dxi-group-item
        column="quantiteCompteeConvertie"
        summaryType="sum"
        valueFormat="#0.###"
        displayFormat="Total: {0}"
        [showInGroupFooter]="false"
        [alignByColumn]="true">
    </dxi-group-item>
  </dxo-summary>
  <!-- #endregion -->
</dx-data-grid>
<!-- #endregion -->

<!-- Popup Sélection article parmi les lignes -->
<dx-popup
  [width]="800"
  [height]="500"
  [showTitle]="true"
  title="Sélectionner l'article parmi les lignes"
  [showCloseButton]="true"
  container=".dx-viewport"
  [(visible)]="popupMultiLignesVisible"
  (onHiding)="annulerMultiLigne()"
>
  <div *dxTemplate="let data of 'content'">
    <dx-data-grid
      #datagridMultiLigne
      [dataSource]="articleMultiZIndexItems"
      [columnAutoWidth]="true"
      [allowColumnResizing]="true"
      [showBorders]="true"
      >
      <!-- Options -->
      <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-scrolling showScrollbar="always"></dxo-scrolling>
      <dxo-selection mode="single"></dxo-selection>

      <!-- Article -->
      <dxi-column dataField="articleCodeBo" caption="Code" width="120"></dxi-column>
      <dxi-column dataField="referenceFournisseur" caption="Réf. Fournisseur" width="200"></dxi-column>
      <dxi-column dataField="articleIntitule" caption="Article"></dxi-column>

      <!-- Quantité -->
      <dxi-column *ngIf="showQuantitePrevue" dataField="quantiteInitialeConvertie" caption="Qté Prévue" dataType="number" width="130" [customizeText]="customizeQtePrevue">
      </dxi-column>

      <dxi-column dataField="uniteId" caption="Unité" width="130">
        <dxo-lookup [dataSource]="uniteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
      </dxi-column>

      <dxi-column dataField="zIndex" caption="Index"></dxi-column>
    </dx-data-grid>

    <dx-toolbar>
      <dxi-item location="before">
        <div *dxTemplate>
          <dx-button (onClick)="annulerMultiLigne()" text="Annuler"></dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button (onClick)="confirmerMultiLigne(datagridMultiLigne.instance.getSelectedRowsData()[0])" text="Sélectionner cette ligne" type="success"></dx-button>
        </div>
      </dxi-item>
    </dx-toolbar>
  </div>
</dx-popup>

<!-- Popup ajout article manuel -->
<dx-popup
  *ngIf="popupAjoutArticlesVisible"
  title="Sélectionner l'article à ajouter"
  [(visible)]="popupAjoutArticlesVisible"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [width]="1000"
  [height]="620"
>
  <dx-scroll-view>
    <app-article-list-datagrid
      #articleListDatagrid
      [initComponent]="articlesToAddDataSource.length === 0"
      [autoriserFiltreGlobal]="articlesToAddDataSource.length === 0"
      [articleDataSource]="articlesToAddDataSource"
      [onlyStockable]="true"
      [isInPopup]="true"
      typeSelection="single"
      grilleStorageKey="bonDetailComptageAjoutArticleDataGrid"
      (selectedArticlesChanged)="onAjouterArticle($event)"
      (closePopup)="popupAjoutArticlesVisible = false"
    ></app-article-list-datagrid>
  </dx-scroll-view>
  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="{
    text: 'Annuler', onClick: articleListDatagrid?.onCancel, type: 'danger'
  }">
  </dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after" [options]="{
    text: 'Confirmer la sélection', onClick: articleListDatagrid?.onConfirm, type: 'success'
  }">
  </dxi-toolbar-item>
</dx-popup>

<!-- Popup Ecarts de quantité -->
<dx-popup
  title="Quantités en écart"
  [(visible)]="popupEcartsQuantitesVisible"
  [showCloseButton]="true"
  [resizeEnabled]="true"
  [width]="1000"
  height="90vh"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view>
      <dx-tab-panel secondary="secondary" [focusStateEnabled]="false" [showNavButtons]="true" style="height: 100%;">
        <dxi-item title="Écarts">
          <div *dxTemplate>
            <!-- DataGrid : Quantités en écart -->
            <dx-data-grid
              *ngIf="ecartDataSource?.length > 0; else aucuneQuantiteEnEcart"
              [dataSource]="ecartDataSource"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [showBorders]="true"
              height="100%"
            >
              <!-- Options -->
              <dxo-column-chooser [enabled]="true" mode="select" [height]="300"></dxo-column-chooser>
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxo-scrolling showScrollbar="always"></dxo-scrolling>
              <!-- Article -->
              <dxi-column dataField="articleCodeBo" caption="Code" width="120"></dxi-column>
              <dxi-column dataField="referenceFournisseur" caption="Réf. Fournisseur" width="150"></dxi-column>
              <dxi-column dataField="articleIntitule" caption="Article"></dxi-column>
              <!-- Quantité -->
              <dxi-column dataField="quantitePrevue" caption="Qté Prévue" dataType="number" width="130"></dxi-column>
              <dxi-column dataField="quantiteComptee" caption="Qté Comptée" dataType="number" width="130"></dxi-column>
              <!-- Unité -->
              <dxi-column dataField="uniteId" caption="Unité" width="130">
                <dxo-lookup [dataSource]="uniteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
              </dxi-column>
            </dx-data-grid>

            <ng-template #aucuneQuantiteEnEcart>
              <h4>Aucune quantité en écart</h4>
            </ng-template>
          </div>
        </dxi-item>

        <dxi-item title="Articles inattendus" *ngIf="ecartInattendusDataSource?.length > 0">
          <div *dxTemplate>
            <!-- DataGrid : Quantités inattendues -->
            <dx-data-grid
              [dataSource]="ecartInattendusDataSource"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [showBorders]="true"
              height="100%"
            >
              <!-- Options -->
              <dxo-filter-row [visible]="true"></dxo-filter-row>
              <dxo-scrolling showScrollbar="always"></dxo-scrolling>
              <!-- Article -->
              <dxi-column dataField="articleCodeBo" caption="Code" width="120"></dxi-column>
              <dxi-column dataField="referenceFournisseur" caption="Réf. Fournisseur" width="200"></dxi-column>
              <dxi-column dataField="articleIntitule" caption="Article"></dxi-column>
              <!-- Quantité -->
              <dxi-column dataField="quantiteComptee" caption="Qté Comptée" dataType="number" width="130"></dxi-column>
              <!-- Unité -->
              <dxi-column dataField="uniteId" caption="Unité" width="130">
                <dxo-lookup [dataSource]="uniteDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
              </dxi-column>
            </dx-data-grid>
          </div>
        </dxi-item>
      </dx-tab-panel>
    </dx-scroll-view>
  </div>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{
      text: 'Retour',
      type: 'normal',
      stylingMode: 'contained',
      onClick: onCancelPopupEcart
    }"
  ></dxi-toolbar-item>

  <dxi-toolbar-item
    widget="dxButton"
    location="after"
    toolbar="bottom"
    [options]="{
      text: 'Confirmer les quantités',
      type: 'default',
      stylingMode: 'contained',
      onClick: onValiderEcartsQuantites
    }"
  ></dxi-toolbar-item>
</dx-popup>

<!-- Popup création d'un comptage -->
<dx-popup
  [width]="400"
  [height]="400"
  [title]="titlePopupComptage"
  [hideOnOutsideClick]="false"
  [(visible)]="comptagePopupVisible"
  [showCloseButton]="true"
  [resizeEnabled]="true"
>
  <div *dxTemplate="let data of 'content'">
    <dx-load-panel [visible]="isLoading"></dx-load-panel>
    <dx-form [formData]="comptageForPopup">
      <dxi-item dataField="intitule">
        <dxi-validation-rule type="required" message="Intitulé obligatoire"></dxi-validation-rule>
      </dxi-item>

      <dxi-item dataField="numero" editorType="dxSelectBox" [editorOptions]="{ items: listeNumerosComptage }">
        <dxi-validation-rule type="required" message="N° Comptage obligatoire"></dxi-validation-rule>
        <dxo-label text="N° Comptage"> </dxo-label>
      </dxi-item>

      <dxi-item dataField="utilisateurAffecteId"
        editorType="dxSelectBox"
        [editorOptions]="{
          dataSource: utilisateursDataSource,
          valueExpr: 'id',
          displayExpr: 'intitule',
          searchMode: 'contains',
          searchExpr: 'intitule',
          searchTimeout: 200,
          searchEnabled: true,
          showClearButton: true
        }"
      >
        <dxo-label text="Utilisateur Affecté" location="top" alignment="left"></dxo-label>
      </dxi-item>
    </dx-form>
  </div>

  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after"
    [options]="{ text: 'Valider', onClick: createOrUpdateComptage }"
  ></dxi-toolbar-item>
  <dxi-toolbar-item widget="dxButton" toolbar="bottom" location="after"
    [options]="{ text: 'Annuler', onClick: hideComptagePopup }"
  ></dxi-toolbar-item>
</dx-popup>

<!-- Popover: Légende couleur -->
<dx-popover
  target="#bonDetailComptageLegendeIcon"
  [width]="300"
  [showTitle]="true"
  title="Légende :"
  [(visible)]="showLegende"
>
  <div *dxTemplate="let data = model; of: 'content'">
    <span class="highlight">Vert</span> = Lignes enfants à une ligne avec l'information de la quantité prévue.<br><br>
  </div>
</dx-popover>