import {ChangeDetectorRef, Component, Input, ViewChild} from "@angular/core";
import {ArticlesLexiClient, Permissions, TarifDto, TarifsLexiClient, TypeTaxe } from "@lexi-clients/lexi";
import {DxDataGridComponent, DxFormComponent} from "devextreme-angular";
import DataSource from "devextreme/data/data_source";
import {lastValueFrom} from "rxjs";
import {CustomStoreService} from "../../services/custom-store.service";
import {PrixListService} from "../../services/prix.service";
import {CalculInfoPrixService} from "../../shared/services/calcul-info-prix.service";
import {AuthService} from "../../settings/auth.service";
import notify from "devextreme/ui/notify";
import { NotificationType } from "../../modules/shared/references/references";

@Component({
    selector: "app-tarif-article-list",
    templateUrl: "./tarif-article-list.component.html",
    styleUrls: ["./tarif-article-list.component.scss"],
})
export class TarifArticleListComponent {
    @ViewChild("form") form: DxFormComponent;
    @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;

    private _articleId: number;
    get articleId(): number { return this._articleId; }
    @Input() set articleId(value: number) {
        this._articleId = value;
        if (value != null) this.onInit();
    }

    @Input() set tarifsDto(value: TarifDto[]) {
        this.tarifs = value;
        if (value != null) this.onInit();
    }

    showLegende = false;
    tarifs: TarifDto[];
    showPopupHistoriqueTarif = false;
    historiqueTarifId: number;
    prixDataSource: DataSource;
    isModificationAutorisee: boolean = false;
    isAffichagePMPAutorise: boolean = false;
    readonly typeTaxeDataSource = [
        {id: TypeTaxe.ht, intitule: "HT"},
        {id: TypeTaxe.ttc, intitule: "TTC"},
    ];
    isPrixSaisiVente: boolean = false;
    initialized = false;

    constructor(
        private readonly articlesLexiClient: ArticlesLexiClient,
        private readonly tarifsLexiClient: TarifsLexiClient,
        private readonly prixListService: PrixListService,
        private readonly calculInfoPrixService: CalculInfoPrixService,
        private readonly authService: AuthService,
        private readonly cdr: ChangeDetectorRef
    ) {}

    async onInit() {
        if (this.initialized) return;
        this.initialized = true;

        try {
            this.tarifs ??= await lastValueFrom(this.articlesLexiClient.getTarifsByArticleId(this.articleId));
            this.prixDataSource = new DataSource({
                key: "id",
                paginate: true,
                pageSize: 10,
                store: new CustomStoreService(
                    this.prixListService,
                ).getSelectBoxCustomStore(),
            });
            this.isModificationAutorisee = this.authService.securityUserisGrantedWith(Permissions.canModifierTarifs);
            this.isAffichagePMPAutorise = this.authService.securityUserisGrantedWith(Permissions.canAfficherPmp);
        }
        finally {
            this.cdr.detectChanges();
        }
    }

    async setTarifs() {
        if (this.articleId == null) {
            notify({ closeOnClick: true, message: `ArticleId n'est pas renseigné.` }, NotificationType.Warning);
            return;
        }
        this.tarifs = await lastValueFrom(this.articlesLexiClient.getTarifsByArticleId(this.articleId));
    }

    async onRowInserting(tarif: TarifDto) {
        try {
            tarif.articleId = this.articleId;
            await lastValueFrom(this.tarifsLexiClient.createOrUpdate(tarif));
        } finally {
            await this.setTarifs();
        }
    }

    async onRowUpdating(e: { oldData: TarifDto; newData: TarifDto }) {
        try {
            for (const field of Object.keys(e.newData)) {
                e.oldData[field] = e.newData[field];
            }
            await lastValueFrom(this.tarifsLexiClient.createOrUpdate(e.oldData));
        } finally {
            await this.setTarifs();
        }
    }

    async onRowRemoving(tarif: TarifDto) {
        try {
            await lastValueFrom(this.tarifsLexiClient._delete(tarif.id));
        } finally {
            await this.setTarifs();
        }
    }

    onInitNewRow = (e: { data: TarifDto }) => {
        e.data.saisiePrix = false;
        this.isPrixSaisiVente = false;
    };

    /*
      e.data = les changements
      e.key = les données déjà présente
     */
    onSaving(e: {
        cancel: boolean;
        changes: Array<{ type: string; data: TarifDto; key: TarifDto }>;
    }) {
        if (this.form?.instance?.validate != null) {
            e.cancel = this.form.instance.validate().isValid;
        }
    }

    onEditingStart(e: TarifDto) {
        this.isPrixSaisiVente = e.saisiePrix;
        if (e.saisiePrix) {
            e.valeur = null;
        }
    }

  // HT = TTC / (1 + Taux / 100)
  calculatePrixHt = (rowData: TarifDto) => {
    const valeurActuelle = rowData.valeurProchaine != null && rowData.dateProchaine != null && new Date(rowData.dateProchaine) <= new Date()
      ? rowData.valeurProchaine
      : (rowData.valeur ?? 0);
    return rowData.prixTypeTaxe == TypeTaxe.ttc ? valeurActuelle / (1 + rowData.sommeTauxTaxes / 100) : valeurActuelle;
  }

    calculateMargeBrute = (rowData: TarifDto) => {
        const ht = this.calculatePrixHt(rowData);
        return this.calculInfoPrixService.calculateMargeBrute(ht, rowData.pmp);
    };

    calculateTauxMarge = (rowData: TarifDto) => {
        const ht = this.calculatePrixHt(rowData);
        return this.calculInfoPrixService.calculateTauxMarge(ht, rowData.pmp);
    };

    calculateTauxMarque = (rowData: TarifDto) => {
        const ht = this.calculatePrixHt(rowData);
        return this.calculInfoPrixService.calculateTauxMarque(ht, rowData.pmp);
    };

    calculateCoefficient = (rowData: TarifDto) => {
        const ht = this.calculatePrixHt(rowData);
        return this.calculInfoPrixService.calculateCoefficient(ht, rowData.pmp);
    };

    checkCanSaisirValeur = (e: any) => {
        return e.data.saisiePrix || e.data.valeur > 0;
    };

    setSaisiePrixCellValue = (newData: TarifDto, value: any) => {
        newData.saisiePrix = value;
        this.isPrixSaisiVente = value;
        if (value) {
            newData.valeur = null;
        }
    };

    onRowPrepared(e: {
        rowType: "data" | "header";
        key: TarifDto;
        rowElement: HTMLElement;
        cells: Array<{
            cellElement: HTMLElement;
            key: TarifDto;
            column: { caption: string };
            value: any;
        }>;
    }) {
        if (e.rowType == "data") {
            let cellTitle: string;
            // Tarif.ValeurProchaine est utilisée
            if (
                e.key.dateProchaine != null &&
                e.key.valeurProchaine != null &&
                new Date(e.key.dateProchaine) < new Date()
            ) {
                cellTitle = "La propriété 'Valeur prochaine' est utilisée.";
                const valeurProchaineCell = e.cells.find(
                    (x) => x.column.caption == "Valeur prochaine",
                );
                if (valeurProchaineCell)
                    valeurProchaineCell.cellElement.style.fontWeight = "600";
            }
            // Tarif.Valeur est utilisée
            else {
                cellTitle = "La propriété 'Valeur' est utilisée.";
                const valeurCell = e.cells.find((x) => x.column.caption == "Valeur");
                if (valeurCell) valeurCell.cellElement.style.fontWeight = "600";
            }

            // Prix HT est le prix de base
            if (e.key.prixTypeTaxe == TypeTaxe.ht) {
                const prixHtCell = e.cells.find((x) => x.column.caption == "Prix HT");
                if (prixHtCell) {
                    prixHtCell.cellElement.classList.add("prix-de-base");
                    prixHtCell.cellElement.title = cellTitle;
                }

                const prixTtcCell = e.cells.find((x) => x.column.caption == "Prix TTC");
                if (prixTtcCell) prixTtcCell.cellElement.classList.add("prix-calcule");
            }
            // Prix TTC est le prix de base
            else if (e.key.prixTypeTaxe == TypeTaxe.ttc) {
                const prixTtcCell = e.cells.find((x) => x.column.caption == "Prix TTC");
                if (prixTtcCell) {
                    prixTtcCell.cellElement.classList.add("prix-de-base");
                    prixTtcCell.cellElement.title = cellTitle;
                }

                const prixHtCell = e.cells.find((x) => x.column.caption == "Prix HT");
                if (prixHtCell) prixHtCell.cellElement.classList.add("prix-calcule");
            }
        }
    }

  showHistoriqueTarif = async (element: any) => {
    if (element?.row?.data?.id) {
        this.historiqueTarifId = element?.row?.data?.id;
        this.showPopupHistoriqueTarif = true;
    }
  };

}