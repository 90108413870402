<div class="view-wrapper">
  <div style="padding: 12px;">
    <div style="display: flex; align-items: center; gap: 12px">
      <h2 class="content-block">Valorisation du stock</h2>
      <div style="display: flex; align-items: center; margin-left: 24px;">
        <span style="margin-right: 6px; cursor: pointer;" title="Tous lieux de stockage confondus"
          (click)="showWithLieuStockages = false">État {{'Site'}}</span>
        <dx-switch [(value)]="showWithLieuStockages" (onValueChanged)="onSwitchValueChanged()"></dx-switch>
        <span style="margin-left: 6px; cursor: pointer;" title="Séparés par lieux de stockage"
          (click)="showWithLieuStockages = true">Détails</span>
      </div>
      <div style="margin-left: 12px;">
        <dx-select-box placeholder="Sélectionner un prix" [dataSource]="prixDataSource" valueExpr="id"
          displayExpr="libelleLong" [searchEnabled]="true" [searchExpr]="['libelleLong', 'codeBo', 'libelleCourt']"
          [showClearButton]="true" [showDataBeforeSearch]="true" [value]="selectedPrixId"
          (onValueChanged)="onPrixChange($event)"></dx-select-box>
      </div>
      <div>
        <dx-date-box type="date" [value]="dateStock" [inputAttr]="{ 'aria-label': 'Date' }"
          (onValueChanged)="onDateChange($event)">
        </dx-date-box>
      </div>

    </div>

    <!-- DataGrid : Stock de l'agence -->
    <ng-container *ngIf="!showWithLieuStockages">
      <app-lot-stockage-magasin-datagrid [currentSiteId]="currentSiteId" [dataGridMarginTop]="'calc(100vh - 210px)'"
        [prixId]="selectedPrixId" [canAfficherPMP]="isAffichagePMPAutorise"></app-lot-stockage-magasin-datagrid>
    </ng-container>

    <!-- DataGrid : Stock avec lieux de stockages -->
    <ng-container *ngIf="showWithLieuStockages">
      <app-lot-stockage-datagrid [currentSiteId]="currentSiteId" [dataGridMarginTop]="'calc(100vh - 210px)'"
        [prixId]="selectedPrixId" [canAfficherPMP]="isAffichagePMPAutorise"
        [dateStock]="dateStock"></app-lot-stockage-datagrid>
    </ng-container>
  </div>
</div>