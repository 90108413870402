<div style="padding: 12px; height: 100%;">
    <h2><i id="listing-legend" style="font-size: 18px; cursor: pointer;" class="dx-icon-info"
        (mouseenter)="showLegende = true" (mouseleave)="showLegende = false"
      ></i>Listing</h2>  
    <div class="select-listing-block">
        <dx-select-box
            [dataSource]="listingArticles"
            [searchEnabled]="true"
            [searchExpr]="['libelle', 'codeBo']"
            [showDataBeforeSearch]="true"
            valueExpr="id"
            [displayExpr]="typesDisplayExpr"
            placeholder="Seclectionnez un Listing d'Articles"
            (onValueChanged)="onListingChanged($event?.value)"
            [width]="350"
        ></dx-select-box>
        <div>
            <dx-button icon="add" stylingMode="text" (onClick)="prepareAddPopup()"></dx-button>
            <dx-button icon="edit" stylingMode="text" (onClick)="prepareEditPopup()" *ngIf="currentListingArticleId != undefined"></dx-button>
            <dx-button icon="trash" stylingMode="text" (onClick)="prepareDeletePopup()" *ngIf="currentListingArticleId != undefined"></dx-button>
        </div>
    </div> 
    <dx-data-grid #dataGrid class="grid theme-dependent" style="height: calc(100% - 120px)" width="100%"
        [dataSource]="listingArticleDetails"
        [selection]="{ mode: 'multiple' }"
        (onRowInserting)="onRowInserting($event)"
        (onRowRemoving)="onRowRemoving($event.key)"
        (onSelectionChanged)="onSelectionChanged($event)"
        [showBorders]="true">
        <dxo-toolbar>
            <dxi-item *ngIf="hasSelectedRows">
                <div *dxTemplate>
                    <dx-button type="danger" text="Supprimer la sélection" (onClick)="deleteSelectedRows()" stylingMode="contained"></dx-button>
                </div>
            </dxi-item>
            <dxi-item name="addRowButton" *ngIf="currentListingArticleId != undefined"></dxi-item>            
            <dxi-item>
                <div *dxTemplate>
                    <dx-button icon="refresh" hint="Rafraîchir" (onClick)="refreshDataGrid()" stylingMode="text"></dx-button>
                </div>
            </dxi-item>
        </dxo-toolbar>
        <dxo-scrolling mode="infinite"></dxo-scrolling>
        <dxo-editing mode="form" [allowAdding]="true" [texts]="{ saveRowChanges: 'Ajouter', cancelRowChanges: 'Terminer' }">
            <dxo-form>        
                <dxi-item
                    dataField="articleId"
                    [label]="{text: 'Article'}"
                    [colSpan]="2"
                    editorType="dxSelectBox"
                    [editorOptions]="{
                    dataSource: articleDataSource,
                    searchEnabled: true,
                    displayExpr: articleDisplayExpr,
                    valueExpr: 'id',
                    showClearButton: true,
                    searchExpr: ['libelleLong', 'codeBo'],
                    showDataBeforeSearch: true
                    }"
                ></dxi-item>
            </dxo-form>
        </dxo-editing>
        <dxi-column
            [visible]="false"
            dataField="articleId"
            caption="Article ID"
        >
        </dxi-column>
        <dxi-column
            dataField="articleCodeBo"
            caption="Code BO"
            [width]="200"
        >
        </dxi-column>
        <dxi-column
            dataField="articleName"
            caption="Libellé Article"
        >
        </dxi-column>
    </dx-data-grid>
</div>

<dx-popup
    [showCloseButton]="true"
    title="Suppression"
    [(visible)]="showDeletePopup"
    [width]="300"
    [height]="180">
    <div>
        <p>Êtes vous sur de supprimer le Listing ?</p>
        <div class="popup-buttons">
            <dx-button text="Oui" type="default" (onClick)="removeListingArticle()"></dx-button>
            <dx-button text="Non" (onClick)="showDeletePopup = false"></dx-button>
        </div>
    </div>
</dx-popup>

<dx-popup
    [showCloseButton]="true"
    title="Ajout d'un Listing Article"
    [(visible)]="showAddPopup"
    [width]="600"
    [height]="270">
    <div>
        <dx-form
            [formData]="listingArticleDto">
            <dxi-item dataField="libelle" [label]="{ text: 'Libelle' }"></dxi-item>
            <dxi-item dataField="codeBo" [label]="{ text: 'CodeBo' }"></dxi-item>
        </dx-form>
        <div class="popup-buttons">
            <dx-button text="Sauvegarder" type="default" (onClick)="addListingArticle()"></dx-button>
            <dx-button text="Annuler" (onClick)="showAddPopup = false"></dx-button>
        </div>
    </div>
</dx-popup>

<dx-popup
    [showCloseButton]="true"
    title="Modification"
    [(visible)]="showEditPopup"
    [width]="600"
    [height]="270">
    <div>
        <dx-form
            [formData]="listingArticleDto">
            <dxi-item dataField="libelle" [label]="{ text: 'Libelle' }"></dxi-item>
            <dxi-item dataField="codeBo" [label]="{ text: 'CodeBo' }"></dxi-item>
        </dx-form>

        <div class="popup-buttons">
            <dx-button text="Modifier" type="default" (onClick)="editListingArticle()"></dx-button>
            <dx-button text="Annuler" (onClick)="showEditPopup = false"></dx-button>
        </div>
    </div>
</dx-popup>

<dx-popover target="#listing-legend" [width]="300" [showTitle]="true" title="Raccourci :"
  [(visible)]="showLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    <kbd>inser</kbd> = Ajout Article<br><br>
  </div>
</dx-popover>