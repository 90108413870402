import { Injectable } from '@angular/core';
import { PartenaireListService } from '../../services/partenaire.service';
import DataSource from 'devextreme/data/data_source';
import { CustomStoreService } from '../../services/custom-store.service';
import { IGridService } from '../../models/igrid-service';
import { ArticleDto, LieuStockageDto, PartenaireDto, PartenaireType } from '@lexi-clients/lexi';
import CustomStore from 'devextreme/data/custom_store';
import { MouvementMarchandiseListService } from '../../services/mouvement-marchandise.service';
import { LieuStockageListService } from '../../services/lieu-stockage.service';
import { ArticleListService } from '../../services/article.service';

@Injectable()
export class DxDataSourceService {
  constructor(
    private readonly partenaireService: PartenaireListService,
    private readonly lieuStockageService: LieuStockageListService,
    private readonly mouvementMarchandiseListService: MouvementMarchandiseListService,
    private readonly articleService: ArticleListService,
  ) {}

  //#region Méthodes génériques
  /* Le nom du paramètre doit correspondre au nom du paramètre côté serveur */
  private getAdditionalParams(params: Record<string, any>): Map<string, any> {
    const additionalParams = new Map<string, any>();
    for (const key in params) {
      if (params[key] != null) additionalParams.set(key, params[key]);
    }
    return additionalParams;
  }

  getDataSourceForSelectBox(
    gridService: IGridService<any>,
    additionalParams: Map<string, string> = null,
    key: string = 'id',
    sortSelector: string = null
  ): DataSource {
    const paramDataSource: any = {
      key,
      paginate: true,
      pageSize: 10,
      store: new CustomStoreService(gridService).getSelectBoxCustomStore(additionalParams)
    };

    // Ordonne les résultats via sortSelector (ex: intitule, libelle, etc...)
    if (sortSelector) {
      paramDataSource.sort = [
        { selector: sortSelector, desc: false }
      ];
    }

    return new DataSource(paramDataSource);
  }

  getCustomStore(gridService: IGridService<any>, additionalParams: Map<string, string> = null, customFilters: string[] = [], manipulateDataOnLoad?: (data: any) => void, customStoreOptionsToOverride = {}): CustomStore {
    return new CustomStoreService(gridService).getCustomStore(additionalParams, customFilters, customStoreOptionsToOverride, manipulateDataOnLoad)
  }

  getTreeListCustomStore(gridService: IGridService<any>, additionalParams: Map<string, string> = null) {
    return new CustomStoreService(gridService).getTreeListCustomStore(additionalParams);
  }
  //#endregion

  //#region Partenaires
  getPartenaireDataSourceForSelectBox(estClient?: boolean, estFournisseur?: boolean, partenaireType?: PartenaireType, avecPieceASolder?: boolean): DataSource {
    return this.getDataSourceForSelectBox(
      this.partenaireService,
      this.getAdditionalParams({estClient, estFournisseur, partenaireType, avecPieceASolder})
    );
  }

  getPartenaireCustomStore(estClient?: boolean, estFournisseur?: boolean, partenaireType?: PartenaireType, pattern: string = null, manipulateDataOnLoad?: (data: any) => void): CustomStore {
    return new CustomStoreService(this.partenaireService).getCustomStore(
      this.getAdditionalParams({estClient, estFournisseur, partenaireType, pattern}),
      null,
      null,
      manipulateDataOnLoad
    );
  }

  async getPartenaireById(id: number): Promise<PartenaireDto> {
    const partenaireDataSource = this.getPartenaireDataSourceForSelectBox();
    partenaireDataSource.filter(["id", "=", id]);
    const partenaire: PartenaireDto = (await partenaireDataSource.load())?.[0];
    return partenaire;
  }

  async getPartenaireByCodeBo(codeBo: string): Promise<PartenaireDto> {
    const partenaireDataSource = this.getPartenaireDataSourceForSelectBox();
    partenaireDataSource.filter(["codeBo", "=", codeBo]);
    const partenaire: PartenaireDto = (await partenaireDataSource.load())?.[0];
    return partenaire;
  }
  //#endregion

  //#region Lieux de stockage
  getLieuStockageDataSourceForSelectBox(siteId?: number, currentSiteOnly?: boolean, currentSitePartenaireOnly?: boolean, partenaireId?: number) {
    return this.getDataSourceForSelectBox(
      this.lieuStockageService,
      this.getAdditionalParams({siteId, currentSiteOnly, currentSitePartenaireOnly, partenaireId})
    );
  }

  async getLieuStockageByCodeBo(codeBo: string): Promise<LieuStockageDto> {
    const lieuStockageDataSource = this.getLieuStockageDataSourceForSelectBox();
    lieuStockageDataSource.filter(["codeBo", "=", codeBo]);
    const lieuStockage: LieuStockageDto = (await lieuStockageDataSource.load())?.[0];
    return lieuStockage;
  }
  //#endregion

  //#region Mouvements
  getMouvementsCustomStore(partenaireId?: number, articleId?: number, bonId?: number, documentLogistiqueCodeBo?: string, noPiece?: string, manipulateDataOnLoad?: (data: any) => void): CustomStore {
    return new CustomStoreService(this.mouvementMarchandiseListService).getCustomStore(
      this.getAdditionalParams({partenaireId, articleId, bonId, documentLogistiqueCodeBo, noPiece}),
      null,
      null,
      manipulateDataOnLoad
    );
  }
  //#endregion

  //#region Articles
  async getArticleById(id: number, avecVariante: boolean): Promise<ArticleDto> {
    const additionalParams = new Map();
    if (avecVariante) additionalParams.set("avecVariante", true);
    const articleDataSource = this.getDataSourceForSelectBox(this.articleService, additionalParams);
    articleDataSource.filter(["id", "=", id]);
    const article: ArticleDto = (await articleDataSource.load())?.[0];
    return article;
  }
  //#endregion
}