import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ListingArticlesLexiClient } from '@lexi-clients/lexi';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { ArticleListService } from 'lexi-angular/src/app/services/article.service';
import { ArticleForUpdateDto } from '@lexi-clients/lexi';
import { UpdateListingArticleDto } from '@lexi-clients/lexi';
import { CreateListingArticleDto } from '@lexi-clients/lexi';
import { lastValueFrom } from 'rxjs';
import notify from 'devextreme/ui/notify';
import { NotificationType } from '../../modules/shared/references/references';



@Component({
  selector: 'app-listing-articles',
  templateUrl: './listing-articles.component.html',
  styleUrls: ['./listing-articles.component.scss']
})
export class ListingArticlesComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  listingArticles: any = [];
  listingArticleDto: UpdateListingArticleDto;
  createListingArticleDto: CreateListingArticleDto;
  listingArticleDetails: any = [];
  currentListingArticleId: number | null;
  articleDataSource: DataSource;
  showDeletePopup: boolean = false;
  showAddPopup: boolean = false;
  showEditPopup: boolean = false;
  hasSelectedRows: boolean = false;
  showLegende = false;
  listingArticleIdsToDelete : Array<number> = [];
  

  constructor(
    private listingArticlesService: ListingArticlesLexiClient,
    private readonly articleService: ArticleListService,
    private readonly dxDataSourceService: DxDataSourceService
) { }

  ngOnInit(): void {
    this.getListingArticle();
    this.setDataSource();
  }

  setDataSource() {
    const additionalParams = new Map();
    this.articleDataSource = this.dxDataSourceService.getDataSourceForSelectBox(this.articleService, additionalParams);
    }

  articleDisplayExpr(article: ArticleForUpdateDto) {
    return article && `${article.codeBo} - ${article.libelleLong}`;
  }

  typesDisplayExpr(data) {
    return data && `${data.codeBo} : ${data.libelle}`;
  }

  async onListingChanged(listingId?: number) {
    this.currentListingArticleId = listingId ?? 0;
    if (listingId === null || listingId === undefined) {
      this.currentListingArticleId = null;
      this.listingArticleDetails = [];
    } else {     
      this.dataGrid.instance.cancelEditData(); 
      this.refreshDataGrid();
    }
  }

  async refreshDataGrid() {
    if (this.dataGrid == null || this.currentListingArticleId == null) return;
    const listing = await lastValueFrom(this.listingArticlesService.getById(this.currentListingArticleId));
    this.listingArticleDetails = listing?.listingArticleDetails ?? [];
  }

  async getListingArticle() {

    this.listingArticles = await lastValueFrom(
        this.listingArticlesService.getAll()
    );

    this.listingArticles.sort((a, b) => a.codeBo.localeCompare(b.codeBo));

  }

  async deleteSelectedRows() {
    this.dataGrid.instance.cancelEditData();
    const selectedRowKeys = this.dataGrid.instance.getSelectedRowKeys();
    if (selectedRowKeys.length === 0) {
      return;
    }
    this.listingArticleIdsToDelete = selectedRowKeys.map(key => key.id);
    if (this.listingArticleIdsToDelete.length === 0) {
      console.error("No IDs to delete.");
      return;
    }
    console.log(this.listingArticleIdsToDelete)
    await lastValueFrom(this.listingArticlesService.deleteMultipleListingArticlesDetails(this.listingArticleIdsToDelete));
    await this.refreshDataGrid();
    setTimeout(() => this.dataGrid.instance.addRow(), 100);
  }

  async onRowInserting(e) {
    try {
      var articleId = e?.data?.articleId;
      if (articleId == null) {
        notify({closeOnClick: true, message: "Selectionnez un article à ajouter au listing"}, NotificationType.Error);
        return;
      }
      await lastValueFrom(this.listingArticlesService.createListingDetail(this.currentListingArticleId ?? 0, articleId));
    }
    finally {
      await this.refreshDataGrid();
      setTimeout(() => this.dataGrid.instance.addRow(), 100);
    }
  }

  async onRowRemoving(e) {
    var id = e.id;
    await lastValueFrom(
      this.listingArticlesService.deleteListingArticleDetail(id)
    );
    this.refreshDataGrid();
  }

  async editListingArticle(){

    await lastValueFrom(
      this.listingArticlesService.update(this.listingArticleDto)
    );
    this.getListingArticle();
    this.showEditPopup = false;
  }

  async removeListingArticle(){
    if (this.currentListingArticleId != undefined) {
      await lastValueFrom(
        this.listingArticlesService.deleteListingArticle(this.currentListingArticleId)
      );
      this.getListingArticle();
      this.currentListingArticleId = null;
      this.listingArticleDetails = [];
      this.showDeletePopup = false;
    }
  }

  async addListingArticle(){
    this.createListingArticleDto = {
      libelle: this.listingArticleDto.libelle,
      codeBo: this.listingArticleDto.codeBo
    }
    await lastValueFrom(
      this.listingArticlesService.create(this.createListingArticleDto)
    );
    this.getListingArticle();
    this.showAddPopup = false;
  }

  prepareAddPopup() {
    this.listingArticleDto = {
      id: 0,
      libelle: "",
      codeBo: ""
    }
    this.showAddPopup = true;
  }
  prepareDeletePopup() {
    this.showDeletePopup = true;
  }
  prepareEditPopup() {
    if (this.currentListingArticleId != undefined) {
      const articleForUpdate = this.listingArticles.find(article => article.id === this.currentListingArticleId);
      if (articleForUpdate) {
        this.listingArticleDto = {
          id: articleForUpdate.id,
          libelle: articleForUpdate.libelle,
          codeBo: articleForUpdate.codeBo
        }
      }
    }
    this.showEditPopup = true;
  }

  onSelectionChanged(e: any) {
    this.hasSelectedRows = e.selectedRowKeys.length > 0;
  }

  @HostListener('document:keydown.insert', ['$event'])
  handleInsertKey(event: KeyboardEvent) {
    event.preventDefault();

    if(this.currentListingArticleId != null && this.currentListingArticleId != 0) {
    this.dataGrid.instance.addRow();
    }
  }
}