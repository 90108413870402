<!-- #region Header -->
<div class="view-wrapper">
  <div>
  <div style="display: flex; align-items: center; justify-content: space-between; padding: 18px 12px 0 12px;">
    <!-- #region Filtres statut | avec détails -->
    <div style="display: flex; align-items: center;">
      <dx-button-group [items]="btnStatuts" selectionMode="single" keyExpr="clef" stylingMode="contained"
        [selectedItemKeys]="[fluxStatutSimplifieEnum.ouvert]"
        (onItemClick)="onStatutSelected($event.itemData.clef)"></dx-button-group>

      <div style="display: flex; align-items: center; margin-left: 12px;">
        <dx-switch [(value)]="includeInfosCalculees" (onValueChanged)="storeIncludeInfosCalculeesValue()"></dx-switch>
        <span style="margin-left: 6px;">Avec détails</span>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region Lieu de stockage de référence -->
    <div style="display: flex; align-items: center;">
      <span style="margin-right: 12px;">Lieu de stockage de référence :</span>
      <dx-select-box #lieuStockageSelectBox [items]="lieuStockages" [width]="270" [showClearButton]="true"
        displayExpr="intitule" valueExpr="id" [value]="this.selectedLieuStockage?.id" [searchEnabled]="true"
        [searchExpr]="['intitule', 'codeBo']" (onValueChanged)="onValueChanged($event)"
        style="margin-right: 12px;"></dx-select-box>
      <dx-button text="Rafraîchir" (onClick)="refreshDataSource()"></dx-button>
    </div>
    <!-- #endregion -->

    <!-- #region Btn créer et import -->
    <div style="display: flex; align-items: center;" [title]="messageCannotCreerBon">
      <dx-drop-down-button text="Créer un bon" icon="add" [items]="mouvementTypeDataSource" displayExpr="intitule"
        keyExpr="id" style="margin-right: 12px;" type="default" stylingMode="contained" [width]="220"
        (onItemClick)="onCreateNewBon($event)" [dropDownOptions]="dropDownOptionsCreerBon"
        [disabled]="!isCreerBonBtnEnabled"></dx-drop-down-button>
      <dx-button icon="upload" (onClick)="showImportPopup = true;" [disabled]="!isCreerBonBtnEnabled"></dx-button>
    </div>
    <!-- #endregion -->
  </div>
  <!-- #endregion -->

  <div style="margin-top: 12px; padding: 0 12px 6px 12px">
    <app-bon-list-globale-datagrid #bonListGlobaleDatagridComponent *ngIf="selectedLieuStockage == null"
      [fluxEtapeDataSource]="fluxEtapeDataSource" [currentPartenaireId]="currentPartenaireId"
      [includeInfosCalculees]="includeInfosCalculees" [offsetTopInPx]="131"></app-bon-list-globale-datagrid>
    <app-bon-list-lieu-stockage-datagrid #bonListLieuStockageDatagridComponent *ngIf="selectedLieuStockage != null"
      [lieuStockage]="selectedLieuStockage" [fluxEtapeDataSource]="fluxEtapeDataSource"
      [includeInfosCalculees]="includeInfosCalculees" [offsetTopInPx]="131"></app-bon-list-lieu-stockage-datagrid>
  </div>
</div>
</div>
<dx-popup title="Importer un fichier pour générer des bons" [(visible)]="showImportPopup"
  (onHiding)="onCloseImportPopup()" [showCloseButton]="true" [resizeEnabled]="true" [width]="450" [height]="370">
  <div style="display: flex; align-items: baseline;">
    Type de fichier :
    <dx-select-box #typeImportBonSelectBox [items]="typeFichierImportBon" [width]="220" [showClearButton]="true"
      displayExpr="label" valueExpr="id" [value]="selectedTypeFichierImportBon" [searchEnabled]="true"
      (onValueChanged)="onTypeFichierImportValueChanged($event)"></dx-select-box>
  </div>

  <dx-file-uploader #fileUploader [multiple]="false" [(value)]="files" name="files[]"
    [allowedFileExtensions]="['.xlsx']" uploadMode="useForm" selectButtonText="Choisir un fichier Excel"
    labelText="ou Glisser/Déposer le fichier" readyToUploadMessage="" class="uploader">
  </dx-file-uploader>

  <div style="display: flex; justify-content: center; margin-top: 12px;">
    <dx-button type="danger" text="Annuler" style="margin: 0 10px 0 20px;" (onClick)="onCloseImportPopup()"></dx-button>
    <dx-drop-down-button text="Générer les bons" icon="upload" [items]="mouvementTypeDataSource" displayExpr="intitule"
      keyExpr="id" style="margin-right: 40px;" [width]="220" (onItemClick)="importBon($event)"
      [dropDownOptions]="dropDownOptionsCreerBon"></dx-drop-down-button>
  </div>

  <div [hidden]="!showLoader" style="display: flex; justify-content: center; align-items: center; margin-top: 15px;">
    <dx-load-indicator [visible]="showLoader" height="50" width="50"></dx-load-indicator>
  </div>
</dx-popup>