import { Component, Input, OnInit } from "@angular/core";
import {
  HistoriqueTarifDto,
  TarifsLexiClient,
} from "@lexi-clients/lexi";
import { ExportingEvent } from "devextreme/ui/data_grid";
import { lastValueFrom } from "rxjs";
import { ExportDataGridService } from "../../shared/services/export-data-grid.service";

@Component({
  selector: "app-tarif-article-historique",
  templateUrl: "./tarif-article-historique.component.html",
  styleUrls: ["./tarif-article-historique.component.scss"],
})
export class TarifArticleHistoriqueComponent implements OnInit {
  @Input() articleId: number;
  @Input() tarifId: number;

  historiqueTarif: HistoriqueTarifDto[] = [];

  constructor(
    private readonly tarifsLexiClient: TarifsLexiClient,
    private readonly exportDataGridService: ExportDataGridService,
  ) {}

  ngOnInit() {
    this.loadHistorique();
  }

  async loadHistorique(): Promise<void> {
    this.historiqueTarif = await lastValueFrom(
      this.tarifsLexiClient.getHistoriqueTarif(this.articleId, this.tarifId),
    );
  }

  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }
}
