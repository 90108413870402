<dx-data-grid
  [dataSource]="fluxHistoriqueList"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  height="100%"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before">
      <div *dxTemplate>
        <span style="font-weight: bold; font-size: 18px;">{{title}}</span>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setFluxHistorique()" stylingMode="text"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>

  <!-- Colonnes -->
  <dxi-column dataField="dateEtape" caption="Date étape" sortOrder="desc" dataType="date" format="dd/MM/yyyy HH:mm" width="155"></dxi-column>
  <dxi-column dataField="dateLimite" caption="Date limite" dataType="date" format="dd/MM/yyyy HH:mm" width="155" [visible]="false"></dxi-column>
  <dxi-column dataField="fluxIntitule" caption="Process"></dxi-column>
  <dxi-column dataField="evenementTypeIntitule" caption="Étape"></dxi-column>
  <dxi-column dataField="evenementTypeNature" caption="Nature" [visible]="false">
    <dxo-lookup [dataSource]="evenementNatureDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="statut" caption="Statut">
    <dxo-lookup [dataSource]="fluxStatutDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="intitule" caption="Intitulé" [visible]="false"></dxi-column>
  <dxi-column dataField="nombreHeures" caption="Heures" [visible]="false"></dxi-column>
  <dxi-column dataField="userEtapeIntitule" caption="Utilisateur"></dxi-column>
  <dxi-column dataField="createdByUserIntitule" caption="Créé par" [visible]="false"></dxi-column>
</dx-data-grid>