<dx-data-grid #dataGrid
  [dataSource]="dossierList"
  [allowColumnResizing]="true"
  [columnAutoWidth]="true"
  [remoteOperations]="{ groupPaging: true }"
  [showBorders]="true"
  [allowColumnReordering]="true"
  class="grid theme-dependent"
  height="100%"
  minHeight="500px"
  (onExporting)="onExporting($event, 'dossiers')"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="groupPanel"></dxi-item>
    <dxi-item name="searchPanel"></dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-search-panel [visible]="false"></dxo-search-panel>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[25, 50, 100]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-remote-operations [sorting]="true" [grouping]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-group-panel [visible]="false"></dxo-group-panel>
  <dxo-state-storing [enabled]="true" appStorage></dxo-state-storing>

  <!-- Colonnes -->
  <dxi-column dataField="statut" caption="Statut" cellTemplate="cellTemplateStatut" [width]="88">
    <div *dxTemplate="let data of 'cellTemplateStatut'" class="align-icon">
      <i *ngIf="data.value === 'new'" class="dx-icon-favorites param" title="Nouveau"></i>
      <i *ngIf="data.value === 'opened'" class="dx-icon-video param" title="En cours"> </i>
      <i *ngIf="data.value === 'closed'" title="Fermé">
        <svg version="1.1" width="20" height="20" title="Fermé" xmlns="http://www.w3.org/2000/svg" class="param closed"
          viewBox="0 0 24 24">
          <path
            d="M18 8h-1v-2c0-2.76-2.24-5-5-5s-5 2.24-5 5v2h-1c-1.1 0-2 0.9-2 2v10c0 1.1 0.9 2 2 2h12c1.1 0 2-0.9 2-2v-10c0-1.1-0.9-2-2-2zM12 17c-1.1 0-2-0.9-2-2s0.9-2 2-2 2 0.9 2 2-0.9 2-2 2zM15.1 8h-6.2v-2c0-1.71 1.39-3.1 3.1-3.1s3.1 1.39 3.1 3.1v2z">
          </path>
        </svg>
      </i>
      <div *ngIf="data.value === 'paused'" class="param" title="En attente">
        <svg version="1.1" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M6 4h4v16H6zm8 0h4v16h-4z"></path>
        </svg>
      </div>
    </div>
    <dxo-lookup [dataSource]="typesStatuts" valueExpr="id" displayExpr="libelle"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="etape" caption="Étape" [allowHeaderFiltering]="true" [allowSearch]="false" [width]="91"></dxi-column>
  <dxi-column dataField="dossierTypeLabel" caption="Type" [allowHeaderFiltering]="true" [allowSearch]="false" [width]="80"></dxi-column>
  <dxi-column dataField="codeBo" caption="Code dossier" [allowHeaderFiltering]="true" [allowGrouping]="false"
    [allowSearch]="true" [width]="106" cellTemplate="dossierTemplate"
  >
    <div *dxTemplate="let data of 'dossierTemplate'">
      <a routerLink="/sav/dossiers/{{data.data.id}}">
        {{data.value}}
      </a>
    </div>
  </dxi-column>
  <dxi-column dataField="description" caption="Description" [allowHeaderFiltering]="false" [allowGrouping]="false" [allowSearch]="true"></dxi-column>
  <dxi-column dataField="responsableIntitule" caption="Responsable" [width]="115" [visible]="true" [allowHeaderFiltering]="true" [allowSearch]="false"></dxi-column>
  <dxi-column dataField="clientIntitule" *ngIf="showClient()" caption="Client" [allowHeaderFiltering]="false" [allowSearch]="false"></dxi-column>
  <dxi-column dataField="dateOuverture" caption="Date d'ouverture" [width]="161" sortOrder="desc" dataType="date"
    format="dd/MM/yyyy" [allowHeaderFiltering]="true" [allowSearch]="false"
  ></dxi-column>
  <dxi-column dataField="encours" caption="À facturer" [width]="109" [allowHeaderFiltering]="false"
    [allowGrouping]="false" [allowSearch]="false"
  >
    <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
  </dxi-column>
  <dxi-column dataField="etapeLimite" caption="Date limite" [width]="142" dataType="date" format="dd/MM/yyyy"
    [allowHeaderFiltering]="true" [allowSearch]="false" [width]="123" [visible]="false"
  ></dxi-column>

  <!-- Colonnes cachées -->
  <dxi-column dataField="clientCodeBo" caption="Code client" [width]="126" [visible]="false"
    [allowHeaderFiltering]="true" [allowSearch]="true"
  ></dxi-column>
  <dxi-column dataField="heurePrevues" caption="Heures prévues" [width]="110" [visible]="false"
    [allowHeaderFiltering]="false" [allowGrouping]="false" [allowSearch]="false"
  ></dxi-column>
  <dxi-column dataField="heureReelles" caption="Heures réelles" [width]="110" [visible]="false"
    [allowHeaderFiltering]="false" [allowGrouping]="false" [allowSearch]="false"
  ></dxi-column>
  <dxi-column dataField="tauxProduction" caption="Production (%)" [width]="110" [visible]="false"
    [allowHeaderFiltering]="false" [allowGrouping]="false" [allowSearch]="false"
  ></dxi-column>
  <dxi-column dataField="renouvellable" caption="Renouvellable" [width]="140" [visible]="false"
    [allowHeaderFiltering]="true" [allowSearch]="false"
  ></dxi-column>
  <dxi-column dataField="nombreRenouvellement" caption="Nombre de renouvellement" [width]="167" [visible]="false"
    [allowHeaderFiltering]="false" [allowGrouping]="false" [allowSearch]="false"
  ></dxi-column>
  <dxi-column dataField="renouvellementAutomatique" caption="Renouvellement auto." [width]="179" [visible]="false"
    [allowHeaderFiltering]="true" [allowSearch]="false"
  ></dxi-column>
  <dxi-column dataField="maintenance" caption="Maintenance" [width]="126" [visible]="false"
    [allowHeaderFiltering]="true" [allowSearch]="false"
  ></dxi-column>
  <dxi-column dataField="nombreMoisValidite" caption="Nombre mois validité" [width]="158" [visible]="false"
    [allowHeaderFiltering]="true" [allowSearch]="false"
  ></dxi-column>
  <dxi-column caption="Documents" cellTemplate="cellTemplate" [visible]="false">
    <div *dxTemplate="let data of 'cellTemplate'">
      <a routerLink="/document" [queryParams]="{id: data.data.id, objectType: 'dossier'}">
        Documents
      </a>
    </div>
  </dxi-column>

  <!-- Summary pour afficher l'encours total et le nombre d'enregistrements... -->

  <!-- ...sur les groupes -->

  <dxo-summary>
    <!-- ...sur le bas du tableau, total -->

    <dxi-total-item column="encours" summaryType="sum"  displayFormat="{0} XPF" title="Encours total: {0} XPF">
      <dxo-value-format type="fixedPoint"></dxo-value-format>
    </dxi-total-item>
  </dxo-summary>
</dx-data-grid>