<!-- Filtres -->
<div *ngIf="autoriserFiltreGlobal" style="display: flex; align-items: center; margin-bottom: 12px; flex-wrap: wrap;">
  <!-- Filtre par ClassificationType -->
  <dx-select-box [dataSource]="classificationTypes"
    [width]="280"
    [displayExpr]="classificationTypeDisplayExpr"
    valueExpr="id"
    [showClearButton]="true"
    (onValueChanged)="onClassificationTypeChanged()"
    [(value)]="selectedClassificationTypeId"
    style="margin-left: 12px;"
    label="Type de classification"
    labelMode="static"
  ></dx-select-box>

  <!-- Filtre par type de prix -->
  <dx-select-box [dataSource]="prixCustomStore"
    displayExpr="libelleLong"
    valueExpr="id"
    [showClearButton]="true"
    (onValueChanged)="onPrixChanged()"
    [(value)]="selectedPrixId"
    style="margin-left: 12px;"
    label="Type de prix"
    labelMode="static"
  ></dx-select-box>

  <!-- Recherche global -->
  <dx-text-box
    #textBox
    [(value)]="rechercheGlobale"
    (onEnterKey)="setArticleDataSource()"
    style="margin-left: 12px;"
    label="Recherche globale"
    labelMode="static"
  ></dx-text-box>

  <div style="display: flex; align-items: center; margin-left: 12px;">
    <dx-switch [(value)]="inclureVariante" (onValueChanged)="setArticleDataSource()"></dx-switch>
    <span style="margin-left: 6px;"> Afficher les variantes</span>
  </div>

  <!-- Bouton Rechercher -->
  <dx-button icon="find" text="Rechercher" (onClick)="setArticleDataSource()" style="margin-left: 12px;"></dx-button>

  <dx-button *ngIf="showBoutonArticleStock" icon="add" text="Articles en stock" (onClick)="setArticleEnStock()" style="margin-left: 12px;"></dx-button>
</div>

<dx-data-grid
  #dataGrid
  id="article-list-datagrid-main-content"
  [dataSource]="articleDataSource"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [filterValue]="filterValue"
  (onSelectionChanged)="onSelectionChanged($event)"
  (onExporting)="onExporting($event, 'articles')"
  [style]="{ height: '90%' }"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item name="groupPanel"></dxi-item>
    <dxi-item location="center" *ngIf="enableRowDragging">
      <div *dxTemplate style="display: flex; align-items: center;">
        <dx-switch #sansClassificationSwitchRef
          [(value)]="sansClassificationValue"
          (onValueChanged)="onSansClassificationChanged($event)"
          [elementAttr]="{class: 'mr-12'}"
        ></dx-switch>
        <span>Sans classification uniquement</span>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        {{ getTexteLignesSelectionees() }}
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="deletetable" hint="Réinitialiser les filtres" (onClick)="clearDataGridFilters()" stylingMode="text"></dx-button>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setArticleDataSource()" stylingMode="text"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-state-storing [enabled]="true" [storageKey]="grilleStorageKey" appStorage></dxo-state-storing>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-scrolling showScrollbar="always" mode="virtual"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-selection [mode]="typeSelection"></dxo-selection>
  <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true" [grouping]="true" [groupPaging]="true"></dxo-remote-operations>
  <dxo-row-dragging *ngIf="enableRowDragging" [allowReordering]="true" [showDragIcons]="true" group="articleList"></dxo-row-dragging>

  <!-- Colonnes -->
  <dxi-column dataField="codeBo" caption="Code" [allowHeaderFiltering]="false" [allowGrouping]="false" cellTemplate="cellTemplate" [width]="120">
    <div *dxTemplate="let data of 'cellTemplate'">
      <a routerLink="/article/{{data.data.id}}">
        {{data.value}}
      </a>
    </div>
  </dxi-column>
  <dxi-column dataField="libelleLong" caption="Libellé" [allowHeaderFiltering]="false" [allowGrouping]="false"></dxi-column>
  <dxi-column dataField="type" caption="Type" [width]="104" [visible]="false">
    <dxo-lookup [dataSource]="articleTypeDataSource" valueExpr="id" displayExpr="intitule"></dxo-lookup>
  </dxi-column>

  <dxi-column dataField="stockTheoriqueSource" caption="Stock (source)" [visible]="false"></dxi-column>
  <dxi-column dataField="stockTheoriqueDestination" caption="Stock (destination)" [visible]="showBoutonArticleStock"></dxi-column>

  <dxi-column dataField="stockSociete" caption="Société" cellTemplate="cellStockTemplate" [width]="110" [allowHeaderFiltering]="false" [allowFiltering]="false" *ngIf="showStockSociete && sourceStockageId == null" [showInColumnChooser]="showStockSociete" format="fixedPoint">
    <div *dxTemplate="let cellStock of 'cellStockTemplate'">
      <dx-button style="margin: 0px; padding: 0px;" type="edit" [text]="cellStock.data.stockSociete" (onClick)="showStockParSite(cellStock.data)"></dx-button>
    </div>
  </dxi-column>
  <dxi-column dataField="stockSite" caption="{{currentSiteIntitule | internationalisation}}" [width]="100" [allowHeaderFiltering]="false" [allowFiltering]="false" *ngIf="showStockSite && sourceStockageId == null" [showInColumnChooser]="showStockSite" format="fixedPoint"></dxi-column>
  <dxi-column caption="Stock par site" cellTemplate="stockTemplate" [width]="105" alignment="center" [visible]="false">
    <div *dxTemplate="let cellInfo of 'stockTemplate'">
      <dx-button icon="product" style="margin: 0px; padding: 0px; width: 32px; height: 32px;" type="edit" (onClick)="showStockParSite(cellInfo.data)"></dx-button>
    </div>
  </dxi-column>
  <dxi-column dataField="variante1Intitule" caption="Variante 1" [visible]="inclureVariante" [allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false" [width]="100"></dxi-column>
  <dxi-column dataField="variante2Intitule" caption="Variante 2" [visible]="inclureVariante" [allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false" [width]="100"></dxi-column>
  <dxi-column
    caption="Disp. Source"
    [width]="135"
    [calculateCellValue]="calculateStockDisponibleSource"
    format="fixedPoint"
    *ngIf="showStockSociete && sourceStockageId != null"
    headerCellTemplate="dispoSourceHeaderTemplate">
      <div *dxTemplate="let data of 'dispoSourceHeaderTemplate'">
        <span style="margin-right: 2px;">Disp. Source</span>
        <i id="stockSourceLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
        (mouseenter)="showStockSourceLegende = true" (mouseleave)="showStockSourceLegende = false"></i>
      </div>
  </dxi-column>

  <dxi-column
    caption="Disp. Destination"
    [width]="161"
    [calculateCellValue]="calculateStockDisponibleDestination"
    format="fixedPoint"
    *ngIf="showStockSociete && destinationStockageId != null"
    headerCellTemplate="dispoDestinationHeaderTemplate">
      <div *dxTemplate="let data of 'dispoDestinationHeaderTemplate'">
        <span style="margin-right: 2px;">Disp. Destination</span>
        <i id="stockDestinationLegendeIcon" style="cursor: pointer;" class="dx-icon-info"
        (mouseenter)="showStockDestinationLegende = true" (mouseleave)="showStockDestinationLegende = false"></i>
      </div>
  </dxi-column>

  <dxi-column dataField="tarifValeurAppliquee" caption="Prix" [width]="105" [allowHeaderFiltering]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="uniteBaseIntitule" caption="Unité" [width]="121"> </dxi-column>
  <dxi-column dataField="classificationValueNiveauUnLibelle" caption="Classification Niv. 1" [width]="182"></dxi-column>
  <dxi-column dataField="classificationValueNiveauDeuxLibelle" caption="Classification Niv. 2" [width]="182"> </dxi-column>

  <!-- Colonnes cachées -->
  <dxi-column dataField="classificationValueNiveauTroisLibelle" caption="Classification Niv. 3" [width]="182" [visible]="false"> </dxi-column>
  <dxi-column dataField="fournisseurPrincipalIntitule" caption="Fournisseur principal" [visible]="false"></dxi-column>
  <dxi-column dataField="taxeGroupeIntitule" caption="Groupe de taxe" [visible]="false"> </dxi-column>
  <dxi-column dataField="bloque" caption="Bloqué" [width]="98" [visible]="false"></dxi-column>

  <dxi-column dataField="quantiteReserveeSource" caption="Réservée (source)" [visible]="false" [allowHeaderFiltering]="false" [allowFiltering]="false"></dxi-column>
  <dxi-column dataField="quantiteReserveeDestination" caption="Réservée (destination)" [visible]="false" [allowHeaderFiltering]="false" [allowFiltering]="false"></dxi-column>

</dx-data-grid>

<!-- Boutons Annuler / Confirmer -->
<div id="article-list-datagrid-footer" *ngIf="typeSelection != 'none' && typeSelection != null && !enableRowDragging && !isInPopup">
  <dx-button type="danger" text="Annuler" (onClick)="onCancel()" style="margin-right: 6px;"></dx-button>
  <dx-button type="success" text="Confirmer la sélection" (onClick)="onConfirm()"></dx-button>
</div>

<!-- Popover: Légende Stock Source -->
<dx-popover target="#stockSourceLegendeIcon" [width]="300" [(visible)]="showStockSourceLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    Stock disponible sur la source (théorique - réservé)
  </div>
</dx-popover>

<!-- Popover: Légende Stock Destination -->
<dx-popover target="#stockDestinationLegendeIcon" [width]="300" [(visible)]="showStockDestinationLegende">
  <div *dxTemplate="let data = model; of: 'content'">
    Stock disponible sur la destination (théorique - réservé)
  </div>
</dx-popover>

<!-- Popup: stock par site -->
<dx-popup
  [title]="'Stock par site - ' + selectedArticle?.libelleLong"
  [hideOnOutsideClick]="true"
  [(visible)]="showPopupStockSite"
  (onHiding)="hideStockParSitePopup()"
  [showCloseButton]="true"
>
  <app-lot-stockage-datagrid *ngIf="showPopupStockSite"
    [currentSiteId]="currentSiteId"
    [articleId]="selectedArticle?.id"
    [dataGridMarginTop]="'calc(100vh - 200px)'"
    [prixId]="selectedPrixId"
    [canAfficherPMP]="isAffichagePMPAutorise"
  ></app-lot-stockage-datagrid>
</dx-popup>
