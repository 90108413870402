<dx-scroll-view class="view-wrapper-scroll">
  <div class="view-wrapper">
    <dx-toolbar class="toolbar-details theme-dependent">
      <dxi-item location="before">
        <dx-button backButton [fallbackRoute]="'/partenaires'" stylingMode="text" icon="back"></dx-button>
      </dxi-item>
      <dxi-item location="before"
        text="{{!isCreation ? partenaire?.codeBo : 'Création'}} - {{!isCreation ? partenaire?.intitule : this.partenaireCategorieIntitule}}">
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-drop-down-button
            [dataSource]="viewList"
            [(selectedItemKey)]="selectedViewId"
            [useSelectMode]="true"
            [dropDownOptions]="{ width: 'auto' }"
            (onItemClick)="onViewClick($event)"
            stylingMode="text"
            keyExpr="id" displayExpr="intitule"
            label="Vue" labelMode="static"
          ></dx-drop-down-button>
        </div>
      </dxi-item>
      <dxi-item location="after" locateInMenu="auto">
        <div class="separator"></div>
      </dxi-item>
      <dxi-item location="after" locateInMenu="auto" widget="dxButton" showText="inMenu"
        [options]="{
          text: 'Copier',
          icon: 'copy',
          hint: 'Dupliquer fiche',
          onClick: onCopyClick,
          stylingMode: 'text'
        }"
      ></dxi-item>
      <dxi-item location="after" locateInMenu="auto">
        <div class="separator"></div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <app-objecttype-edition-possible-list [objectType]="objectType.partenaire" [objectId]="partenaire?.id" [isDropDownButton]="true" ></app-objecttype-edition-possible-list>
        </div>
      </dxi-item>
      <dxi-item location="after" *ngIf="!isCreation">
        <div *dxTemplate>
          <dx-drop-down-button [items]="actions" keyExpr="id" displayExpr="intitule"
            (onItemClick)="onActionClick($event)" [disabled]="isEditing" [dropDownOptions]="{ width: 130 }"
            text="Action" icon="smalliconslayout"
          ></dx-drop-down-button>
        </div>
      </dxi-item>
    </dx-toolbar>

    <div class="panels theme-dependent" style="height: calc(100% - 58px);">
      <div class="left">
        <app-partenaire-form [partenaireData]="partenaire" [(isEditing)]="isEditing" [isLoading]="isLoading"></app-partenaire-form>
      </div>

      <div class="right theme-dependent" style="height: 100%;">
        <dx-tab-panel secondary="secondary" [focusStateEnabled]="false" [showNavButtons]="true" style="height: 100%;">
          <!-- #region Général -->
          <ng-container *ngIf="selectedViewId === ViewType.General">
            <dxi-item title="Général">
              <div *dxTemplate>
                <dx-form *ngIf="partenaire" class="form last plain-styled-form general-padding" [formData]="partenaire"
                  labelMode="floating" [class.view-mode]="!isEditing" [readOnly]="!isEditing" [colCount]="4">
                  <!-- Administratif -->
                  <dxi-item itemType="group" caption="Administratif" [colSpan]="4" colCount="4">
                    <dxi-item dataField="raisonSociale" [label]="{ text: 'Raison Sociale' }" [colSpan]="2"
                      [editorOptions]="{ maxLength: 120 }"></dxi-item>
                    <dxi-item dataField="enseigne" [label]="{ text: 'Enseigne' }" [colSpan]="2"
                      [editorOptions]="{ maxLength: 100 }"></dxi-item>
                    <dxi-item dataField="formeJuridiqueId" [label]="{ text: 'Forme juridique' }"
                      editorType="dxSelectBox" [editorOptions]="{
                          dataSource: formeJuridiques,
                          displayExpr: 'intitule',
                          valueExpr: 'id',
                          showClearButton: true,
                        }"></dxi-item>
                    <dxi-item dataField="identifiantEntreprise" [label]="{ text: 'N° Tahiti' }"
                      [editorOptions]="{ maxLength: 20 }"></dxi-item>
                    <dxi-item dataField="identifiantCommercial" [label]="{ text: 'N° RC' }"
                      [editorOptions]="{ maxLength: 20 }"></dxi-item>
                    <dxi-item dataField="numeroTvaIntracommunautaire" [label]="{ text: 'N° TVA intracommunautaire' }"
                      [editorOptions]="{ maxLength: 20 }"></dxi-item>
                    <dxi-item dataField="representantLegal" [label]="{ text: 'Représentant légal' }"
                      [editorOptions]="{ maxLength: 100 }"></dxi-item>
                    <dxi-item dataField="nom" [label]="{ text: 'Nom' }" [editorOptions]="{ maxLength: 100 }"></dxi-item>
                    <dxi-item dataField="prenom" [label]="{ text: 'Prénom' }"
                      [editorOptions]="{ maxLength: 100 }"></dxi-item>
                    <dxi-item dataField="dateNaissance" [label]="{ text: 'Date de naissance'}" editorType="dxDateBox"
                      [editorOptions]="{displayFormat: 'dd/MM/yyyy', width: '170px', type: 'date'}"></dxi-item>
                    <dxi-item dataField="civilite" [label]="{text: 'Civilité'}" editorType="dxSelectBox"
                      [editorOptions]="{
                          dataSource: civiliteType,
                          searchEnabled: true,
                          displayExpr: 'libelle',
                          valueExpr: 'id',
                          showClearButton: true,
                          searchExpr: ['libelle', 'codeBo'],
                          showDataBeforeSearch: true
                        }"></dxi-item>
                    <dxi-item dataField="adhesionOffresCommerciales" [label]="{visible: false}" editorType="dxCheckBox"
                      [editorOptions]="{text: 'Adhésion aux offres commerciales'}"></dxi-item>
                    <dxi-item dataField="clientAnonyme" [label]="{visible: false}" editorType="dxCheckBox"
                    [editorOptions]="{text: 'Client anonyme'}"></dxi-item>
                  </dxi-item>
                  <!-- Géographique -->
                  <dxi-item itemType="group" caption="Adresse Géographique" [colSpan]="2" [colCount]="2">
                    <dxi-item dataField="paysId" [label]="{text: 'Pays'}" editorType="dxSelectBox" [editorOptions]="{
                      dataSource: paysDataSource,
                      searchEnabled: true,
                      displayExpr: 'intitule',
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['intitule', 'codeDouane', 'codeNumerique'],
                      showDataBeforeSearch: true
                    }"></dxi-item>
                    <dxi-item dataField="secteurGeographiqueId" [label]="{text: 'Secteur géographique'}"
                      editorType="dxSelectBox" [editorOptions]="{
                          dataSource: secteurGeographiqueDataSource,
                          searchEnabled: true,
                          displayExpr: 'intitule',
                          valueExpr: 'id',
                          showClearButton: true,
                          searchExpr: ['intitule', 'codeBo'],
                          showDataBeforeSearch: true
                        }"></dxi-item>

                    <dxi-item dataField="commune" [label]="{ text: 'Commune' }" [editorOptions]="{ maxLength: 120 }">
                      <dxi-validation-rule type="stringLength" [max]="120"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="ileId" [label]="{text: 'Île'}" editorType="dxSelectBox" [editorOptions]="{
                      dataSource: ileDataSource,
                      searchEnabled: true,
                      displayExpr: 'libelle',
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['libelle'],
                      showDataBeforeSearch: true
                    }"></dxi-item>
                    <dxi-item dataField="adresseGeographique1" [colSpan]="2" [label]="{ text: 'Adresse géographique' }"
                      [editorOptions]="{ maxLength: 80 }">
                      <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="adresseGeographique2" [colSpan]="2"
                      [label]="{ text: 'Adresse géographique 2' }" [editorOptions]="{ maxLength: 80 }">
                      <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="adresseGeographique3" [colSpan]="2"
                      [label]="{ text: 'Adresse géographique 3' }" [editorOptions]="{ maxLength: 80 }">
                      <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="informationsLivraison" editorType="dxTextArea"
                      [editorOptions]="{height: 125, maxLength: 255}" [label]="{text: 'Informations livraison'}"
                      [colCount]="2"></dxi-item>
                  </dxi-item>
                  <!-- Postale -->
                  <dxi-item itemType="group" caption="Adresse Postale" [colSpan]="2" [colCount]="2">
                    <dxi-item dataField="adressePostale1" [colSpan]="2" [label]="{ text: 'Adresse postale' }"
                      [editorOptions]="{ maxLength: 80 }">
                      <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="adressePostale2" [colSpan]="2" [label]="{ text: 'Adresse postale 2' }"
                      [editorOptions]="{ maxLength: 80 }">
                      <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="adressePostale3" [colSpan]="2" [label]="{ text: 'Adresse postale 3' }"
                      [editorOptions]="{ maxLength: 80 }">
                      <dxi-validation-rule type="stringLength" [max]="80"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="codePostal" [label]="{ text: 'Code postal' }"
                      [editorOptions]="{ maxLength: 12 }">
                      <dxi-validation-rule type="stringLength" [max]="12"></dxi-validation-rule>
                    </dxi-item>
                    <dxi-item dataField="boitePostale" [label]="{ text: 'Boîte postale' }"
                      [editorOptions]="{ maxLength: 12 }">
                      <dxi-validation-rule type="stringLength" [max]="12"></dxi-validation-rule>
                    </dxi-item>
                  </dxi-item>
                </dx-form>
              </div>
            </dxi-item>

            <dxi-item title="Profil" *ngIf="caracteristiquesForView && caracteristiquesForView.length > 0">
              <div *dxTemplate style="padding: 12px; height: 100%;">
                <dx-form
                  [formData]="caracteristiquesForView"
                  [readOnly]="!isEditing"
                  [height]="'100%'"
                  [class.view-mode]="!isEditing"
                  class="form last plain-styled-form general-padding"
                  labelMode="floating"
                  style="overflow: auto;"
                >
                  <dxi-item itemType="group" [colCount]="2">
                    <dxi-item *ngFor="let c of caracteristiquesForView" itemType="group">
                      <!-- Valeur : String -->
                      <dxi-item
                        *ngIf="c.caracteristiqueValue.caracteristique.type !== AttributValeurType.boolean
                          && c.caracteristiqueValue.caracteristique.type !== AttributValeurType.date
                          && c.caracteristiqueValue.caracteristique.type !== AttributValeurType.listCustom
                        "
                        [dataField]="c.caracteristiqueValue.caracteristique.valeur"
                        [label]="{ text: c.caracteristiqueValue.caracteristique.intitule}"
                        editorType="dxTextBox"
                        [editorOptions]="{ value : c.caracteristiqueValue.valeur }"
                      ></dxi-item>

                      <!-- Valeur : Choix multiples -->
                      <dxi-item *ngIf="c.valeursPossibles" editorType="dxSelectBox"
                        [label]="{ text: c.caracteristiqueValue.caracteristique.intitule}"
                        [editorOptions]="{
                          dataSource: c.valeursPossibles,
                          displayExpr: 'intitule',
                          valueExpr: 'valeur',
                          value : c.caracteristiqueValue.valeur
                        }"
                      ></dxi-item>

                      <!-- Valeur : Date -->
                      <dxi-item *ngIf="c.caracteristiqueValue.caracteristique.type == AttributValeurType.date"
                        [label]="{ text: c.caracteristiqueValue.caracteristique.intitule}"
                        editorType="dxDateBox"
                        [editorOptions]="{
                          value : c.caracteristiqueValue.valeur
                        }"
                      ></dxi-item>

                      <!-- Valeur : CheckBox -->
                      <dxi-item *ngIf="c.caracteristiqueValue.caracteristique.type == AttributValeurType.boolean"
                        [label]="{ text: c.caracteristiqueValue.caracteristique.intitule}"
                        editorType="dxSwitch"
                        [editorOptions]="{value : c.caracteristiqueValue.valeur}"
                      ></dxi-item>
                    </dxi-item>
                  </dxi-item>
                </dx-form>
              </div>
            </dxi-item>

            <dxi-item title="Comptabilité" [template]="'comptabiliteTabTemplate'"></dxi-item>

            <dxi-item title="Contacts" [disabled]="isCreation" *ngIf="partenaire && partenaire.type != partenaireType.interne">
              <div *dxTemplate>
                <app-contact-list *ngIf="!isCreation" [clientId]="partenaireId" [heightToDeduce]="heightToDeduce"></app-contact-list>
              </div>
            </dxi-item>

            <dxi-item title="Classifications" [disabled]="isCreation" *ngIf="partenaire && partenaire.type != partenaireType.interne">
              <div *dxTemplate>
                <app-classification-datagrid *ngIf="!isCreation" [objectType]="objectType.partenaire"
                  [itemId]="partenaireId" [heightToDeduce]="heightToDeduce"
                  [allowEdit]="true"
                ></app-classification-datagrid>
              </div>
            </dxi-item>

            <dxi-item title="Notes" colCount="4">
              <div *dxTemplate style="padding: 12px;">
                <p>Notes :</p>
                <dx-text-area *ngIf="partenaire"
                  [(value)]="partenaire.notes"
                  [inputAttr]="{ 'aria-label': 'Notes' }"
                  [height]="160"
                  [readOnly]="!isEditing"
                ></dx-text-area>
              </div>
            </dxi-item>

            <dxi-item title="Documents" [disabled]="isCreation">
              <div *dxTemplate style="padding: 12px;">
                <app-document-list [objectId]="partenaireId" [objectType]="objectType.partenaire"></app-document-list>
              </div>
            </dxi-item>

            <dxi-item title="Caractéristiques" [disabled]="isCreation">
              <div *dxTemplate style="padding: 12px;">
                <app-gestion-caracteristiques *ngIf="!isCreation" [objectId]="partenaireId" [objectType]="objectType.partenaire"></app-gestion-caracteristiques>
              </div>
            </dxi-item>
          </ng-container>
          <!-- #endregion -->

          <!-- #region Facturation -->
           <ng-container *ngIf="selectedViewId === ViewType.Facturation">
              <dxi-item title="Factures">
                <div *dxTemplate style="padding: 12px;">
                  <app-facture-list *ngIf="!isCreation" [clientId]="partenaireId"></app-facture-list>
                </div>
              </dxi-item>

              <dxi-item title="Comptabilité" [template]="'comptabiliteTabTemplate'"></dxi-item>

              <dxi-item title="Prix" [disabled]="isCreation">
                <div *dxTemplate style="padding: 12px;">
                  <app-client-prix-list *ngIf="!isCreation" [clientId]="partenaireId"></app-client-prix-list>
                </div>
              </dxi-item>
           </ng-container>
          <!-- #endregion -->

          <!-- #region Logistique  -->
           <ng-container *ngIf="selectedViewId === ViewType.Logistique">
              <dxi-item title="Lieux de stockage" [disabled]="isCreation">
                <div *dxTemplate>
                  <dx-form *ngIf="partenaire"
                    [formData]="partenaire"
                    [colCount]="2"
                    [readOnly]="!isEditing"
                    [class.view-mode]="!isEditing"
                    class="form last plain-styled-form general-padding"
                    labelMode="floating"
                  >
                    <dxi-item dataField="lieuParDefautId" editorType="dxSelectBox"
                      [label]="{ text: 'Lieu de stockage par défaut' }"
                      [editorOptions]="lieuStockageOptions"
                    ></dxi-item>
                  </dx-form>

                  <app-lieu-stockage-tree-list
                    [partenaireId]="partenaireId"
                    [enableActions]="canGererLieuxStockages && isEditing" [currentSiteId]="currentSiteId"
                    [partenaireCodeBo]="partenaire?.codeBo" [heightToDeduce]="heightToDeduce"
                    (onUpdateLieuStockage)="setLieuStockageDataSource()"
                  ></app-lieu-stockage-tree-list>
                </div>
              </dxi-item>

              <dxi-item title="Connaissements" *ngIf="partenaire?.type != partenaireType.interne" [disabled]="isCreation">
                <div *dxTemplate>
                  <app-connaissement-list [clientId]="partenaireId"></app-connaissement-list>
                </div>
              </dxi-item>

              <dxi-item title="Fret local" *ngIf="partenaire?.type != partenaireType.interne">
                <div *dxTemplate>
                  <dx-form *ngIf="partenaire" class="form last plain-styled-form general-padding" [formData]="partenaire"
                    labelMode="floating" [class.view-mode]="!isEditing" [readOnly]="!isEditing"
                  >
                    <dxi-item itemType="group" colCount="3">
                      <dxi-item dataField="estRevendeur" [label]="{ text: 'Est revendeur' }" editorType="dxSwitch"></dxi-item>
                      <dxi-item dataField="transporteurPrefereId" [label]="{text: 'Transporteur préféré'}"
                        editorType="dxSelectBox" [editorOptions]="{
                        dataSource: transporteurDataSource,
                        searchEnabled: true,
                        displayExpr: 'designation',
                        valueExpr: 'id',
                        showClearButton: true,
                        searchExpr: ['designation', 'codeBo', 'codeInternational'],
                        showDataBeforeSearch: true
                        }"
                      ></dxi-item>
                      <dxi-item dataField="lieuDebarquementPrivilegieId" [label]="{text: 'Lieu de débarquement préféré'}"
                        editorType="dxSelectBox" [editorOptions]="{
                        dataSource: lieuDebarquementDataSource,
                        searchEnabled: true,
                        displayExpr: 'intitule',
                        valueExpr: 'id',
                        showClearButton: true,
                        searchExpr: ['intitule'],
                        showDataBeforeSearch: true
                        }"
                      ></dxi-item>
                    </dxi-item>

                    <dxi-item>
                      <div *dxTemplate>
                        <app-partenaire-fret-local-settings-form [fretLocalSettings]="partenaire?.fretLocalSettings"
                          [formIsReadOnly]="!isEditing" [motifRefusDemande]="motifRefusDemande"
                          [typeUtilisateur]="typeUtilisateurRuunui">
                        </app-partenaire-fret-local-settings-form>
                      </div>
                    </dxi-item>
                  </dx-form>
                </div>
              </dxi-item>
           </ng-container>
          <!-- #endregion -->

          <!-- #region Sav -->
          <ng-container *ngIf="selectedViewId === ViewType.Sav">
            <dxi-item title="Parcs">
              <div *dxTemplate style="padding: 12px;">
                <dx-data-grid [dataSource]="parcs"
                  [height]="'100%'"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [columnAutoWidth]="true"
                  [allowColumnResizing]="true"
                >
                  <!-- Options -->
                  <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[25, 50, 100]" [showInfo]="true" [visible]="true"></dxo-pager>

                  <!-- Colonnes -->
                  <dxi-column dataField="numeroSerie" caption="N°" cellTemplate="numeroSerieTemplate" [width]="90">
                    <div *dxTemplate="let data of 'numeroSerieTemplate'">
                      <a routerLink="/sav/parcs/{{data.data.id}}">
                        {{data.value}}
                      </a>
                    </div>
                  </dxi-column>
                  <dxi-column dataField="articleIntitule" caption="Article" cellTemplate="articleTemplate">
                    <div *dxTemplate="let data of 'articleTemplate'">
                      <a routerLink="/article/{{data.data.articleId}}">
                        {{data.data.articleCodeBo}} - {{data.data.articleIntitule}}
                      </a>
                    </div>
                  </dxi-column>
                  <dxi-column dataField="dateMiseEnService" caption="Mise en service" [width]="120" dataType="date" format="dd/MM/yyyy"></dxi-column>
                  <dxi-column caption="Garantie" [width]="250" cellTemplate="garantieTemplate">
                    <div *dxTemplate="let data of 'garantieTemplate'">
                      Du <span *ngIf="data?.data?.debutGarantie">{{ data.data.debutGarantie | date:'dd/MM/yyyy' }}</span>
                      au <span *ngIf="data?.data?.finGarantie">{{ data.data.finGarantie | date:'dd/MM/yyyy' }}</span>
                    </div>
                  </dxi-column>
                </dx-data-grid>
              </div>
            </dxi-item>

            <dxi-item title="Dossiers">
              <div *dxTemplate style="padding: 12px;">
                <app-dossier-list-sub [dossierList]="dossiers" [objectType]="objectType.partenaire"></app-dossier-list-sub>
              </div>
            </dxi-item>

            <dxi-item title="Activités">
              <div *dxTemplate style="padding: 12px; height: 100%;">
                <dx-data-grid [dataSource]="activites"
                  [height]="'100%'"
                  [showBorders]="true"
                  [allowColumnReordering]="true"
                  [columnAutoWidth]="true"
                  [allowColumnResizing]="true"
                >
                  <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
                  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[25, 50, 100]" [showInfo]="true" [visible]="true"></dxo-pager>
                  <dxi-column dataField="dateOperation" caption="Date" width="110" dataType="date" format="dd/MM/yyyy"></dxi-column>
                  <dxi-column dataField="code" caption="Code" width="120" cellTemplate="codeTemplate">
                    <div *dxTemplate="let data of 'codeTemplate'">
                      <a routerLink="/sav/activites/{{data.data.id}}">
                        {{data.value}}
                      </a>
                    </div>
                  </dxi-column>
                  <dxi-column dataField="intervenantIntitule" caption="Intervenant" width="150"></dxi-column>
                  <dxi-column dataField="intitule" caption="Intitulé"></dxi-column>
                </dx-data-grid>
              </div>
            </dxi-item>
          </ng-container>
          <!-- #endregion -->

          <!-- #region Templates -->
          <div *dxTemplate="let data of 'comptabiliteTabTemplate'">
            <dx-form *ngIf="partenaire" class="form last plain-styled-form general-padding" [formData]="partenaire"
              labelMode="floating" [class.view-mode]="!isEditing" [readOnly]="!isEditing" [colCount]="2">
              <dxi-item itemType="group" caption="Informations financières" [colSpan]="2" colCount="2"
                *ngIf="partenaire?.type != partenaireType.interne">
                <dxi-item [label]="{text: 'Encours'}" editorType="dxNumberBox" [editorOptions]="{
                      format: { type: 'fixedPoint', precision: 0 },
                      readOnly: true,
                      value: encoursClient
                    }"></dxi-item>
                <dxi-item dataField="plafondEncours" [label]="{text: 'Plafond encours'}" editorType="dxNumberBox"
                  [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item>
                <!-- <dxi-item dataField="jourPaiement" [label]="{text: 'Jour paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
                <!-- <dxi-item dataField="nombreJourPaiement" [label]="{text: 'Nombre jour paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
                <!-- <dxi-item dataField="debutPeriodePaiement" [label]="{text: 'Début période paiement'}" editorType="dxNumberBox" [editorOptions]="{ format: { type: 'fixedPoint', precision: 0 } }"></dxi-item> -->
                <!-- <dxi-item dataField="habitudePaiement" [label]="{ text: 'Habitude de paiement' }">
                    <dxi-validation-rule type="stringLength" [max]="150"></dxi-validation-rule>
                  </dxi-item> -->
                <dxi-item dataField="partenaireFactureId" [label]="{text: 'Partenaire facturé'}"
                  editorType="dxSelectBox" [editorOptions]="{
                      dataSource: partenaireDataSource,
                      searchEnabled: true,
                      displayExpr: 'intitule',
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['intitule', 'codeBo'],
                      showDataBeforeSearch: true
                    }"></dxi-item>
                <dxi-item dataField="partenaireParentId" [label]="{text: 'Partenaire parent'}"
                  editorType="dxSelectBox" [editorOptions]="{
                      dataSource: partenaireDataSource,
                      searchEnabled: true,
                      displayExpr: 'intitule',
                      valueExpr: 'id',
                      showClearButton: true,
                      searchExpr: ['intitule', 'codeBo'],
                      showDataBeforeSearch: true
                    }"></dxi-item>
                <dxi-item dataField="controlEncours" [label]="{visible: false}" editorType="dxCheckBox"
                  [editorOptions]="{text: 'Contrôle encours'}"></dxi-item>
                <dxi-item dataField="encoursNegatifAutorise" [label]="{visible: false}" editorType="dxCheckBox"
                  [editorOptions]="{text: 'Encours négatif autorisé'}"></dxi-item>
                <dxi-item dataField="soumisTva" [label]="{visible: false}" editorType="dxCheckBox"
                  [editorOptions]="{text: 'Soumis à la TVA'}"></dxi-item>
                <dxi-item dataField="facturationHT" [label]="{visible: false}" editorType="dxCheckBox"
                  [editorOptions]="{text: 'Facturé en HT'}"></dxi-item>
              </dxi-item>
              <!-- Partie Client -->
              <dxi-item itemType="group"
                [caption]="partenaire?.type != partenaireType.interne ? 'Client' : 'Compta'" [colSpan]="1">
                <dxi-item dataField="estClient" [label]="{visible: false}" editorType="dxCheckBox"
                  [editorOptions]="{text: 'Client'}" *ngIf="partenaire?.type != partenaireType.interne"></dxi-item>
                <dxi-item itemType="group"
                  [disabled]="partenaire?.type == partenaireType.externe && !partenaire?.estClient">
                  <dxi-item dataField="codeBoClient" [label]="{ text: 'Code BackOffice client'}"
                    *ngIf="partenaire?.type != partenaireType.interne"></dxi-item>
                  <dxi-item dataField="compteGeneralClient"
                    [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }"
                    caption="Compte collectif client">
                    <dxi-validation-rule type="stringLength" [min]="longueurCompte" [max]="longueurCompte"
                      ignoreEmptyValue="true" message="{{ messageLongueurCompteInvalide }}">
                    </dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="compteAuxiliaireClient" [label]="{ text: 'Compte auxiliaire client' }"
                    [editorOptions]="{ maxLength: 20 }">
                    <dxi-validation-rule type="stringLength" [max]="20"
                      message="Le nom du compte auxiliaire ne peut dépasser 20 caractères."></dxi-validation-rule>
                  </dxi-item>
                </dxi-item>
              </dxi-item>
              <!-- Partie Fournisseur -->
              <dxi-item itemType="group" caption="Fournisseur" [colSpan]="1"
                *ngIf="partenaire?.type != partenaireType.interne">
                <dxi-item dataField="estFournisseur" [label]="{visible: false}" editorType="dxCheckBox"
                  [editorOptions]="{text: 'Fournisseur'}"></dxi-item>
                <dxi-item itemType="group" [disabled]="!partenaire?.estFournisseur">
                  <dxi-item dataField="codeBoFournisseur" [label]="{ text: 'Code BackOffice fournisseur' }"
                    [editorOptions]="{ maxLength: 36 }"></dxi-item>
                  <dxi-item dataField="compteGeneralFournisseur"
                    [editorOptions]="{ maxLength: longueurCompte, format: { type: 'fixedPoint', precision: 0 }  }"
                    caption="Compte collectif fournisseur">
                    <dxi-validation-rule type="stringLength" [min]="longueurCompte" [max]="longueurCompte"
                      ignoreEmptyValue="true" message="{{ messageLongueurCompteInvalide }}">
                    </dxi-validation-rule>
                  </dxi-item>
                  <dxi-item dataField="compteAuxiliaireFournisseur"
                    [label]="{ text: 'Compte auxiliaire fournisseur' }" [editorOptions]="{ maxLength: 20 }">
                    <dxi-validation-rule type="stringLength" [max]="20"
                      message="Le nom du compte auxiliaire ne peut dépasser 20 caractères."></dxi-validation-rule>
                  </dxi-item>
                </dxi-item>
              </dxi-item>
              <!-- Partie Informations bancaires -->
              <dxi-item itemType="group" caption="Informations bancaires" [colSpan]="1">
                <dxi-item dataField="informationsBancaire.numeroCompte" [label]="{text: 'Numéro de compte'}"
                  [editorOptions]="{ maxLength: 120 }"></dxi-item>
                <dxi-item dataField="informationsBancaire.codeGuichet" [label]="{text: 'Code guichet'}"
                  [editorOptions]="{ maxLength: 120 }"></dxi-item>
                <dxi-item dataField="informationsBancaire.cleRib" [label]="{text: 'Clé RIB'}"
                  [editorOptions]="{ maxLength: 120 }"></dxi-item>
              </dxi-item>
            </dx-form>
          </div>
          <!-- #endregion -->

        </dx-tab-panel>
      </div>
    </div>
  </div>
</dx-scroll-view>

<app-form-popup
  [(visible)]="isAddPartenairePopupOpened"
  (saveAndOpen)="onClickSaveNewPartenaire()"
  titleText="Nouveau Partenaire"
  [height]="450"
>
  <app-partenaire-new-form *ngIf="isAddPartenairePopupOpened"></app-partenaire-new-form>
</app-form-popup>
