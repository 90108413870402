
import { NgModule } from '@angular/core';
import { Routes, RouterModule, mapToCanActivate } from '@angular/router';
import { HasAnyRoleGuard, IsAuthenticatedGuard } from '@lexi/oidc-uaa';
import { SettingsComponent } from './components/settings/settings.component';
import { CreationSocieteComponent } from './components/creation-societe/creation-societe.component';
import { EvenementTypeListComponent } from './components/evenement-type-list/evenement-type-list.component';
import { IsRootGuard } from './guards/is-root.guard';
import { IsCurrentUserRootGuard } from './guards/is-current-user-root.guard';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { ArticleListComponent } from './components/article-list/article-list.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { PartenaireListComponent } from './components/partenaire/partenaire-list/partenaire-list.component';
import { PartenaireDetailComponent } from './components/partenaire/partenaire-detail/partenaire-detail.component';
import { IsCollaborateurGuard } from './guards/is-collaborateur.guard';
import { CanAccessModuleKpiGuard } from './guards/can-access-module-kpi.guard';
import { CanAccessModuleLogistiqueGuard } from './guards/can-access-module-logistique.guard';
import { CanAccessModuleVenteGuard } from './guards/can-access-module-vente.guard';
import { CanAccessModuleFretLocalGuard } from './guards/can-access-module-fret-local.guard';
import { CanAccessModuleAdministrationGuard } from './guards/can-access-module-administration.guard';
import { IsPartenaireGuard } from './guards/is-partenaire.guard';
import { TransporteurListComponent } from './components/transporteur-list/transporteur-list.component';
import { Permissions } from '@lexi-clients/lexi';
import { StockViewComponent } from './modules/shared/stock-view/stock-view.component';
import { BonListComponent } from './modules/shared/bon/bon-list/bon-list.component';
import { MouvementStockViewComponent } from './modules/shared/mouvement-stock-view/mouvement-stock-view.component';
import { BonDetailComponent } from './modules/shared/bon/bon-detail/bon-detail.component';
import { SiteDetailComponent } from './modules/admin/site-list/site-detail/site-detail.component';
import { ManageStockGuard } from './guards/manage-stock.guard';
import { DocumentListComponent } from './components/document-list/document-list.component';
import { VoirEnTantQueComponent } from './components/voir-en-tant-que/voir-en-tant-que.component';
import { ValorisationStockComponent } from "./modules/shared/valorisation-stock/valorisation-stock.component";
import { SideNavOuterToolbarComponent } from './layouts';
import { ClassificationComponent } from './modules/admin/classification/classification.component';
import { AllBonListComponent } from "./modules/shared/bon/all-bon-list/all-bon-list.component";
import { FeatureTagsGuard } from "../../../angular-libs/lexi/oidc-uaa/src/lib/feature-tags.guard";
import { ContactsPageComponent } from './components/contacts-page/contacts-page.component';
import { ListingArticlesComponent } from './components/listing-articles/listing-articles.component';

const routes: Routes = [
  // Routes accessibles à un Partenaire et à un Collaborateur
  {
    path: 'roadmap',
    loadChildren: () => import('./modules/roadmap/roadmap.module').then(m => m.RoadmapModule),
    canActivate: mapToCanActivate([IsAuthenticatedGuard])
  },
  {
    path: '',
    component: SideNavOuterToolbarComponent,
    children: [
      // Routes accessibles uniquement aux utilisateurs de type Collaborateur
      { path: 'evenement-types', component: EvenementTypeListComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]), },
      { path: 'creation-societe', component: CreationSocieteComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'settings', component: SettingsComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'profil', component: UserProfileComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'articles', component: ArticleListComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]), data: { reuseRoute: true }  },
      { path: 'article/:id', component: ArticleDetailComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'clients', component: PartenaireListComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]), data: { reuseRoute: true }  },
      { path: 'fournisseurs', component: PartenaireListComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]), data: { reuseRoute: true }  },
      { path: 'partenaires', component: PartenaireListComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]), data: { reuseRoute: true }  },
      { path: 'client/:id', component: PartenaireDetailComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'fournisseur/:id', component: PartenaireDetailComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'partenaire/:id', component: PartenaireDetailComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'transporteurs', component: TransporteurListComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, HasAnyRoleGuard]), data: { roles: [Permissions.canGererTransporteurs], reuseRoute: true } },
      {
        path: 'administration',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleAdministrationGuard])
        },
      { path: 'classifications/:objectTypeKey', component: ClassificationComponent, canActivate: [IsAuthenticatedGuard, IsCollaborateurGuard], data: { roles: [Permissions.canAdministrerArticles] } },
      { path: 'visualisation-stock', component: StockViewComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, HasAnyRoleGuard, ManageStockGuard]), data: { roles: [Permissions.accesVueConsultationStock], reuseRoute: true } },
      { path: 'valorisation-stock', component: ValorisationStockComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, HasAnyRoleGuard, ManageStockGuard]), data: { roles: [Permissions.accesVueConsultationStock], reuseRoute: true } },
      { path: 'visualisation-des-mouvements', component: MouvementStockViewComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, HasAnyRoleGuard]), data: { roles: [Permissions.accesVueConsultationStock], reuseRoute: true } },
      { path: 'visualisation-des-bons', component: BonListComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, ManageStockGuard]), data: { reuseRoute: true } },
      { path: 'visualisation-des-bons/all', component: AllBonListComponent, canActivate: mapToCanActivate([FeatureTagsGuard,IsAuthenticatedGuard, IsCollaborateurGuard, ManageStockGuard]) },
      { path: 'bon/:id', component: BonDetailComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, ManageStockGuard]) },
      { path: 'site-en-cours', component: SiteDetailComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, HasAnyRoleGuard]), data: { roles: [Permissions.canManageSite], reuseRoute: true } },
      { path: 'document', component: DocumentListComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'voir-en-tant-que', component: VoirEnTantQueComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, IsCurrentUserRootGuard]) },
      { path: 'contacts', component: ContactsPageComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]) },
      { path: 'listing-articles', component: ListingArticlesComponent, canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard]),  data: { roles: [Permissions.canAdministrerArticles] } },


      // Modules présents dans NavigationTop (Collaborateur only)
      {
        path: 'vente',
        loadChildren: () => import('./modules/vente/vente.module').then(m => m.VenteModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleVenteGuard])
      },
      {
        path: 'logistique',
        loadChildren: () => import('./modules/logistique/logistique.module').then(m => m.LogistiqueModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleLogistiqueGuard])
      },
      {
        path: 'sav',
        loadChildren: () => import('./modules/sav/sav.module').then(m => m.SavModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard])
      },
      {
        path: 'douane',
        loadChildren: () => import('./modules/douane/douane.module').then(m => m.DouaneModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard])
      },
      {
        path: 'fret-local',
        loadChildren: () => import('./modules/fret-local/fret-local.module').then(m => m.FretLocalModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleFretLocalGuard])
      },
      {
        path: 'kpi',
        loadChildren: () => import('./modules/kpi/kpi.module').then(m => m.KpiModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, CanAccessModuleKpiGuard])
      },
      {
        path: 'editions',
        loadChildren: () => import('./modules/edition/edition.module').then(m => m.EditionModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard, IsRootGuard])
      },
      {
        path: 'securite',
        loadChildren: () => import('./modules/security/access-management.module').then(m => m.AccessManagementModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard])
      },
      {
        path: 'comptabilite',
        loadChildren: () => import('./modules/comptabilite/comptabilite.module').then(m => m.ComptabiliteModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard])
      },
      {
        path: 'support',
        loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsCollaborateurGuard])
      },
      // Portail client (Partenaire only)
      {
        path: 'portail-client',
        loadChildren: () => import('./modules/portail-client/portail-client.module').then(m => m.PortailClientModule),
        canActivate: mapToCanActivate([IsAuthenticatedGuard, IsPartenaireGuard])
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
