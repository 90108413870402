import { AuthService } from "lexi-angular/src/app/settings/auth.service";
import { Component, Input, OnInit } from '@angular/core';
import {
  CaisseTypeAuthentification,
  CumulScanSurUnArticle,
  ElevationPrivilegeAuthentificationType,
  LicenceLexiClient,
  LieuStockageDto,
  LexiMouvementTypesLexiClient,
  LieuStockagesLexiClient,
  OptionPrelevementClotureCaisse,
  TpvSettings,
  TypeRemiseDto,
  TypeRemisesLexiClient,
  LexiMouvementTypeDto,
  ClassificationValueDto,
  ClassificationValuesLexiClient,
  DocumentType,
  SelectionObligatoireClientSettings,
  UpdateLexiClient
} from '@lexi-clients/lexi';
import DataSource from 'devextreme/data/data_source';
import { DxDataSourceService } from 'lexi-angular/src/app/shared/services/dx-data-source.service';
import { forkJoin, lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tpv-settings-form',
  templateUrl: './tpv-settings-form.component.html',
  styleUrls: ['./tpv-settings-form.component.scss'],
})
export class TpvSettingsFormComponent implements OnInit {
  @Input() colCount: number = 1;
  @Input() colSpan: number = 1;

  private _tpvSettings: TpvSettings = {};
  get tpvSettings(): TpvSettings {
    return this._tpvSettings;
  }
  @Input() set tpvSettings(value: TpvSettings) {
    this._tpvSettings = value;
    if (this._tpvSettings == null) {
      this._tpvSettings = {};
    }
    if (this.tpvSettings.selectionObligatoireClientSettings == null){
      const dto : SelectionObligatoireClientSettings = {
        mouvementTypeCodeBoList : [],
        informationGarantie : false,
        informationSuiviSAV: false,
        classificationCodeBoList: []
      };
      this.tpvSettings.selectionObligatoireClientSettings = dto;
    }
  }

  @Input() formIsReadOnly: boolean = false;
  lieuStockageDataSource: LieuStockageDto[] = [];

  typesMouvementDataSource : LexiMouvementTypeDto[] = [];
  classificationsValuesDataSource: ClassificationValueDto[] = [];
  
  versionsTpvOptions : Object; 
  private _siteId: number;
  get siteId(): number {
    return this._siteId;
  }

  @Input() set siteId(value: number) {
    if (value != null && value != undefined) {
      this._siteId = value;
      this.setLieuStockageDataSource();
    }
  }

  private _isLieuxStockageForSite: boolean;
  get isLieuxStockageForSite(): boolean {
    return this._isLieuxStockageForSite;
  }
  @Input() set isLieuxStockageForSite(value: boolean) {
    if (value != null) {
      this._isLieuxStockageForSite = value;
      if (value == false) this.setLieuStockageDataSource();
    }
  }

  readonly caisseTypeAuthentification = [
    { id: CaisseTypeAuthentification.domainWindows, intitule: "Domaine Windows" },
    { id: CaisseTypeAuthentification.localLexi, intitule: "Lexi local" },
    { id: CaisseTypeAuthentification.sessionWindows, intitule: "Session Windows" },
  ];

  readonly elevationPrivilegeAuthentificationType = [
    { id: ElevationPrivilegeAuthentificationType.badgeOnly, intitule: "Badge uniquement" },
    { id: ElevationPrivilegeAuthentificationType.badgeAndPassword, intitule: "Badge et code PIN" },
    { id: ElevationPrivilegeAuthentificationType.domainWindows, intitule: "Domaine Windows" },
  ];

  readonly cumulScanSurUnArticleDataSource = [
    { id: CumulScanSurUnArticle.forceNouvelleLigne, intitule: "Forcer sur une nouvelle ligne"},
    { id: CumulScanSurUnArticle.auChoix, intitule: "Au choix"},
    { id: CumulScanSurUnArticle.parDefautNouvelleLigne, intitule: "Par défaut: Nouvelle ligne"},
    { id: CumulScanSurUnArticle.parDefautCumul, intitule: "Par défaut : Cumul"},
  ];

  readonly optionPrelevementClotureCaisseDataSource = [
    { id: OptionPrelevementClotureCaisse.none, intitule: "Prélèvement libre"},
    { id: OptionPrelevementClotureCaisse.vider, intitule: "Vider la caisse"},
    { id: OptionPrelevementClotureCaisse.fondReference, intitule: "Atteindre le fond de référence"},
  ];

  partenaireDataSource: DataSource;
  typeRemises: TypeRemiseDto[];

  isNiuora : boolean;

  constructor(
    private readonly lexiMouvementTypesLexiClient: LexiMouvementTypesLexiClient,
    private readonly dxDataSourceService: DxDataSourceService,
    private readonly typeRemisesLexiClient: TypeRemisesLexiClient,
    private readonly lieuStockageLexiClient: LieuStockagesLexiClient,
    private readonly updateCaisseLexiClient : UpdateLexiClient,
    private readonly classificationValuesLexiClient: ClassificationValuesLexiClient,
    private readonly authService: AuthService,
    private readonly licenceLexiClient : LicenceLexiClient
  ) { }

  async ngOnInit() {
    const requestResults = await lastValueFrom(forkJoin({
      versionsTpv : this.updateCaisseLexiClient.getAllVersions(),
      typeRemises : this.typeRemisesLexiClient.getAll(),
      typesMouvement : this.lexiMouvementTypesLexiClient.getMouvementType(DocumentType.vente),
      classificationValues : this.classificationValuesLexiClient.getByClassificationTypeId(this.authService.currentSociete.classificationComptableTypeId),
      isNiuora : this.licenceLexiClient.isNiuora()
    }))
    this.typeRemises = requestResults.typeRemises;
    this.partenaireDataSource = this.dxDataSourceService.getPartenaireDataSourceForSelectBox(true);
    this.versionsTpvOptions = {items : requestResults.versionsTpv.map(item =>{ return item.version}), searchEnabled : true}
    this.typesMouvementDataSource = requestResults.typesMouvement;
    this.classificationsValuesDataSource = requestResults.classificationValues;
    this.isNiuora = requestResults.isNiuora;
  }

  partenaireEtTypesDisplayExpr(data) {
    return data && `${data.codeBo} - ${data.intitule}`
  }

  async setLieuStockageDataSource() {
    if (this.isLieuxStockageForSite && this.siteId == null) return;
    this.lieuStockageDataSource = await lastValueFrom(this.lieuStockageLexiClient.getIfFonds(this.siteId, this.isLieuxStockageForSite));
  }
}