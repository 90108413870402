<dx-data-grid
  [dataSource]="dataSource"
  [showBorders]="true"
  [allowColumnResizing]="true"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [filterValue]="filterValue"
  [filterSyncEnabled]="true"
  [remoteOperations]="{ filtering: true, sorting: true, paging: true }"
  (onExporting)="onExporting($event)"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item location="before">
      <div style="font-size: 22px; font-weight: 400; padding-right: 25px;">Audit</div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" stylingMode="text" (onClick)="actualiser()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="deletetable" hint="Réinitialiser les filtres" stylingMode="text" (onClick)="clearFilters()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-header-filter [visible]="true"></dxo-header-filter>
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-export [enabled]="true" fileName="audit"></dxo-export>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>

  <!-- Colonnes -->
  <dxi-column dataField="objectType" dataType="string" caption="Type d'objet" width="190" [visible]="!objectType">
    <dxo-lookup [dataSource]="objectTypes" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="objectId" caption="ID objet" dataType="number" width="130" [visible]="!objectId" cellTemplate="objectLink"></dxi-column>
  <dxi-column dataField="utilisateurId" dataType="number" caption="Utilisateur">
    <dxo-lookup [dataSource]="utilisateurs" displayExpr="email" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="action" caption="Action" dataType="string"></dxi-column>
  <dxi-column dataField="date" caption="Date" dataType="datetime" format="dd/MM/yyyy HH:mm:ss" width="220" [sortIndex]="0" sortOrder="desc"></dxi-column>
  <dxi-column dataField="data" caption="Données" dataType="object" cellTemplate="jsonCell" width="50%" [allowFiltering]="false" [allowSorting]="false"></dxi-column>

  <div *dxTemplate="let row of 'objectLink'">
    <a *ngIf="objectNavigations[row.data.objectType]; else noUrl"
      [href]="objectNavigations[row.data.objectType] + '/' + row.data.objectId"
    >
      {{ row.data.objectId }}
    </a>

    <ng-template #noUrl>{{ row.data.objectId }}</ng-template>
  </div>

  <div *dxTemplate="let row of 'jsonCell'">
    <div *ngFor="let field of row?.data?.data?.fields ?? []" class="json-data">
      <span class="json-field-name">{{ field.name }}</span>
      <span class="json-field-values">
        <i>{{ field.oldValue || '∅' }}</i>
        <span>&nbsp;➞&nbsp;</span>
        <b>{{ field.newValue }}</b>
      </span>
    </div>
  </div>
</dx-data-grid>