<div class="view-wrapper list-page">
  <!-- #region Treelist : ClassificationValues -->
 <dx-tree-list class="treeview theme-dependent customgrid" height="100%" #treeList [dataSource]="classificationValues" parentIdExpr="parentId" [rootValue]="null"  *ngIf="showClassifications"
    [showRowLines]="true" [showBorders]="true" [columnAutoWidth]="true" [allowColumnResizing]="true"
    (onSelectionChanged)="onClassificationValueSelectionChanged($event)" [width]="450"
  >
    <!-- #region Toolbar -->
    <dxo-toolbar [multiline]="true">
      <dxi-item>
        <div *dxTemplate style="display: flex; align-items: center;">
          <dx-switch
            [(value)]="isSelectionRecursive"
            hint="Sélectionner automatiquement les enfants"
            (onValueChanged)="onToggleIsSelectionRecursive()"
          ></dx-switch>
          <span style="margin-left: 6px;">Enfants</span>
        </div>
      </dxi-item>
      <dxi-item>
        <div *dxTemplate>
          <dx-button icon="deletetable" hint="Réinitialiser les filtres" (onClick)="clearTreeListFilters()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="searchPanel"></dxi-item>
    </dxo-toolbar>
    <!-- #endregion -->

    <!-- #region Options -->
    <dxo-remote-operations [filtering]="true" [sorting]="true" [paging]="true" [grouping]="true"></dxo-remote-operations>
    <dxo-scrolling mode="infinite"></dxo-scrolling>
    <dxo-selection mode="multiple" [allowSelectAll]="false" [recursive]="isSelectionRecursive"></dxo-selection>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-row-dragging [onAdd]="onAddArticleIntoClassification" [showDragIcons]="false" [allowDropInsideItem]="true"
      group="articleList" [allowReordering]="false"
    ></dxo-row-dragging>
    <!-- #endregion -->

    <!-- Colonnes -->
    <dxi-column dataField="intitule" caption="Intitulé" [width]="300"></dxi-column>
  </dx-tree-list>
  <!-- #endregion -->

  <!-- #region DataGrid : Articles -->
  <dx-data-grid #dataGrid
    [dataSource]="articleDataSource"
    [allowColumnReordering]="true"
    [filterValue]="filterValue"
    [columnAutoWidth]="true"
    [allowColumnResizing]="true"
    [showBorders]="true"
    [focusedRowEnabled]="true"
    [keyExpr]="'id'"

    (onSelectionChanged)="onSelectionChanged($event)"
    (onExporting)="onExporting($event, 'articles')"
    (onCellClick)="onCellClick($event)"

    class="grid theme-dependent"
    noDataText="Aucun article trouvé"
    height="100%"
    width="100%"
  >
    <!-- #region Toolbar -->
    <dxo-toolbar>
      <dxi-item location="before">
        <dx-button icon="menu" (onClick)="onToggleClassificationDisplay()" hint="Afficher / Cacher les classifications"
          stylingMode="text"></dx-button>
      </dxi-item>
      <dxi-item location="before">
        <div class="grid-header">Articles</div>
      </dxi-item>
      <dxi-item location="before">
        <div *dxTemplate>
          <app-gerer-grid-state [dataGrid]="dataGrid" [gridStorageKey]="grilleStorageKey"></app-gerer-grid-state>
        </div>
      </dxi-item>
      <dxi-item location="before" locateInMenu="auto">
        <dx-drop-down-button stylingMode="text" [useSelectMode]="true" [dataSource]="classificationTypes"
          [displayExpr]="classificationTypeDisplayExpr" keyExpr="id" [dropDownOptions]="{ width: 'auto' }"
          (onSelectionChanged)="onClassificationTypeChanged()" [(selectedItemKey)]="selectedClassificationTypeId"
          label="Type de classification" labelMode="static">
        </dx-drop-down-button>
      </dxi-item>
      <dxi-item location="before" locateInMenu="auto">
        <dx-drop-down-button stylingMode="text" [useSelectMode]="true" [dataSource]="prixCustomStore"
          displayExpr="libelleLong" keyExpr="id" (onSelectionChanged)="onPrixChanged()"
          [dropDownOptions]="{ width: 'auto' }"
          [(selectedItemKey)]="selectedPrixId" text="Type de prix" labelMode="static"></dx-drop-down-button>
      </dxi-item>
      <dxi-item location="before" locateInMenu="auto">
        <div *dxTemplate style="display: flex; align-items: center;">
          <dx-switch
            [(value)]="inclureVariante"
            switchedOnText="Afficher les variantes"
            switchedOffText="Masquer les variantes"
            (onValueChanged)="setDataSource()"
          ></dx-switch>
          <span style="margin-left: 12px;"> Afficher les variantes</span>
        </div>
      </dxi-item>
      <!-- TODO Préférer une entrée "nulle" dans la liste des classifications -->
      <!-- <dxi-item location="center" *ngIf="showClassifications">
          <dx-switch #sansClassificationSwitchRef [(value)]="sansClassificationValue"
            (onValueChanged)="onSansClassificationChanged($event)" [elementAttr]="{class: 'mr-12'}"></dx-switch>
          <span>Sans classification uniquement</span>
      </dxi-item> -->
      <dxi-item location="after">
        <dx-button text="Nouveau" icon="plus" type="default" [disabled]="!isModificationAutorisee"
          stylingMode="contained" routerLink="/article/nouveau"></dx-button>
      </dxi-item>
      <dxi-item locateInMenu="auto">
        <dx-text-box #textBox mode="search" [(value)]="rechercheGlobale" (onEnterKey)="setDataSource()"
          placeholder="Recherche"></dx-text-box>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="refresh" stylingMode="text" hint="Rafraîchir" (onClick)="setDataSource()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <dx-button icon="upload" stylingMode="text" hint="Importer des articles"
          (onClick)="showImportCsvPopup = true"></dx-button>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="deletetable" stylingMode="text" hint="Réinitialiser les filtres"
            (onClick)="clearDataGridFilters()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
        </div>
      </dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item name="columnChooserButton"></dxi-item>
      <dxi-item locateInMenu="auto">
        <div *dxTemplate>
          <app-objecttype-edition-possible-list [objectType]="objectType.article" [objectIds]="this.selectedArticlesIds" [isDropDownButton]="true"></app-objecttype-edition-possible-list>
        </div>
      </dxi-item>
    </dxo-toolbar>
    <!-- #endregion -->

    <!-- #region Options -->
    <dxo-state-storing [enabled]="true" [storageKey]="grilleStorageKey" appStorage></dxo-state-storing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true">
      <dxo-search [enabled]="true"></dxo-search>
    </dxo-header-filter>
    <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="false"></dxo-grouping>
    <dxo-paging [enabled]="true" [pageSize]="25"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[25, 50, 100]" [showInfo]="true" [visible]="true"
      [infoText]="getTexteLignesSelectionees() + ' | Page {0} sur {1} ({2} éléments)'"
    ></dxo-pager>
    <dxo-selection mode="multiple"></dxo-selection>
    <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true" [grouping]="true" [groupPaging]="true"></dxo-remote-operations>
    <dxo-row-dragging *ngIf="showClassifications" [allowReordering]="true" [showDragIcons]="true" group="articleList"></dxo-row-dragging>
    <!-- #endregion -->

    <!-- #region Colonnes -->
    <dxi-column dataField="codeBo" caption="Code" [allowHeaderFiltering]="false" [allowGrouping]="false" cellTemplate="cellTemplate">
      <div *dxTemplate="let data of 'cellTemplate'">
        <a routerLink="/article/{{data.data.id}}">
          {{data.value}}
        </a>
      </div>
    </dxi-column>
    <dxi-column dataField="libelleLong" caption="Libellé" [allowHeaderFiltering]="false" [allowGrouping]="false"></dxi-column>
    <dxi-column dataField="type" caption="Type" [width]="104" [visible]="false">
      <dxo-lookup [dataSource]="articleTypeDataSource" valueExpr="id" displayExpr="intitule"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="stockSociete" caption="Société" [width]="110" [allowHeaderFiltering]="false"
      [allowFiltering]="false" [showInColumnChooser]="true" format="fixedPoint"
    ></dxi-column>
    <dxi-column dataField="stockSite" caption="{{currentSiteIntitule | internationalisation}}" [width]="100"
      [allowHeaderFiltering]="false" [allowFiltering]="false" [showInColumnChooser]="true" format="fixedPoint"
    ></dxi-column>
      <!-- TODO : Voir comment alimenter ce champ côté API avant de pouvoir rechercher dessus sinon cause souci lors du filtre -->
    <dxi-column dataField="variante1Intitule" caption="Variante 1" [showInColumnChooser]="inclureVariante" [visible]="inclureVariante" [allowFiltering]="false" [allowSearch]="false" [allowSorting]="false"></dxi-column>
      <!-- TODO : Voir comment alimenter ce champ côté API avant de pouvoir rechercher dessus sinon cause souci lors du filtre -->
    <dxi-column dataField="variante2Intitule" caption="Variante 2" [showInColumnChooser]="inclureVariante" [visible]="inclureVariante" [allowFiltering]="false" [allowSearch]="false" [allowSorting]="false"></dxi-column>
    <dxi-column dataField="tarifValeurAppliquee" caption="Prix" [width]="105" [allowHeaderFiltering]="false" [allowFiltering]="false"></dxi-column>
    <dxi-column dataField="uniteBaseIntitule" caption="Unité" [width]="121"></dxi-column>
    <dxi-column dataField="classificationValueNiveauUnLibelle" caption="Niveau 1" [width]="165"></dxi-column>
    <dxi-column dataField="classificationValueNiveauDeuxLibelle" caption="Niveau 2" [width]="165"></dxi-column>

    <!-- Colonnes cachées -->
    <dxi-column dataField="classificationValueNiveauTroisLibelle" caption="Niveau 3" [width]="165" [visible]="false"></dxi-column>
    <dxi-column dataField="fournisseurPrincipalIntitule" caption="Fournisseur principal" [visible]="false"></dxi-column>
    <dxi-column dataField="taxeGroupeIntitule" caption="Groupe de taxe" [visible]="false"> </dxi-column>
    <dxi-column dataField="bloque" caption="Bloqué" [width]="98" [visible]="false"></dxi-column>
    <!-- #endregion -->
  </dx-data-grid>
  <!-- #endregion -->
</div>

<!-- #region Popups -->
<!-- Popup : Info import CSV -->
<dx-popup [width]="500" [height]="450" title="Importer des articles via fichier .csv" [(visible)]="showImportCsvPopup" [showCloseButton]="true">
  <div *dxTemplate="let data of 'content'">
    <span>
      Pour ajouter plusieurs codes barres pour un article, ceux-ci doivent être séparés d'un '|'.
      Exemple : codebarres1|codebarres2|codebarres3
    </span>
    <div [hidden]="showLoader">
      <dx-file-uploader [multiple]="false" [allowedFileExtensions]="['.csv']" uploadMode="useForm"
        selectButtonText="Importer un fichier .csv" readyToUploadMessage="" labelText="ou Glisser/Déposer le fichier"
        [(value)]="fichierCsv" (click)="resetImportResultMsg()"></dx-file-uploader>
      <dx-button style="margin-right: 12px;" (click)="downloadTemplate()" text="Template"></dx-button>
      <dx-button style="margin-right: 12px;" (click)="cancelImportCsv()" text="Annuler"></dx-button>
      <dx-button (click)="importDataFromCsv()" text="Importer" type="default" stylingMode="contained" [disabled]="!fichierCsv[0]?.name.endsWith('.csv')"></dx-button>
      <div *ngIf="importCsvResultMsg" style="margin-top: 20px;">
        <p>
          <b *ngIf="!importHasFailed">Résultat</b>
          <b *ngIf="importHasFailed" style="color: red;">Une erreur est survenue lors de l'importation CSV</b>
        </p>
        <p>{{importCsvResultMsg}}</p>
        <p [innerHTML]="importCsvResultMsg2" style="white-space:pre-wrap;"></p>
      </div>
    </div>
    <div [hidden]="!showLoader" style="display: flex; justify-content: center; align-items: center;">
      <dx-load-indicator [visible]="showLoader" height="150" width="150"></dx-load-indicator>
    </div>
  </div>
</dx-popup>

<!-- Popup: stock par site -->
<dx-popup [title]="'Stock par site - ' + selectedArticle?.libelleLong" [hideOnOutsideClick]="true"
  [(visible)]="showPopupStockSite" (onHiding)="hideStockParSitePopup()" [showCloseButton]="true">
  <app-lot-stockage-datagrid *ngIf="showPopupStockSite" [currentSiteId]="currentSiteId" [articleId]="selectedArticle?.id"
    [dataGridMarginTop]="'calc(100vh - 200px)'" [prixId]="selectedPrixId"
    [canAfficherPMP]="isAffichagePMPAutorise" ></app-lot-stockage-datagrid>
</dx-popup>
<!-- #endregion -->