<dx-data-grid
  [dataSource]="dataSource"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  [height]="'100%'"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" (onClick)="setDataSource()" stylingMode="text"></dx-button>
      </div>
    </dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>

  <!-- Colonnes -->
  <dxi-column dataField="codeBo" caption="Code" cellTemplate="codeBoCellTemplate">
    <div *dxTemplate="let data of 'codeBoCellTemplate'">
      <a routerLink="/vente/operation-commerciale/{{data.data.id}}">
        {{data.value}}
      </a>
    </div>
  </dxi-column>
  <dxi-column dataField="libelle" caption="Libellé">
  </dxi-column>
  <dxi-column dataField="prixId" caption="Prix">
    <dxo-lookup [dataSource]="prixDataSource" displayExpr="libelleLong" valueExpr="id"></dxo-lookup>
  </dxi-column>
  <dxi-column dataField="isEnabled" caption="Actif" dataType="boolean" [width]="85"></dxi-column>
  <dxi-column dataField="dateDebut" dataType="date" caption="Début" format="dd/MM/yyyy" [width]="105"></dxi-column>
  <dxi-column dataField="dateFin" dataType="date" caption="Fin" format="dd/MM/yyyy" [width]="105"></dxi-column>
  <dxi-column dataField="heureDebut" dataType="datetime" caption="Heure début" format="HH:mm" [width]="115" [editorOptions]="{calendarOptions: {visible: false}}"></dxi-column>
  <dxi-column dataField="heureFin" dataType="datetime" caption="Heure fin" format="HH:mm" [width]="115" [editorOptions]="{calendarOptions: {visible: false}}"></dxi-column>
  <dxi-column dataField="zIndex" dataType="number" caption="Ordre" [width]="73"></dxi-column>
  <dxi-column dataField="fixe" caption="Fixe" dataType="boolean" [width]="85"></dxi-column>
  <dxi-column dataField="globale" caption="Globale" dataType="boolean" [allowEditing]="false" [visible]="false"></dxi-column>
  <dxi-column dataField="type" caption="Type" [allowEditing]="false" [visible]="false">
    <dxo-lookup [dataSource]="operationCommercialeTypeDataSource" displayExpr="intitule" valueExpr="id"></dxo-lookup>
  </dxi-column>

  <!-- MasterDetail -->
  <dxo-master-detail [enabled]="true" template="detail"></dxo-master-detail>
  <div *dxTemplate="let item of 'detail'">
    <app-operation-commerciale-detail-list
      [articleId]="articleId"
      [operationCommercialeId]="item.data.id"
      [dataGridHeight]="300"
    ></app-operation-commerciale-detail-list>
  </div>
</dx-data-grid>