  <!-- Filtres par ClassificationType -->
  <div style="display: flex">
    <dx-select-box [dataSource]="classificationTypes"
      [width]="280"
      [displayExpr]="classificationTypeDisplayExpr"
      valueExpr="id"
      [showClearButton]="true"
      (onValueChanged)="onClassificationTypeChanged()"
      [(value)]="selectedClassificationTypeId"
      style="margin: 0 12px 12px 0;"
      label="Type de classification"
    ></dx-select-box>

    <!-- Filtre sur quantité -->
    <dx-select-box [dataSource]="filtreSurQuantiteDataSource"
      [width]="205"
      displayExpr="intitule"
      valueExpr="id"
      [showClearButton]="true"
      (onValueChanged)="onFiltreSurQuantiteChanged()"
      [(value)]="selectedFiltreSurQuantite"
      style="margin: 0 12px 12px 0;"
      label="Filtre sur quantité"
    ></dx-select-box>

    <!-- Recherche -->
    <dx-text-box
      #textBoxPattern
      [(value)]="pattern"
      (onEnterKey)="setLotStockageStore()"
      style="margin: 0 12px 12px 0;"
      label="Recherche"
    ></dx-text-box>

    <!-- Bouton Rechercher -->
    <dx-button icon="find" text="Rechercher" (onClick)="setLotStockageStore()" style="margin-bottom: 12px;"></dx-button>
  </div>

<!-- DataGrid -->
 <div id="data-grid">
<dx-data-grid id="data-grid"
  [style]="'height:'+dataGridMarginTop"
  [dataSource]="lotStockageStore"
  [allowColumnReordering]="true"
  [columnAutoWidth]="true"
  [allowColumnResizing]="true"
  [showBorders]="true"
  (onRowPrepared)="onRowPrepared($event)"
  (onExporting)="onExporting($event, 'etat-stock')"
>
  <!-- Toolbar -->
  <dxo-toolbar>
    <dxi-item>
      <div *dxTemplate>
        <dx-button icon="deletetable" hint="Réinitialiser les filtres" stylingMode="text" (onClick)="clearDatagridFilters()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item location="before">
      <div *dxTemplate>
        <i id="legendeIcon" style="font-size: 24px;" class="dx-icon-info" (mouseenter)="showLegende = true" (mouseleave)="showLegende = false"></i>
      </div>
    </dxi-item>
    <dxi-item name="groupPanel"></dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
      </div>
    </dxi-item>
    <dxi-item location="after">
      <div *dxTemplate>
        <dx-button icon="refresh" hint="Rafraîchir" stylingMode="text" (onClick)="setLotStockageStore()"></dx-button>
      </div>
    </dxi-item>
    <dxi-item name="exportButton"></dxi-item>
    <dxi-item name="columnChooserButton"></dxi-item>
  </dxo-toolbar>

  <!-- Options -->
  <dxo-filter-row [visible]="true"></dxo-filter-row>
  <dxo-header-filter [visible]="true">
    <dxo-search [enabled]="true"></dxo-search>
  </dxo-header-filter>
  <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>
  <dxo-export [enabled]="true"></dxo-export>
  <dxo-state-storing [enabled]="true" [storageKey]="dataGridStorageKey" appStorage></dxo-state-storing>
  <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
  <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
  <dxo-scrolling showScrollbar="always"></dxo-scrolling>
  <dxo-group-panel [visible]="true"></dxo-group-panel>
  <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
  <dxo-editing mode="row" [allowUpdating]="true"></dxo-editing>
  <dxo-remote-operations [sorting]="true" [filtering]="true" [paging]="true" [grouping]="false" [groupPaging]="false"></dxo-remote-operations>

  <!-- Marchandise -->
    <dxi-column dataField="articleCodeBo" [allowEditing]="false" caption="Code article" cellTemplate="codeArticleCellTemplate" [width]="115" [allowHeaderFiltering]="false" [allowGrouping]="false">
      <div *dxTemplate="let data of 'codeArticleCellTemplate'">
        <a routerLink="{{getLinkToArticleDetail(data.data)}}">
          {{data.value}}
        </a>
      </div>
    </dxi-column>
    <dxi-column dataField="articleIntitule" [allowEditing]="false" caption="Article" [allowHeaderFiltering]="false" [allowGrouping]="false"></dxi-column>
    <dxi-column dataField="totalQuantite" [allowEditing]="false" caption="Quantité" [width]="100" [allowHeaderFiltering]="false" [allowGrouping]="false" [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column dataField="quantiteDisponible" caption="Qté dispo" [width]="100" [allowGrouping]="false" [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column dataField="quantiteReservee" caption="Qté réservée" [width]="100" [allowGrouping]="false" [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column dataField="quantiteMinimale" caption="Qté min." [width]="100" [allowHeaderFiltering]="false" [allowGrouping]="false" [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column dataField="quantiteMaximale" caption="Qté max." [width]="100" [allowHeaderFiltering]="false" [allowGrouping]="false" [allowHeaderFiltering]="false"></dxi-column>
    <dxi-column dataField="uniteIntitule" [allowEditing]="false" caption="Unité" [width]="100"></dxi-column>
    <dxi-column *ngIf="prixId != null" dataField="prixVente" [allowEditing]="false" [allowHeaderFiltering]="false" caption="Prix Vente" [width]="100">
      <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
    </dxi-column>
    <dxi-column *ngIf="prixId != null" caption="Valeur PV" [calculateCellValue]="calculateQuantitePrixVente" [visible]="false" [allowHeaderFiltering]="false">
      <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
    </dxi-column>
    <dxi-column dataField="classificationValueNiveauUnId" caption="Classification Niv. 1">
      <dxo-lookup [dataSource]="classificationValuesNiveauUn" displayExpr="intitule" valueExpr="id"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="classificationValueNiveauDeuxId" caption="Classification Niv. 2">
      <dxo-lookup [dataSource]="classificationValuesNiveauDeux" displayExpr="intitule" valueExpr="id"></dxo-lookup>
    </dxi-column>
    <dxi-column caption="Stock par site" cellTemplate="stockTemplate" width="130px"></dxi-column>
    <dxi-column caption="Qté min - Quantité" width="100" [visible]="false" [calculateDisplayValue]="getDifferenceQuantiteMinQuantite"></dxi-column>

    <!-- Templates -->
    <div *dxTemplate="let cellInfo of 'stockTemplate'" >
      <i class="dx-icon-product" style="cursor: pointer;" (click)="showStockParSite(cellInfo.data)"></i>
    </div>
</dx-data-grid>
</div>
<!-- Popover: Légende couleur -->
<dx-popover
  target="#legendeIcon"
  [width]="300"
  [showTitle]="true"
  title="Légende :"
  [(visible)]="showLegende"
>
  <div *dxTemplate="let data = model; of: 'content'">
    <span class="qte-sous-qte-min">Rouge</span> = Quantité en stock inférieure à la quantité minimum<br><br>
    <span class="qte-superieur-a-qte-max">Jaune</span> = Quantité en stock supérieure à la quantité maximum<br><br>
  </div>

</dx-popover>

<!-- Popup: stock par site -->
<dx-popup
  contentTemplate="popup-content"
  [(visible)] = "showPopupStockSite"
  [title]="'Stock par site - ' + selectedArticle?.articleIntitule"
  [showCloseButton]="true"
  [width]="400"
  [hideOnOutsideClick]="true"
>
  <div *dxTemplate="let data of 'popup-content'">
    <dx-data-grid height="100%" *ngIf="stockParSite" [dataSource]="stockParSite">
      <dxi-column dataField="intitule" caption="Site"></dxi-column>
      <dxi-column [width]="100" dataField="quantite" caption="Quantité"></dxi-column>
    </dx-data-grid>
  </div>
</dx-popup>