<div *ngIf="!isAffichageStockAutorise" style="padding-left: 1rem;">
  Vous n'avez pas la permission pour consulter l'état des stocks
</div>

<ng-container *ngIf="isAffichageStockAutorise">
  <div style="margin-bottom: 6px;">
    <dx-button text="Site" (onClick)="setDataSourceByPartenaire()" [visible]="isInFicheArticle" [disabled]="configFiltre?.isByPartenaire"></dx-button>
    <dx-button text="Société" (onClick)="setDataSourceBySociete()" [visible]="isInFicheArticle" [disabled]="!configFiltre?.isByPartenaire"></dx-button>
  </div>

  <dx-data-grid
    [style]="'height:'+dataGridMarginTop" [dataSource]="lotStockageStore" [allowColumnReordering]="true"
    [columnAutoWidth]="true" [allowColumnResizing]="true" [showBorders]="true"
    [remoteOperations]="{ groupPaging: true }"
    (onExporting)="onExporting($event, 'stock-details')"
  >
    <!-- #region Toolbar -->
    <dxo-toolbar>
      <dxi-item name="groupPanel"></dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <app-reset-grid-state [dataGrid]="dataGrid"></app-reset-grid-state>
        </div>
      </dxi-item>
      <dxi-item location="after">
        <div *dxTemplate>
          <dx-button icon="refresh" hint="Rafraîchir" stylingMode="text" (onClick)="setDataSource()"></dx-button>
        </div>
      </dxi-item>
      <dxi-item name="exportButton"></dxi-item>
      <dxi-item name="columnChooserButton"></dxi-item>
    </dxo-toolbar>
    <!-- #endregion -->

    <!-- #region Options -->
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true" [allowSearch]="true"></dxo-header-filter>
    <dxo-column-chooser [enabled]="true" mode="select" [height]="600"></dxo-column-chooser>
    <dxo-export [enabled]="true" fileName="etat-stock"></dxo-export>
    <dxo-state-storing [enabled]="true" [storageKey]="dataGridStorageKey" appStorage></dxo-state-storing>
    <dxo-paging [enabled]="true" [pageSize]="50"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[50, 100, 150]" [showInfo]="true" [visible]="true"></dxo-pager>
    <dxo-scrolling showScrollbar="always"></dxo-scrolling>
    <dxo-group-panel [visible]="true"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
      <!--
      La propriété [groupPaging] ci-dessous cause un souci dans le cas des lots de type Fond et empêche la visualisation des lots
      Si la liste des lots est de type Fond, le seul problème à l'affichage sera les summarys en bas de la grille dans le cas d'un grouping
      qui seront égal à 0. (cf: https://dev.azure.com/idtpf/Lexi/_git/Lexi/pullrequest/4880)
     -->
    <dxo-remote-operations [sorting]="true" [grouping]="true" [filtering]="true" [paging]="true"></dxo-remote-operations>
    <!-- #endregion -->

    <!-- #region Colonnes -->
    <dxi-column [allowGrouping]="true" dataField="siteIntitule" caption="Site" [visible]="!configFiltre?.isByPartenaire"></dxi-column>
    <dxi-column [allowGrouping]="true" dataField="lieuStockageIntitule" caption="Lieu stockage"></dxi-column>
    <!-- Marchandise -->
    <dxi-column [allowGrouping]="true" [allowHeaderFiltering]="false" dataField="articleCodeBo" caption="Code article" cellTemplate="codeArticleCellTemplate" [width]="115" [visible]="showCodeArticle">
      <div *dxTemplate="let data of 'codeArticleCellTemplate'">
        <a routerLink="/article/{{data.data.articleId}}">
          {{data.value}}
        </a>
      </div>
    </dxi-column>
    <dxi-column [allowGrouping]="true" [allowHeaderFiltering]="false" dataField="articleIntitule" caption="Article" [visible]="articleId == null" sortOrder="asc" sortIndex="0"></dxi-column>
    <dxi-column *ngIf="dateStock == null" [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="dateLimite" caption="Date limite" dataType="date" format="dd/MM/yyyy" [width]="106"></dxi-column>
    <dxi-column *ngIf="dateStock == null" [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="numeroSerie" caption="N° série"></dxi-column>
    <dxi-column [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="quantite" caption="Quantité" [width]="100"></dxi-column>
    <dxi-column *ngIf="dateStock == null" [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="quantiteDisponible" caption="Qté dispo" [width]="100"></dxi-column>
    <dxi-column *ngIf="dateStock == null" [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="quantiteReservee" caption="Qté réservée" [width]="100"></dxi-column>
    <dxi-column [allowGrouping]="false" dataField="uniteIntitule" caption="Unité" [width]="100"></dxi-column>
    <dxi-column [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="prixRevient" caption="Prix de revient" [visible]="false" [width]="125" *ngIf="canAfficherPMP" >
      <dxo-format type="fixedPoint" [precision]="3"></dxo-format>
    </dxi-column>
    <dxi-column *ngIf="prixId != null" [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="prixVente" caption="Prix de vente" [visible]="false" [width]="125">
      <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
    </dxi-column>
    <dxi-column *ngIf="prixId != null" [allowHeaderFiltering]="false" caption="Valeur PV" [calculateCellValue]="calculateQuantitePrixVente" [visible]="false">
      <dxo-format type="fixedPoint" [precision]="0"></dxo-format>
    </dxi-column>
    <dxi-column [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="pmpGlobal" caption="Cout moyen" [width]="125" *ngIf="canAfficherPMP" >
      <dxo-format type="fixedPoint" [precision]="3"></dxo-format>
    </dxi-column>
    <dxi-column *ngIf="canAfficherPMP"
      dataField="valeur" caption="Valeur" [width]="125"
      [allowGrouping]="false" [allowHeaderFiltering]="false" [allowFiltering]="false" [allowSorting]="false"
    >
      <dxo-format type="fixedPoint" [precision]="3"></dxo-format>
    </dxi-column>
    <dxi-column [allowGrouping]="false" [allowHeaderFiltering]="false" dataField="version" caption="Version" dataType="date" format="dd/MM/yyyy HH:mm:ss" [visible]="false" [width]="150"></dxi-column>
    <dxi-column dataField="isDeleted" caption="Archivé" cellTemplate="archiveCellTemplate" dataType="boolean" [filterValue]="false" [width]="100" [visible]="false"></dxi-column>
    <div *dxTemplate="let cellData of 'archiveCellTemplate'">
      <i class="dx-icon-check" *ngIf="cellData.data.isDeleted"></i>
    </div>
    <!-- #endregion -->

    <!-- #region Totaux -->
    <dxo-summary>
      <dxi-total-item column="valeur" summaryType="sum" valueFormat=",##0.000" displayFormat="{0} F"></dxi-total-item>
      <dxi-total-item column="quantite" summaryType="sum" valueFormat="fixedPoint" displayFormat="{0}"></dxi-total-item>
      <dxi-group-item column="valeur" summaryType="sum" valueFormat=",##0.000" displayFormat="{0} F"
        [alignByColumn]="true"></dxi-group-item>
      <dxi-group-item column="quantite" summaryType="sum" valueFormat="fixedPoint" displayFormat="{0}"
        [alignByColumn]="true"></dxi-group-item>
    </dxo-summary>
    <!-- #endregion -->
  </dx-data-grid>
</ng-container>