import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {EditionsLexiClient, EditionTemplateListDto, EditionTemplatesLexiClient, ObjectType, ListEditionDto } from '@lexi-clients/lexi';
import { EnvironmentService } from 'lexi-angular/src/app/environment.service';
import notify from 'devextreme/ui/notify';
import {DownloadService} from 'lexi-angular/src/app/services/download.service';
import {ReportService} from 'lexi-angular/src/app/services/report.service';
import { AuthService } from 'lexi-angular/src/app/settings/auth.service';
import {lastValueFrom, Subscription} from 'rxjs';
import { NotificationType } from '../references/references';

@Component({
  selector: 'app-objecttype-edition-possible-list',
  templateUrl: './objecttype-edition-possible-list.component.html',
  styleUrls: ['./objecttype-edition-possible-list.component.scss'],
})
export class ObjecttypeEditionPossibleListComponent implements OnDestroy {

  private _objectType: ObjectType;
  get objectType(): ObjectType {
    return this._objectType;
  }
  @Input() set objectType(value: ObjectType) {
    if (value) {
      this._objectType = value;
      this.loadData();
    }
  }


  private _objectId: number;
  get objectId(): number {
    return this._objectId;
  }
  /**
   * Identifiant de l'objet à éditer.
   * 
   * Utiliser soit objectId pour 1 élément, soit objectIds pour une liste d'éléments
   */
  @Input() set objectId(value: number) {
    if (value) {
      this._objectId = value;
    }
  }

  /**
   * Liste des identifiants des objets à éditer.
   * 
   * Utiliser soit objectId pour 1 élément, soit objectIds pour une liste d'éléments
   */
  @Input() objectIds: string[]|number[] = [];

  @Input() isDropDownButton = false;

  @Input() placeholder = 'Imprimer';
  @Output() templateIdSelected = new EventEmitter<string>();

  private subscriptions = new Subscription();

  editionsPossible: EditionTemplateListDto[];

  constructor(private editionTemplatesLexiClient: EditionTemplatesLexiClient,
    private editionsLexiClient: EditionsLexiClient,
    private downloadService: DownloadService,
    private readonly authService: AuthService,
    private readonly reportService: ReportService,
    private environmentService: EnvironmentService) { }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  async loadData() {
    this.editionsPossible = await lastValueFrom(this.editionTemplatesLexiClient.getByObjectType(this.objectType));
  }

  async onDownloadEdition(selectedItem) {
    const selectedEditionTemplate = selectedItem?.itemData;
    
    if(selectedEditionTemplate?.editionGenerateurType === 'xtraReport') {    
      this.reportService.open(selectedItem?.itemData.id, 'objectId=' + this._objectId + '&siteId=' + this.authService.currentSiteId);
      return;
    }

    let response;
    
    if (this.objectIds?.length > 0) {
      const dto: ListEditionDto = {
        editionTemplateId: selectedEditionTemplate.id,
        editionObjectIds: this.objectIds.map(id => ({ objectId: id, nombreTemplate: 1 }))
      };

      response = await lastValueFrom(this.editionsLexiClient.getByEditionTemplateAndObjectIdPOST(dto, 'response'));
    } else {
      if (!this.objectId) {
        notify(`Aucun élément n'a été sélectionné.`, NotificationType.Warning);
        return;
      }
      
      response = await lastValueFrom(this.editionsLexiClient.getByEditionTemplateAndObjectIdGET(selectedEditionTemplate.id, this.objectId, 'response'));
    }
    
    this.downloadService.directDownloadFile(response, 'pdf');
  }

  handleEditionTemplateChange(e) {
    this.templateIdSelected.emit(e?.value);
  }
}
