/**
 * Lexi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum Permissions {
    canSynchroniserBackoffice = 'canSynchroniserBackoffice',
    canGererTpv = 'canGererTpv',
    canCloturerCaisse = 'canCloturerCaisse',
    accesEditionsTpv = 'accesEditionsTpv',
    accesModuleClient = 'accesModuleClient',
    verrouillageSession = 'verrouillageSession',
    canLogin = 'canLogin',
    canSelectionnerClient = 'canSelectionnerClient',
    accessEncoursClient = 'accessEncoursClient',
    accessFicheClient = 'accessFicheClient',
    canSelectionnClientBloque = 'canSelectionnClientBloque',
    canDecaisserReglementDiffere = 'canDecaisserReglementDiffere',
    canEncaisserReglementDiffere = 'canEncaisserReglementDiffere',
    canVenteSousPmp = 'canVenteSousPmp',
    canVenteArticleBloque = 'canVenteArticleBloque',
    canOuvrirTiroir = 'canOuvrirTiroir',
    canApporterFond = 'canApporterFond',
    canPreleverFond = 'canPreleverFond',
    canControlerFond = 'canControlerFond',
    canGererFidelisation = 'canGererFidelisation',
    canGererMotifAnnulationLigne = 'canGererMotifAnnulationLigne',
    canManageTerminaux = 'canManageTerminaux',
    canAfficherStatistiqueTpv = 'canAfficherStatistiqueTpv',
    canAnnulerTicket = 'canAnnulerTicket',
    canCreerDevis = 'canCreerDevis',
    canModifierEntrepotPiece = 'canModifierEntrepotPiece',
    canModifierDevis = 'canModifierDevis',
    canModifierPieceLieDevis = 'canModifierPieceLieDevis',
    canRetournerTicketPerime = 'canRetournerTicketPerime',
    canTransformerDevis = 'canTransformerDevis',
    canAnnulerLignePiece = 'canAnnulerLignePiece',
    canModifierEntrepotLigne = 'canModifierEntrepotLigne',
    canAugmenterPrixUnitaire = 'canAugmenterPrixUnitaire',
    canForcerRemiseLigne = 'canForcerRemiseLigne',
    canModifierPrixArticleRetour = 'canModifierPrixArticleRetour',
    canModifierMotifAnnulationLigne = 'canModifierMotifAnnulationLigne',
    canModifierReglementValide = 'canModifierReglementValide',
    canRechercherPieceToutPointVente = 'canRechercherPieceToutPointVente',
    canRendreMonnaieBonAchat = 'canRendreMonnaieBonAchat',
    canFermerSessionCaisse = 'canFermerSessionCaisse',
    canForcerOuvertureSessionCaisse = 'canForcerOuvertureSessionCaisse',
    canOuvrirSessionCaisse = 'canOuvrirSessionCaisse',
    canTransfertSessionCaisse = 'canTransfertSessionCaisse',
    canSolderAvecReliquat = 'canSolderAvecReliquat',
    canRetournerArticleSansTicket = 'canRetournerArticleSansTicket',
    canRetournerArticleAvecTicket = 'canRetournerArticleAvecTicket',
    canAccessKpi = 'canAccessKpi',
    canDemanderAssistance = 'canDemanderAssistance',
    canSeDeconnecter = 'canSeDeconnecter',
    canManageEditions = 'canManageEditions',
    canManageSite = 'canManageSite',
    canManageUsers = 'canManageUsers',
    canImprimerBadgeUtilisateur = 'canImprimerBadgeUtilisateur',
    canManagePassword = 'canManagePassword',
    canManageRoles = 'canManageRoles',
    canAssignRoles = 'canAssignRoles',
    canManagerSocietes = 'canManagerSocietes',
    canManageParametres = 'canManageParametres',
    canDeverrouillerSession = 'canDeverrouillerSession',
    canCreerComptage = 'canCreerComptage',
    canPreparerComptage = 'canPreparerComptage',
    canValiderComptage = 'canValiderComptage',
    canManageCodeBarres = 'canManageCodeBarres',
    canEffectuerGratuite = 'canEffectuerGratuite',
    optionApportMaximum = 'optionApportMaximum',
    optionApportMinimum = 'optionApportMinimum',
    optionDelaiVerrouillage = 'optionDelaiVerrouillage',
    optionEcartRecomptage = 'optionEcartRecomptage',
    optionSaisiMotifAnnulationLigneObligatoire = 'optionSaisiMotifAnnulationLigneObligatoire',
    optionPrelevement = 'optionPrelevement',
    optionSeuilPrelevement = 'optionSeuilPrelevement',
    optionRemiseMaximum = 'optionRemiseMaximum',
    optionNiveauKpi = 'optionNiveauKpi',
    canTerminerCompteClient = 'canTerminerCompteClient',
    canModifierClientRetourTicket = 'canModifierClientRetourTicket',
    canForcerAnnulationPiece = 'canForcerAnnulationPiece',
    canGererParc = 'canGererParc',
    canModifierCaracteristiques = 'canModifierCaracteristiques',
    canModifierFicheClient = 'canModifierFicheClient',
    canModifierFicheArticle = 'canModifierFicheArticle',
    candKpiDesignDashboard = 'candKpiDesignDashboard',
    canGererConnaissements = 'canGererConnaissements',
    canGererRaccourcis = 'canGererRaccourcis',
    canGererModesReglementMagasin = 'canGererModesReglementMagasin',
    canForcerSelectionClientCarteExpiree = 'canForcerSelectionClientCarteExpiree',
    canGererTauxDevise = 'canGererTauxDevise',
    canGererTransporteurs = 'canGererTransporteurs',
    canGererLieuStockages = 'canGererLieuStockages',
    accesVuesReserveesAuNiveauSociete = 'accesVuesReserveesAuNiveauSociete',
    accesVueConsultationStock = 'accesVueConsultationStock',
    canModifierTarifs = 'canModifierTarifs',
    accesVueMessage = 'accesVueMessage',
    canAfficherPmp = 'canAfficherPMP',
    administrationFret = 'administrationFret',
    canReimprimerVignetteAffranchissement = 'canReimprimerVignetteAffranchissement',
    canReimprimerLeveeAffranchissements = 'canReimprimerLeveeAffranchissements',
    canAfficherDocumentsLogistiques = 'canAfficherDocumentsLogistiques',
    canGererBons = 'canGererBons',
    canGererDocumentsLogistiques = 'canGererDocumentsLogistiques',
    canModifierQuantiteReservee = 'canModifierQuantiteReservee',
    canGererEcrituresComptables = 'canGererEcrituresComptables',
    canAfficherQuantitesTheoriquesSurUnBonInventaire = 'canAfficherQuantitesTheoriquesSurUnBonInventaire',
    canGererParametresBons = 'canGererParametresBons',
    canAccederModuleVente = 'canAccederModuleVente',
    canAccederModuleLogistique = 'canAccederModuleLogistique',
    canAccederModuleKpi = 'canAccederModuleKpi',
    canAccederModuleFretLocal = 'canAccederModuleFretLocal',
    administrationBonsAchat = 'administrationBonsAchat',
    canVendreSansStock = 'canVendreSansStock',
    canGererClientSurCaisse = 'canGererClientSurCaisse',
    canAccepterBonAchatAutreSite = 'canAccepterBonAchatAutreSite',
    canVendreNumeroSerieHorsStock = 'canVendreNumeroSerieHorsStock',
    canSurchargerPrelevementClotureTpv = 'canSurchargerPrelevementClotureTpv',
    canChangerLieuStockageSessionCaisse = 'canChangerLieuStockageSessionCaisse',
    canImprimerJournalOperation = 'canImprimerJournalOperation',
    canVisualiserCaSurTpv = 'canVisualiserCASurTPV',
    canModifierPrixRevientArticle = 'canModifierPrixRevientArticle',
    canFaireImageDuStock = 'canFaireImageDuStock',
    canFacturerConnaissement = 'canFacturerConnaissement',
    canOuvrirFermerCaisseSansControle = 'canOuvrirFermerCaisseSansControle',
    canVisualiserEcart = 'canVisualiserEcart',
    optionEcartComptageMaximumAutoriseOuverture = 'optionEcartComptageMaximumAutoriseOuverture',
    canAccederModuleSav = 'canAccederModuleSAV',
    canModifierMetadonneeReglement = 'canModifierMetadonneeReglement',
    administrationOperationsCommerciales = 'administrationOperationsCommerciales',
    canAdministrerArticles = 'canAdministrerArticles',
    canAdministrerClients = 'canAdministrerClients',
    creerReglementBackOffice = 'creerReglementBackOffice',
    canModifierTouteActivite = 'canModifierTouteActivite',
    canSynchronisationFull = 'canSynchronisationFull',
    canAccederModuleSupport = 'canAccederModuleSupport'
};

