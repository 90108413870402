import { Component, Input } from '@angular/core';
import { ExportDataGridService } from 'lexi-angular/src/app/shared/services/export-data-grid.service';
import { ExportingEvent } from 'devextreme/ui/data_grid';
import { ObjectType, DossierResultDto } from '@lexi-clients/lexi';

const DATAGRID_CONF_STORAGE_KEY_BASE = "grille_dossiers_sub";

@Component({
  selector: 'app-dossier-list-sub',
  templateUrl: './dossier-list-sub.component.html',
  styleUrls: ['./dossier-list-sub.component.scss'],
})
export class DossierListSubComponent {
  readonly typesStatuts = [
    {id: "new", libelle: 'Nouveau'},
    {id: "paused", libelle: 'En attente'},
    {id: "closed", libelle: 'Fermé'},
    {id: "opened", libelle: 'En cours'}
  ];

  @Input() objectType: ObjectType;
  @Input() dossierList: DossierResultDto[] = [];

  constructor( private readonly exportDataGridService: ExportDataGridService) { }

  showClient() {
    return this.objectType !== ObjectType.partenaire && this.objectType !== ObjectType.materiel;
  }

  onExporting(e: ExportingEvent, filename: string) {
    this.exportDataGridService.onExporting(e, filename);
  }
}